import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import * as PrismicDOM from 'prismic-dom';

@Component({
  selector: 'en-prismic-text',
  templateUrl: './prismic-text.component.html',
  styleUrls: ['./prismic-text.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class PrismicTextComponent implements OnInit {
  @Input() prismicText;
  @Input() addClass: string = '';

  htmlText;

  constructor() {}

  ngOnInit(): void {
    const linkResolver = doc => {
      if (doc.type === 'alert_page') {
        return '/alert/' + doc.uid;
      }
      if (doc.type === 'custom_content_page') {
        return '/page/' + doc.uid;
      }
      return '/page/' + doc.uid;
    };

    if (
      this.prismicText &&
      (this.prismicText.content.length > 0 || this.prismicText.length > 0)
    ) {
      this.htmlText = PrismicDOM?.RichText.asHtml(
        this.prismicText.content,
        linkResolver
      );
    }
  }
}
