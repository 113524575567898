export let checkboxDataList = [
  {
    id: 'mce-group[8653]-8653-0',
    name: 'group[8653][1]',
    label: 'Ferðaþjónusta',
    value: 1,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-1',
    name: 'group[8653][2]',
    label: 'Fjármál og rekstur',
    value: 2,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-2',
    name: 'group[8653][4]',
    label: 'Heilbrigðis- og félagssvið',
    value: 4,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-3',
    name: 'group[8653][8]',
    label: 'Menning',
    value: 8,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-4',
    name: 'group[8653][16]',
    label: 'Persónuleg hæfni',
    value: 16,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-5',
    name: 'group[8653][32]',
    label: 'Starfstengd hæfni',
    value: 32,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-6',
    name: 'group[8653][64]',
    label: 'Stjórnun & forysta',
    value: 64,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-7',
    name: 'group[8653][128]',
    label: 'Tungumál',
    value: 128,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-8',
    name: 'group[8653][256]',
    label: 'Uppeldi & kennsla',
    value: 256,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-9',
    name: 'group[8653][512]',
    label: 'Upplýsingatækni',
    value: 512,
    isChecked: false,
  },

  {
    id: 'mce-group[8653]-8653-10',
    name: 'group[8653][1024]',
    label: 'Arkitektúr, verk- og tæknifræði',
    value: 1024,
    isChecked: false,
  },
];
