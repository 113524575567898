import { Component, Input } from '@angular/core';

@Component({
  selector: 'en-employee',
  templateUrl: './employee.component.html',
  styleUrls: ['./employee.component.less'],
})
export class EmployeeComponent {
  @Input() content;
  openingHoursOpen = false;

  toggleOpeningHours() {
    if (this.openingHoursOpen === false) {
      this.openingHoursOpen = true;
    } else {
      this.openingHoursOpen = false;
    }
  }
}
