import { Component, Input } from '@angular/core';

@Component({
  selector: 'en-frett-card',
  templateUrl: './frett-card.component.html',
  styleUrls: ['./frett-card.component.less'],
})
export class FrettCardComponent {
  @Input() content;
  @Input() newsType;
}
