import { Component, Input } from '@angular/core';

@Component({
  selector: 'en-references-grid',
  templateUrl: './references-grid.component.html',
  styleUrls: ['./references-grid.component.less'],
})
export class referencesGridComponent {
  @Input() cont;
}
