<div
  class="search-section"
  [ngClass]="{ blue: color === 'Y0004' || color === 'blue' }"
  *ngIf="!header"
>
  <form (ngSubmit)="search()">
    <div class="input">
      <input
        [(ngModel)]="searchInput"
        id="search"
        name="search"
        type="search"
        placeholder="leita..."
        [ngClass]="{ blue: color === 'Y0004' || color === 'blue' }"
        autofocus
      />
      <span class="callSearch" *ngIf="!searchInput">
        <svg
          class="searchIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="27.414"
          height="27.414"
          viewBox="0 0 27.414 27.414"
        >
          <g
            id="search_1_"
            data-name="search (1)"
            transform="translate(-2.197 -2.197)"
          >
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="11"
              cy="11"
              r="11"
              transform="translate(3.197 3.197)"
              stroke-width="2"
              stroke="#c6c5c2"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
            <line
              id="Line_6"
              data-name="Line 6"
              x1="6.089"
              y1="6.089"
              transform="translate(22.108 22.108)"
              fill="none"
              stroke="#c6c5c2"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </span>

      <span
        class="exitSearch"
        (click)="clearSearchInput()"
        *ngIf="searchInput"
        [ngClass]="{ blue: color === 'Y0004' || color === 'blue' }"
      >
        <svg
          class="searchIcon"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 49.663 50"
        >
          <g id="Close" transform="translate(-1665.491 -36.494)">
            <g id="chevron" transform="translate(1538.978 36.495)">
              <g
                id="Group_65"
                data-name="Group 65"
                transform="translate(126.513)"
              >
                <path
                  id="Path_52"
                  data-name="Path 52"
                  d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                  transform="translate(-126.513)"
                  fill="#c6c5c2"
                />
              </g>
            </g>
            <g
              id="chevron-2"
              data-name="chevron"
              transform="translate(1840.959 86.117) rotate(180)"
            >
              <g
                id="Group_65-2"
                data-name="Group 65"
                transform="translate(126.513)"
              >
                <path
                  id="Path_52-2"
                  data-name="Path 52"
                  d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                  transform="translate(-127.22 -0.707)"
                  fill="#c6c5c2"
                />
                <path
                  id="Path_54"
                  data-name="Path 54"
                  d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                  transform="translate(-126.513)"
                  fill="#c6c5c2"
                />
              </g>
            </g>
          </g>
        </svg>
      </span>
    </div>

    <en-button
      [buttonContent]="'Skoða námskeið'"
      *ngIf="!searchInput && viewAll !== false"
      [buttonType]="'regular'"
      [routerLink]="'namskeid'"
      [buttonColor]="color"
    ></en-button>
    <en-button
      type="submit"
      [buttonContent]="'Leita'"
      *ngIf="searchInput"
      [buttonType]="'regular'"
      [buttonColor]="color"
      (click)="search()"
    ></en-button>
  </form>
</div>

<div class="search-header" *ngIf="header">
  <form (ngSubmit)="search()">
    <div class="input">
      <input
        type="search"
        id="search"
        name="search"
        #searchEl
        [(ngModel)]="searchInput"
        placeholder="leita..."
      />
      <button class="btn-search" type="submit" (click)="search()">
        <svg
          class="searchIcon"
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 27.414 27.414"
        >
          <g
            id="search_1_"
            data-name="search (1)"
            transform="translate(-2.197 -2.197)"
          >
            <circle
              id="Ellipse_4"
              data-name="Ellipse 4"
              cx="11"
              cy="11"
              r="11"
              transform="translate(3.197 3.197)"
              stroke-width="2"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
            <line
              id="Line_6"
              data-name="Line 6"
              x1="6.089"
              y1="6.089"
              transform="translate(22.108 22.108)"
              fill="none"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
            />
          </g>
        </svg>
      </button>
    </div>
  </form>
</div>
