<div class="hero-banner">
  <div
    class="hero-banner__content trs-hero-content"
    [ngClass]="{ loaded: (prismicLoaded | async) }"
  >
    <en-overhead-title [color]="'Y0004'">Menntun fyrir alla</en-overhead-title>
    <en-heading
      [headingContent]="{ type: 'heading1', text: 'Námsbrautir' }"
    ></en-heading>
    <en-search [color]="'Y0004'" [viewAll]="false"></en-search>
  </div>

  <div
    class="hero-banner__media trs-hero-img"
    [ngClass]="{ loaded: (prismicLoaded | async) }"
  >
    <img
      [src]="prismicContent?.banner_image.url"
      [alt]="prismicContent?.banner_image.alt"
    />
  </div>
</div>

<en-filter [activeCourseType]="'Y0004'"></en-filter>

<div class="nam_component wrapper" #results>
  <ng-container *ngIf="classes?.length && !classesError; else rendering">
    <div class="main-grid">
      <ng-container *ngFor="let class of classes; let last = last">
        <a [routerLink]="[getEduframeClassLink(class.eduframeCourse, class.eduframePlannedCourse)]">
          <en-course-card [cont]="class" [loaded]="fmLoaded"></en-course-card>
        </a>
      </ng-container>
    </div>
  </ng-container>

  <ng-template #rendering>
    <ng-container *ngIf="classesError; else loading">
      Engar námsbrautir fundust undir þessum forsendum
    </ng-container>
    <ng-template #loading>
      <en-course-grid-skeleton></en-course-grid-skeleton>
    </ng-template>
  </ng-template>
</div>

<ng-container *ngIf="filterActive">
  <en-button
    [buttonType]="'clear'"
    [buttonContent]="'Hreinsa val'"
    (click)="clearSortingAndFilters()"
    [buttonColor]="'Y0004'"
  ></en-button>
</ng-container>
