import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  AfterViewInit,
} from '@angular/core';

@Component({
  selector: 'en-youtube-player',
  templateUrl: './youtube-player.component.html',
  styleUrls: ['./youtube-player.component.less'],
})
export class YoutubePlayerComponent implements AfterViewInit {
  @Input() ytUrl: string;
  @Input() noSpacing = true;
  @ViewChild('player') el: ElementRef;

  public playerVars: YT.PlayerVars = {
    color: 'white',
    enablejsapi: 1,
    modestbranding: 1,
    rel: 0,
    showinfo: 0,
  };

  ngAfterViewInit(): void {
    this.playerInit(this.ytUrl);
  }

  private playerInit(url: string): void {
    if (typeof window === 'undefined') {
      return;
    }

    const playerOptions: YT.PlayerOptions = {
      height: '100%',
      width: '100%',
      playerVars: this.playerVars,
      videoId: url.trim(),
    };

    new YT.Player(this.el.nativeElement, playerOptions);
  }
}
