import { SearchPageComponent } from '@endurmenntun/_components/pages/search-page/search-page.component';
import { CoursesEnglishComponent } from '@endurmenntun/_components/pages/courses-english/courses-english.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// Component imports
import { FrontPageComponent } from '@endurmenntun/_components/pages/front-page/front-page.component';
import { FrettirComponent } from '@endurmenntun/_components/pages/frettir/frettir.component';
import { FrettComponent } from '@endurmenntun/_components/pages/frettir/frett/frett.component';
import { UmEhiContainerComponent } from '@endurmenntun/_components/pages/um-ehi/um-ehi-container.component';
import { StaffComponent } from '@endurmenntun/_components/pages/um-ehi/staff/staff.component';
import { ManagementComponent } from '@endurmenntun/_components/pages/um-ehi/management/management.component';
import { EhiComponent } from '@endurmenntun/_components/pages/um-ehi/ehi/ehi.component';
import { NotFoundComponent } from '@endurmenntun/_components/pages/not-found/not-found.component';
import { RegistrationFormComponent } from '@endurmenntun/_components/pages/education/registration-form/registration-form.component';
import { AlertPageComponent } from '@endurmenntun/_components/pages/alert-page/alert-page.component';
import { CustomContentPageComponent } from './_components/pages/custom-content-page/custom-content-page.component';

import { ConfirmationDocumentsComponent } from '@endurmenntun/_components/pages/education/registration-form/confirmation-documents/confirmation-documents.component';
import { NamskeidComponent } from '@endurmenntun/_components/pages/education/nam-single/namskeid/namskeid.component';
import { FormsComponent } from './_components/pages/forms/forms.component';
import { CoursesComponent } from '@endurmenntun/_components/pages/education/courses/courses.component';
import { ClassesComponent } from '@endurmenntun/_components/pages/education/classes/classes.component';
// Services
import { PrismicService } from './_services/prismic/prismic.service';

const routes: Routes = [
  { path: '', component: FrontPageComponent },

  {
    path: 'namskeid',
    children: [
      { path: '', component: CoursesComponent },
      {
        path: ':courseId/:coursename/:plannedCourseId',
        component: NamskeidComponent,
      },
    ],
  },

  {
    path: 'namsbrautir',
    children: [
      { path: '', component: ClassesComponent },
      {
        path: ':courseId/:coursename/:plannedCourseId',
        component: NamskeidComponent,
        data: { type: 'class' }
      },
    ],
  },

  {
    path: 'frettir',
    children: [
      { path: '', component: FrettirComponent },
      { path: ':frett', component: FrettComponent },
    ],
  },

  {
    path: 'greinar',
    children: [
      { path: '', component: FrettirComponent },
      { path: ':grein', component: FrettComponent },
    ],
  },

  {
    path: 'skraning',
    children: [{ path: ':namId', component: RegistrationFormComponent }],
  },

  {
    path: 'rafraen-gogn',
    children: [{ path: ':namId', component: ConfirmationDocumentsComponent }],
  },

  {
    path: 'stadfesting',
    children: [{ path: ':namId', component: ConfirmationDocumentsComponent }],
  },

  {
    path: 'alert',
    children: [
      { path: ':alertUID', component: AlertPageComponent },
      { path: '', redirectTo: '/', pathMatch: 'full' },
    ],
  },

  {
    path: 'um-ehi',
    redirectTo: 'um-okkur',
  },

  {
    path: 'um-okkur',
    component: UmEhiContainerComponent,
    children: [
      { path: '', component: EhiComponent },
      { path: 'starfsfolk', component: StaffComponent },
      { path: 'stjornin', component: ManagementComponent },
    ],
  },

  { path: 'search-results', component: SearchPageComponent },

  { path: 'hafa-samband', component: FormsComponent },

  { path: 'postlisti', component: FormsComponent },

  { path: 'courses-in-english', component: CoursesEnglishComponent },

  { path: 'not-found', component: NotFoundComponent },

  { path: ':customPageUID', component: CustomContentPageComponent },

  {
    path: ':dynamicPageParent',
    children: [
      { path: ':customPageUID', component: CustomContentPageComponent },
      { path: '', redirectTo: '/', pathMatch: 'full' },
    ],
  },

  { path: '**', component: NotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  providers: [PrismicService],
  exports: [RouterModule],
})
export class AppRoutingModule {}
