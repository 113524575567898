import { Component, Output, EventEmitter } from '@angular/core';
// Models
import { MailListForm } from '@endurmenntun/_models/forms/mail-list-form.class';
// Services
import { MailinglistService } from '@endurmenntun/_services/mailchimp/mailinglist.service';
import { checkboxDataList } from '@endurmenntun/_services/mailchimp/mailing-list';

@Component({
  selector: 'en-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: ['./mailing-list.component.less'],
})
export class MailingListComponent {
  @Output() pushSuccess = new EventEmitter<boolean>();
  public netfangBlur = false;
  public model = new MailListForm('');
  public submitted = false;
  public error: boolean = false;
  public errorMessage: string = '';
  public checkboxList = checkboxDataList;

  constructor(private mailingList: MailinglistService) {}

  onSubmit(args) {
    args.form.updateValueAndValidity();

    if (args.form.invalid) {
      Object.keys(args.form.controls).forEach(key => {
        args.form.get(key).markAsDirty();
      });
      return;
    } else {
      this.mailingList.newRegistration(this.model.EMAIL).subscribe(
        response => {
          if (response.result && response.result !== 'error') {
            this.submitted = true;
            this.pushSuccess.emit(true);
          } else {
            this.error = true;
            this.errorMessage = response.msg;
          }
        },
        err => {
          this.error = true;
          this.errorMessage =
            'Villa kom upp í skráningarferlinu. Vinsamlegast hafðu samband við ENDURMENNTUN í síma <a href="tel:5254444">525 4444</a> eða sendu tölvupóst á <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>';
        }
      );
    }
  }
}
