<div class="frett-component">
  <a
    [routerLink]="
      '/' +
      (newsType.includes('frettir') ? 'frettir' : 'greinar') +
      '/' +
      content?.uid
    "
  >
    <figure>
      <img
        [src]="content.data.featured_image.Grid.url"
        [alt]="content.data.featured_image.Grid.alt"
        [height]="content.data.featured_image.Grid.dimensions.height"
        [width]="content.data.featured_image.Grid.dimensions.width"
      />
      <div class="overlay">
        <h4>Skoða {{ newsType.includes('frettir') ? 'frétt' : 'grein' }}</h4>
      </div>
    </figure>

    <div class="frett-component__content">
      <div class="content-card">
        <span class="date" *ngIf="content?.type === 'frett'">{{
          content.data.article_date | date: 'd. MMMM yyyy':'+0000':'is'
        }}</span>
        <h3>{{ content.data.title[0].text }}</h3>
      </div>
    </div>
  </a>
</div>
