<ng-container *ngIf="buttonType === 'regular'">
  <button
    [ngClass]="{
      'regular-button': buttonType === 'regular',
      blue: buttonColor === 'blue' || buttonColor === 'Y0004'
    }"
  >
    {{ buttonContent }}
  </button>
</ng-container>

<ng-container *ngIf="buttonType === 'main'">
  <button
    [ngClass]="{
      'main-button': buttonType === 'main',
      blue: buttonColor === 'blue' || buttonColor === 'Y0004'
    }"
  >
    <span class="chevron">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.29"
        height="50"
        viewBox="0 0 25.29 50"
      >
        <path
          id="Path_52"
          data-name="Path 52"
          d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
          transform="translate(-126.513 0.001)"
          fill="#d4575b"
        />
      </svg>

      <span class="plus-addon">
        <img src="../../../../assets/Media/plus.svg" alt="" />
      </span>
    </span>
    <div class="active-content">
      {{ buttonContent }}
    </div>
  </button>
</ng-container>

<ng-container *ngIf="buttonType === 'clear'">
  <button
    class="clear-button"
    [ngClass]="{ blue: buttonColor === 'blue' || buttonColor === 'Y0004' }"
  >
    <div class="active-content">
      {{ buttonContent }}
    </div>
    <span class="exit-icon">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="70.24"
        height="69.703"
        viewBox="0 0 70.24 69.703"
      >
        <g id="plus" transform="translate(-1540.027 -1794.382)">
          <g id="chevron" transform="translate(1485.923 1704.923) rotate(45)">
            <g
              id="Group_65"
              data-name="Group 65"
              transform="translate(126.513)"
            >
              <path
                id="Path_52"
                data-name="Path 52"
                d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                transform="translate(-126.513)"
                fill="#fff"
              />
            </g>
          </g>
          <g
            id="chevron-2"
            data-name="chevron"
            transform="translate(1664.368 1953.543) rotate(-135)"
          >
            <g
              id="Group_65-2"
              data-name="Group 65"
              transform="translate(126.513)"
            >
              <path
                id="Path_52-2"
                data-name="Path 52"
                d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                transform="translate(-127.22 -0.707)"
                fill="#fff"
              />
              <path
                id="Path_54"
                data-name="Path 54"
                d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                transform="translate(-126.513)"
                fill="#fff"
              />
            </g>
          </g>
        </g>
      </svg>
    </span>
  </button>
</ng-container>
