import { Component, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
import * as PrismicDOM from 'prismic-dom';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-alert-banner',
  templateUrl: './alert-banner.component.html',
  styleUrls: ['./alert-banner.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class AlertBannerComponent implements AfterViewInit {
  public prismicContent: any;
  public html: any;
  // Returns true when content is loaded
  public loaded: Observable<Boolean>;

  constructor(
    private prismic: PrismicService,
    private general: GeneralService
  ) {}

  async ngAfterViewInit() {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }

  async getPrismicContent() {
    this.prismic
      .getDocumentByType('alert_banner')
      .then((data: PrismicBaseInterface) => {
        if (data.results.length === 0) {
          return;
        }

        this.prismicContent = data.results[0].data;
        this.formatText(this.prismicContent);
      })
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }

  formatText(content) {
    const linkResolver = doc => {
      if (doc.type === 'alert_page') {
        return '/alert/' + doc.uid;
      }
      return '/' + doc.uid;
    };

    if (content.tilkynning.length > 0) {
      this.html = PrismicDOM?.RichText.asHtml(content.tilkynning, linkResolver);
    }
  }
}
