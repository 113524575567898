import {
  Component,
  OnInit,
  Input,
  OnChanges,
  SimpleChanges,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { OutlookCalendar, GoogleCalendar, ICalendar } from 'datebook';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'en-footer-actions',
  templateUrl: './footer-actions.component.html',
  styleUrls: ['./footer-actions.component.less'],
})
export class FooterActionsComponent implements OnInit, OnChanges {
  @Input() calendarDetails: {
    title?: string;
    location?: string;
    description?: string;
    start?: string;
    end?: string;
  };
  @Input() courseContent?;
  public activePath: string;
  public path: string;
  public outlookPath;
  public googlePath;
  public iCalPath;
  public calendarOpen = false;
  public pdfCalled = false;

  public getSantizeUrl(url : string) {
    // @ts-ignore
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  constructor(
    private sanitizer: DomSanitizer,
    private location: Location,
    private router: Router,
    @Inject(PLATFORM_ID) public platformId: any
  ) {}

  ngOnInit(): void {
    this.activePath = this.location.path();
    this.path = 'https://endurmenntun.is' + this.activePath;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.calendarDetails) {
      this.setCalendars(this.calendarDetails);
    }
  }

  setCalendars(calendarDetails): void {
    if (!calendarDetails.start || !calendarDetails.end) {
      return;
    }

    const start = this.convertToISODate(calendarDetails.start);
    const end = this.convertToISODate(calendarDetails.end);

    const outlookCalendar = new OutlookCalendar({ ...calendarDetails, start, end });
    const googleCalendar = new GoogleCalendar({ ...calendarDetails, start, end });
    const iCalendar = new ICalendar({ ...calendarDetails, start, end });

    this.renderCalendars(outlookCalendar, googleCalendar, iCalendar);
  }

  convertToISODate(dateString: string): string {
    const parts = dateString.split("/");
    const day = parts[0];
    const month = parts[1];
    const year = parts[2];

    // Convert to ISO 8601 format for the datebook library
    const isoDate = `${year}-${month}-${day}`;

    return isoDate;
  }

  renderCalendars(outlook, google, icalendar) {
    this.outlookPath = outlook.render();
    this.googlePath = google.render();
    this.iCalPath = icalendar.render();
  }

  toggleCalendar() {
    if (this.calendarOpen === false) {
      this.calendarOpen = true;
    } else {
      this.calendarOpen = false;
    }
  }

  goBack() {
    if (this.router.url.includes('payment')) {
      if (isPlatformBrowser(this.platformId)) {
        setTimeout(() => {
          window.scroll(0, 0);
        }, 50);
      }
    }
    this.location.back();
  }
}
