import { GeneralService } from './../../../_services/general/general.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// Models
import { Namskeid } from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import { FmResponse } from './../education/interfaces/filemaker-query.interface';
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import slugify from 'slugify';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';
import { FilterParams } from '@endurmenntun/fake-maker/interfaces/fake-maker.interface';

@Component({
  selector: 'en-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.less'],
})
export class SearchPageComponent implements OnDestroy {
  @Input() namType?;
  private header: boolean = false;
  public nothingFound: boolean;
  public searchQuery: any;
  public results: Array<{ fieldData?: Namskeid }>;
  public loading: boolean;
  public loaded: any;
  public prismicContent: any;
  public prismicNews: any;
  public prismicStaff: any;
  public managementData: any;

  constructor(
    private educationService: FakeMakerService,
    private route: ActivatedRoute,
    private router: Router,
    private loc: Location,
    private general: GeneralService,
    private prismic: PrismicService
  ) {
    this.route.queryParams.subscribe(params => {
      this.loading = true;
      this.searchQuery = params?.search;
      this.searchCourses(this.namType, params);
    });
  }

  // +++++++++++++++++++++++++++++++++++++++++++++++++++++++
  // ++++ Search the Prismic API +++++++++++++++++++++++++++

  // we dont want to display the results on the frontpage
  get isOnSearchResultsPage(): boolean {
    return this.router.url.startsWith('/search-results');
  }
  slugifyString(text) {
    return slugify(text, {
      replacement: '-',
      remove: /[*+~.()'"!:@?]/g,
      lower: true,
    });
  }

  // get news from prismic

  getNews(params): any {
    this.prismic
      .getArticlesByType('frett')
      .then((data: PrismicBaseInterface) => {
        this.prismicNews = data.results;
        if (params === '') {
          return;
        } else {
          this.prismicNews = this.prismicNews.filter(item => {
            const title = item.data.title[0].text.toLowerCase();

            return title.includes(params.search.toLowerCase());
          });
        }
      });
  }

  // get staff from prismic
  getStaff(params): any {
    this.prismic
      .getArticlesByType('employee')
      .then((data: PrismicBaseInterface) => {
        this.prismicStaff = data.results;
        if (params === '') {
          return;
        } else {
          this.prismicStaff = this.prismicStaff.filter(item => {
            const title = item.data.employee_name[0].text.toLowerCase();
            return title.includes(params.search.toLowerCase());
          });
        }
      });
  }
  getManagement(params): any {
    this.prismic
      .getArticlesByType('management')
      .then((data: PrismicBaseInterface) => {
        this.managementData = data.results;

        // Extract the list items first before searching
        this.managementData = this.managementData.flatMap(item => {
          return item.data.management_content.filter(
            content => content.type === 'list-item'
          );
        });

        if (params !== '') {
          this.managementData = this.managementData.filter(item => {
            const title = item.text.toLowerCase();
            return title.includes(params.search.toLowerCase());
          });
        }
      });
  }

  searchCourses(namType, params: FilterParams) {
    this.results = null;
    this.getNews(params);
    this.getStaff(params);
    this.getManagement(params);

    this.educationService.filterCourses('*', params).then(
      (data: FmResponse) => {
        this.results = data.response.data;
        this.nothingFound = this.results.length === 0;
        this.loading = false;
      },
      err => {
        console.error('THERE WAS AN ERROR IN SEARCHCOURSES', err);
        this.results = null;
        this.nothingFound = true;
        this.loading = false;
      }
    );
  }

  ngOnDestroy(): void {
    this.clearSearchResults();
  }

  clearSearchResults() {
    this.results = null;
    this.searchQuery = null;
    this.educationService.clearSortingAndFilters();
  }

  onClearSearch(): void {
    this.clearSearchResults();
    if (this.router.url.includes('search-results')) {
      if (this.general.getBrowserName() === 'firefox') {
        this.router.navigate(['/']);
      } else {
        this.loc.back();
      }
    } else if (this.header) {
    } else {
      this.router.navigate([], { queryParams: {} });
    }
  }
}
