// tslint:disable: variable-name
export class Namskeid {
  Annir: string;
  Byrjar_Dags: string;
  Byrjar_Dags_: string;
  Dags_Lidinn: number;
  Endar_Dags: string;
  Endar_Dags_: string;
  Fagrad: string;
  Fagleg_Umsjon: string;
  Fag_Umsjon_Mynd: string;
  Fag_Umsjon_netfang: string;
  Fag_Umsjon_simi: string;
  Fag_Umsjon_almennt: string;
  Fjarkennsla_: string;
  Fjarkennsla: string;
  Fjoldi_laus_saeti: string;
  Greining_Vefur: string;
  Haefnisvidmid_Avinningur: string;
  Hefst_KL: string;
  Heiti: string;
  ID: number;
  ID_Namskeidaflokkur: string;
  ID_Undirflokkur: string;
  ID_Yfirflokkur: string;
  Kennari: string;
  Kennslumat: string;
  Kynning: string;
  Lanamoguleikar_Styrkir: string;
  Lanshaeft: string;
  Lengd: number;
  Lokadags_Skraningar: string;
  Lykur_KL: string;
  Lysing_flokkud_1: string;
  Lysing_flokkud_2: string;
  Lysing_flokkud_3: string;
  Lysing_flokkud_4: string;
  Lysing_flokkud_5: string;
  Titill_flokkud_1: string;
  Titill_flokkud_2: string;
  Titill_flokkud_3: string;
  Titill_flokkud_4: string;
  Titill_flokkud_5: string;
  Markmid: string;
  Mynd: string;
  Mynd_Vefslod: string;
  Myndband_Mynd_Vefslod: string;
  Myndband_Vefslod: string;
  Namskeid_Id: string;
  Namskeidsflokkur: string;
  Namsefni: string;
  Namsmat: string;
  Numer: string;
  Nytt_Endurtekid_: string;
  Pdf_Slod: string;
  Samvinnutexti: string;
  Skilmalar: string;
  Stadsetning: string;
  Stjornur: string;
  Stutt_Lysing: string;
  Stutt_Lysing_Ny: string;
  Taekjabunadur: string;
  Tegund_eydublads: string;
  Textaleit: string;
  Timi: string;
  Umsagnir: string;
  Umsoknarferli: string;
  Umsjon: string;
  Umsjon_almennt: string;
  Umsjon_Mynd: string;
  Umsjon_netfang: string;
  Umsjon_simi: string;
  Undirflokkur: string;
  Undirtitill: string;
  Verd: number;
  Verd_Snemmskraning: number;
  Text_Snemmskraning: string;
  Yfirflokkur: string;
  rafraenskjol: string;
}

export class PortalData {
  Myndir_Namskeid?: Array<{
    Myndir_Namskeid: string;
    Mynd: string;
  }>;
  Auka_Umsagnir_Vefur?: Array<{
    'Auka_Umsagnir_Vefur::Nafn': string;
    'Auka_Umsagnir_Vefur::Umsöng': string;
    'Auka_Umsagnir_Vefur::Mynd': string;
  }>;

}

export class NamskeidRes {
  messages: Array<{}>;
  response: {
    data: Array<{
      fieldData: Namskeid;
      portalData: PortalData;
      modId;
      recordId;
    }>;
  };
}

/** Detect if this course offers an early registration */
export function hasEarlyRegistration(course: Namskeid): boolean {
  return (course?.Text_Snemmskraning?.length ?? 0) > 0;
}
