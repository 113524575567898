import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
// Models
import { RegistrationPayment } from '@endurmenntun/_models/forms/registration-payment.class';
import { LocalstorageService } from '../localstorage/localstorage.service';

@Injectable({
  providedIn: 'root',
})
export class RegistrationPaymentService {
  public courseDetails = new BehaviorSubject({});

  constructor(private storage: LocalstorageService) {}

  emitCourse(value: any) {
    this.courseDetails.next(value);
  }

  setUserInformation(data: any) {
    this.storage.setItem('en-tempUserInfo', JSON.stringify(data));
  }

  getUserInformation(): RegistrationPayment {
    const retriveUserInfo = this.storage.getItem('en-tempUserInfo');
    return JSON.parse(retriveUserInfo);
  }

  removeUserInformation() {
    this.storage.removeItem('en-tempUserInfo');
  }
}
