import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
// Models
import { Skraningarblad } from '@endurmenntun/_models/forms/form.class';
import { Language } from '@endurmenntun/_services/language.service';
import { cleanIcelandicPhoneNumber } from 'src/utils';
import { postals, postalsEN } from '../postals/postals';

@Component({
  selector: 'en-form-type-one',
  templateUrl: './form-type-one.component.html',
  styleUrls: ['./form-type-one.component.less', '../form.component.less'],
})
export class FormTypeOneComponent implements OnInit, OnChanges {
  @Input() namskeidId;
  @Input() lang: Language;
  @Input() disclaimer;
  @Input() digital;
  @Input() remote;
  @Input() reqProcessing: boolean = false;
  @Input() registerStudentError;
  @Output() registerStudent = new EventEmitter<any>();

  public model: Skraningarblad;
  public netfangEndurtekid: string;
  public kennitalaBlur = false;
  public farsimiBlur = false;
  public netfangBlur = false;

  // Ef Greiðandi er annar en skráningsaðili
  public kennitalaGreidandaBlur = false;
  public netfangGreidandaEndurtekid: string;
  public netfangGreidandaBlur = false;
  public netfangMottakandaBlur = false;
  public postalCodes = Object.values(postals);

  // show validation error at bottom of form
  public formValid = true;

  constructor() {}

  /** Language enum, accessible within template */
  public get Language() {
    return Language;
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.namskeidId && changes.model) {
      this.model.Namskeid_Id = this.namskeidId;
    }
  }

  onPaste(event) {
    const kt: string = event.clipboardData.getData('text/plain');
    if (event.target.name.includes('kennitalagreidanda')) {
      this.model.Kennitala_Greidanda = kt.replace(/\D/g, '');
    } else {
      this.model.Kennitala = kt.replace(/\D/g, '');
    }
  }

  ngOnInit(): void {
    this.model = new Skraningarblad(
      this.namskeidId,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'false',
      'false',
      '',
      'RUSL',
      '',
      '',
      ''
    );

    this.model.Hver_Greidir = 'Þátttakandi';
    this.model.Greidslumati = 'Pei';

    if (this.lang === Language.English) {
      this.postalCodes = Object.values(postalsEN);
    }
  }

  onSubmit(args) {
    if (args.controls.fjarnam && args.controls.fjarnam.value) {
      args.controls.fjarnam.value = args.controls.fjarnam.value.toString();
    }
    if (args.controls.skilmalar && args.controls.skilmalar.value) {
      args.controls.skilmalar.value = args.controls.skilmalar.value.toString();
    }
    args.form.updateValueAndValidity();

    if (args.form.invalid) {
      Object.keys(args.form.controls).forEach(key => {
        args.form.get(key).markAsDirty();
      });
      this.formValid = false;
      return;
    } else {
      this.formValid = true;
      this.registerStudent.emit({ model: this.model });
    }
  }

  getOnlyPostal(postal) {
    var numberPattern = /\d+/g;
    var justCode = postal.match(numberPattern);
    if (justCode !== null) {
      return justCode;
    } else {
      return postal;
    }
  }

  /** Clean up phone number, if we got it from clipboard */
  public onPhoneNumberFromClipboard(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text/plain');
    const phoneNumber = cleanIcelandicPhoneNumber(clipboardData);

    this.model.Farsimi = phoneNumber;
  }
}
