<div class="not-found wrapper">
  <div class="not-found__header">
    <en-overhead-title>
      {{ prismicContent?.overhead_title }}
    </en-overhead-title>
    <en-heading [headingContent]="prismicContent?.title[0]"></en-heading>
  </div>

  <div class="not-found__content">
    <ng-container *ngIf="prismicContent">
      <en-prismic-text
        [prismicText]="{ content: prismicContent?.content }"
      ></en-prismic-text>
    </ng-container>

    <div class="image">
      <img src="../../../../assets/illustrations/undraw_road_sign_mfpo.svg" />
    </div>
  </div>

  <!-- <en-button routerLink="" [buttonContent]="'Fara til baka á forsíðu'" [buttonType]="'regular'"></en-button> -->
</div>
