<form (ngSubmit)="onSubmit(skraningarForm)" #skraningarForm="ngForm">
  <div class="form-inputs">
    <div class="form-input-group">
      <label for="nafn">
        {{ lang === 'is' || !lang ? 'Nafn' : 'Name' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="nafn"
        required
        [(ngModel)]="model.Nafn"
        name="nafn"
        #name="ngModel"
        autocomplete="name"
      />
      <div class="alert" [hidden]="name.valid || name.pristine">
        {{
          lang === 'is' || !lang
            ? 'Nafn þarf að vera fyllt út'
            : 'Name is required'
        }}
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (kennitala.invalid &&
            kennitala.errors.required &&
            kennitala.touched) ||
          (kennitala.invalid && kennitalaBlur && kennitala.errors.minlength)
      }"
    >
      <label for="kennitala">
        {{
          lang === 'is' || !lang ? 'Kennitala' : 'Personal ID/ Date of Birth'
        }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="kennitala"
        required
        enValidKennitala
        [(ngModel)]="model.Kennitala"
        name="kennitala"
        #kennitala="ngModel"
        minlength="{{ lang === 'is' || !lang ? 10 : 6 }}"
        maxlength="{{ lang === 'is' || !lang ? 10 : 6 }}"
        (paste)="onPaste($event)"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="kennitalaBlur = true"
        autocomplete="off"
      />
      <ng-container
        *ngIf="kennitala.invalid && (kennitala.dirty || kennitala.touched)"
      >
        <div class="alert" *ngIf="kennitala.control.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Kennitala þarf að vera fyllt út'
              : 'Personal ID/ Date of Birth is required'
          }}
        </div>
        <div
          class="alert"
          *ngIf="kennitala.control.errors.minlength && kennitalaBlur === true"
        >
          {{
            lang === 'is' || !lang
              ? 'Þessi kennitala þarf að vera 10 stafir'
              : 'Date of birth can has to be 6 digits, day-month-year, Example: 241272'
          }}
        </div>
        <div
          class="alert"
          *ngIf="
            kennitala.control.errors.invalidKennitala && kennitalaBlur === true
          "
        >
          {{
            lang === 'is' || !lang
              ? 'Kennitala er ekki gild.'
              : 'This personal ID is not valid.'
          }}
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="heimilisfang">
        {{ lang === 'is' || !lang ? 'Heimilisfang' : 'Address' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="heimilisfang"
        required
        [(ngModel)]="model.Heimilisfang"
        name="heimilisfang"
        #heimilisfang="ngModel"
        autocomplete="street-address"
      />
      <div class="alert" [hidden]="heimilisfang.valid || heimilisfang.pristine">
        {{
          lang === 'is' || !lang
            ? 'Heimilisfang þarf að vera fyllt út'
            : 'Address is required'
        }}
      </div>
    </div>

    <div class="form-input-group">
      <label for="postnumer">
        {{ lang === 'is' || !lang ? 'Póstnúmer' : 'Zip Code' }}
        <span class="must-fill">*</span>
      </label>
      <select
        name="postnumer"
        id="postnumer"
        required
        [(ngModel)]="model.Postnumer"
        #postnumer="ngModel"
        autocomplete="postal-code"
      >
        <option disabled selected value>
          {{
            lang === 'is' || !lang
              ? '-- Veldu póstnúmer -- '
              : 'Select Zip Code'
          }}
        </option>
        <ng-container *ngFor="let postalCode of postalCodes">
          <option [value]="getOnlyPostal(postalCode)">{{ postalCode }}</option>
        </ng-container>
      </select>
      <div class="alert" [hidden]="postnumer.valid || postnumer.pristine">
        {{
          lang === 'is' || !lang
            ? 'Póstnúmer þarf að vera fyllt út'
            : 'Zip code is required'
        }}
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (farsimi.invalid && farsimi.errors.required && farsimi.touched) ||
          (farsimi.invalid && farsimiBlur && farsimi.errors.minlength)
      }"
    >
      <label for="nafn">
        {{ lang === 'is' || !lang ? 'Farsími' : 'Mobile phone' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="farsimi"
        required
        [(ngModel)]="model.Farsimi"
        name="farsimi"
        #farsimi="ngModel"
        minlength="7"
        maxlength="7"
        autocomplete="tel"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="farsimiBlur = true"
        (paste)="onPhoneNumberFromClipboard($event)"
      />
      <ng-container
        *ngIf="farsimi.invalid && (farsimi.dirty || farsimi.touched)"
      >
        <div class="alert" *ngIf="farsimi.control.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Farsími þarf að vera fylltur út'
              : 'Mobile phone is required'
          }}
        </div>
        <div
          class="alert"
          *ngIf="farsimi.control.errors.minlength && farsimiBlur === true"
        >
          {{
            lang === 'is' || !lang
              ? 'Farsími þarf að vera 7 stafir'
              : 'Mobile phone has to be atleast 7 digits'
          }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="form-inputs">
    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (netfang.invalid && netfang.errors.required && netfang.touched) ||
          (netfang.invalid && netfangBlur && netfang.errors.minlength)
      }"
    >
      <label for="email">
        {{ lang === 'is' || !lang ? 'Netfang' : 'Email' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="email"
        id="netfang"
        required
        [(ngModel)]="model.Netfang"
        name="netfang"
        #netfang="ngModel"
        autocomplete="email"
        (ngModelChange)="model.Netfang = $event.toLowerCase()"
        pattern="^(.+@.+\..+)$"
        (blur)="netfangBlur = true"
      />
      <ng-container
        *ngIf="netfang.invalid && (netfang.dirty || netfang.touched)"
      >
        <div class="alert" *ngIf="netfang.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Netfang þarf að vera fyllt út'
              : 'Email is required'
          }}
        </div>
        <div
          class="alert"
          *ngIf="netfang.errors.pattern && netfangBlur === true"
        >
          {{
            lang === 'is' || !lang
              ? 'Netfang þarf að vera gilt'
              : 'Email has to be valid'
          }}
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="netfangStadfest">
        {{ lang === 'is' || !lang ? 'Endurtaka netfang' : 'Repeat email' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="email"
        id="netfangStadfest"
        required
        name="netfangStadfest"
        #netfangStadfest="ngModel"
        [(ngModel)]="netfangEndurtekid"
        (ngModelChange)="netfangEndurtekid = $event.toLowerCase()"
        pattern="{{ netfang.value }}"
      />
      <ng-container
        *ngIf="
          netfangStadfest.invalid &&
          (netfangStadfest.dirty || netfangStadfest.touched)
        "
      >
        <div class="alert" *ngIf="netfangStadfest.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Vinsamlegast endurtaktu netfangið'
              : 'Please repeat your email'
          }}
        </div>
        <div class="alert" *ngIf="netfangStadfest.errors.pattern">
          {{
            lang === 'is' || !lang
              ? 'Netföng passa ekki'
              : 'Emails do not match'
          }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-input-group fullwidth checkbox">
    <div class="checkbox-wrapper">
      <div class="checkmark-container">
        <input
          type="checkbox"
          id="postlisti"
          [value]="'true'"
          [(ngModel)]="model.Postlisti"
          name="postlisti"
          #postlisti="ngModel"
        />
        <div class="checkmark"></div>
      </div>
      <label for="nafn" *ngIf="lang === 'is' || !lang"
        >Já, takk. Ég vil fá senda tölvupósta um námskeið og námsbrautir
        Endurmenntunar - skrá mig á póstlista.</label
      >
      <label for="nafn" *ngIf="lang === 'en'"
        >I want to receive emails about courses and classes from Endurmenntun -
        Sign me up for mailing list.</label
      >
    </div>
  </div>

  <div class="form-input-group fullwidth text">
    <label for="nafn">
      {{ lang === 'is' || !lang ? 'Athugasemdir' : 'Comments' }}
    </label>
    <textarea
      type="text"
      id="athugasemdir"
      rows="6"
      [(ngModel)]="model.Athugasemd"
      name="athugasemdir"
      #athugasemdir="ngModel"
      maxlength="300"
    ></textarea>
    <div class="max-letters">
      <span>
        <ng-container
          *ngIf="athugasemdir?.value && athugasemdir?.value.length !== 0"
        >
          {{ athugasemdir.value.length }}
        </ng-container>
      </span>
      <span *ngIf="lang === 'is' || !lang">Hámark 300 stafabil</span>
      <span *ngIf="lang === 'en'">Maximum 300 characters</span>
    </div>
  </div>

  <div class="form-info teaching-material">
    <div class="form-info__content">
      <p>
        Tölvupóstur verður sendur sem staðfesting á móttöku skráningar. Ef þér
        berst engin staðfesting, vinsamlega hafðu samband við ENDURMENNTUN í
        síma 525 4444 eða sendu tölvupóst á
        <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
      </p>
    </div>
  </div>

  <div class="form-info teaching-material" *ngIf="digital">
    <h2>{{ lang === 'is' || !lang ? 'Námsgögn' : 'Course material' }}</h2>
    <div class="form-info__content">
      <p *ngIf="lang === 'is' || !lang">
        Ef námsgögn fylgja námskeiðinu þá verða þau eingöngu á rafrænu formi og
        send á það netfang sem þátttakandi gefur upp við skráningu. Það er svo
        ákvörðun viðkomandi kennara hvort námsgögn verði send út áður en
        námskeið hefst eða eftir að því lýkur.
      </p>
      <p *ngIf="lang === 'en'">
        If study materials accompany the course, they will only be in electronic
        form and sent to the email address provided by the participant during
        registration. It is the decision of the respective teacher whether study
        materials will be sent out before the course begins or after it ends.
      </p>
    </div>
  </div>

  <ng-container *ngIf="disclaimer !== ''">
    <div class="form-info terms-and-conditions">
      <h2>
        {{ lang === 'is' || !lang ? 'Skilmálar' : 'Terms and conditions' }}
      </h2>
      <div class="form-info__content">
        <p [innerHTML]="disclaimer"></p>
      </div>

      <div class="form-input-group fullwidth checkbox">
        <div class="checkbox-wrapper">
          <div class="checkmark-container">
            <input
              type="checkbox"
              id="skilmalar"
              required
              [value]="'true'"
              name="skilmalar"
              [(ngModel)]="model['Samþykkja_skilmala']"
              #skilmalar="ngModel"
            />
            <div class="checkmark"></div>
          </div>
          <label for="skilmalar" *ngIf="lang === 'is' || !lang"
            >Ég samþykki ofangreinda skilmála</label
          >
          <label for="skilmalar" *ngIf="lang === 'en'"
            >I accept the mentioned terms</label
          >
        </div>
        <div
          class="alert"
          [hidden]="
            skilmalar.valid || skilmalar.pristine || skilmalar.value === 'true'
          "
        >
          {{
            lang === 'is' || !lang
              ? 'Skilmálar þarf að vera fyllt út'
              : 'Terms and conditions have to be agreed upon'
          }}
        </div>
      </div>
    </div>
  </ng-container>

  <en-button
    [buttonContent]="'Staðfesta'"
    [buttonType]="'regular'"
    [buttonColor]="'red'"
    type="submit"
  ></en-button>
  <ng-container *ngIf="reqProcessing"> Vinn úr skráningu... </ng-container>

  <div class="alert" [hidden]="formValid || reqProcessing">
    <ng-container *ngIf="reqProcessing === false">
      {{
        lang === 'is' || !lang
          ? 'Villa kom upp í skráningu, vinsamlegast fylgdu villumeldingum hér fyrir ofan'
          : 'An error occured in the registration, please follow the error messages above.'
      }}
    </ng-container>
  </div>
  <div class="alert" [hidden]="!registerStudentError">
    <ng-container *ngIf="registerStudentError && registerStudentError.status">
      Villa kom upp í skráningarferlinu. Vinsamlegast hafðu samband við
      ENDURMENNTUN í síma <a href="tel:5254444">525 4444</a> eða sendu tölvupóst
      á <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
    </ng-container>
  </div>
</form>
