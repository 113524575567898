import { Component, OnInit } from '@angular/core';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-management',
  templateUrl: './management.component.html',
  styleUrls: ['./management.component.less'],
})
export class ManagementComponent implements OnInit {
  management: any;

  constructor(private prismic: PrismicService) {}

  ngOnInit(): void {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.getManagement();
      }
    });
  }

  getManagement() {
    this.prismic
      .getDocumentByType('management')
      .then((data: PrismicBaseInterface) => {
        this.management = data.results[0];
      });
  }
}
