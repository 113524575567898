import { Component, PLATFORM_ID, Inject } from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { registerLocaleData } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import localeIs from '@angular/common/locales/is';
registerLocaleData(localeIs, 'is');
// services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
// models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-frett',
  templateUrl: './frett.component.html',
  styleUrls: ['./frett.component.less'],
})
export class FrettComponent {
  public article: any;
  public frettNotFound = false;
  public loc = this.location.path(false);

  constructor(
    private prismic: PrismicService,
    private location: Location,
    private route: ActivatedRoute,
    private meta: MetaDataService,
    @Inject(PLATFORM_ID) private platformId: any
  ) {
    this.route.params.subscribe(params => {
      let articleUID;
      if (params.frett) {
        articleUID = params['frett'];
      } else {
        articleUID = this.location.path().split('/').pop();
      }
      this.getArticle(articleUID);
    });
  }

  getArticle(articleUid): void {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.prismic.getArticleByUid(articleUid, this.loc).then(
          (data: PrismicBaseInterface) => {
            this.article = data.results;
            this.meta.setMetaTags(data.results[0].data);
          },
          err => {
            console.error(err);
          }
        );
      }
    });
  }

  public toggleAccordian(event, index): void {
    var element = event.currentTarget;
    element.classList.toggle('active');
    var panel = element.nextElementSibling;
    panel.classList.toggle('active');
  }
}
