import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import {
  EduframeAugmentedFmResponse,
  FmResponse,
} from '../interfaces/filemaker-query.interface';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';
import {
  Course,
  CourseWithIncludes,
  PlannedCourse,
  PlannedCourseWithIncludes,
} from 'src/eduframe';
import { GlobalVariablesService } from '../../../../_services/globalVariables/globalVariables.service';

interface FilterParameters {
  label_id?: string | Array<string>;
  course_variant_id?: string | Array<string>;
  search?: string;
}

@Component({
  selector: 'en-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.less'],
})
export class CoursesComponent implements OnInit, AfterViewInit {
  @ViewChild('results') searchResults: ElementRef;
  public prismicContent: any;
  public coursesError: { error: number };
  public courses: any;
  public allCourses: any[];
  public filterActive: boolean = false;
  public prismicLoaded: Observable<boolean>;
  public fmLoaded: Observable<boolean>;
  public courseFilterIsActive: boolean;
  public renderLimit: number = 20;

  constructor(
    private educationService: FakeMakerService,
    private prismic: PrismicService,
    private meta: MetaDataService,
    private route: ActivatedRoute,
    private general: GeneralService,
    private globalVariablesService: GlobalVariablesService
  ) {
    this.route.queryParams.subscribe(params => {
      this.filterCourses(params as FilterParameters);
    });
  }

  ngOnInit(): void {
    // Load courses first and filter only after courses are loaded
    this.getCourses();
    this.getPrismicContent('namskeid');

    this.globalVariablesService.courseFilterIsActive$.subscribe(
      (state: boolean) => {
        this.courseFilterIsActive = state;
      }
    );
  }

  ngAfterViewInit(): void {
    let activeParam: string = this.route.snapshot.queryParams.search;
    this.route.queryParams.subscribe(param => {
      if (activeParam !== param.search) {
        activeParam = param.search;
        this.scrollResultsIntoView();
      }
    });
  }

  private filterCourses(params: FilterParameters): void {
    // Only filter if we have all courses
    if (!this.allCourses) {
      return;
    }

    this.courses = this.allCourses.filter(course => {
      const eduframeCourse = course.eduframeCourse as CourseWithIncludes;
      const eduframePlannedCourse =
        course.eduframePlannedCourse as PlannedCourseWithIncludes;

      // Apply filtering logic
      if (params.course_variant_id && params.label_id) {
        const label_id = Number(params.label_id);
        const course_variant_id = Number(params.course_variant_id);
        return (
          eduframePlannedCourse.course_variant_id == course_variant_id &&
          eduframeCourse.label_ids.includes(label_id) &&
          course.fieldData.Fjarkennsla_ === 'true'
        );
      }

      // Filter by label if only label_id is provided
      if (params.label_id && !params.course_variant_id) {
        const label_id = Number(params.label_id);
        return eduframeCourse.label_ids.includes(label_id);
      }

      // Filter by variant if only course_variant_id is provided
      if (params.course_variant_id && !params.label_id) {
        const course_variant_id = Number(params.course_variant_id);
        return eduframePlannedCourse.course_variant_id == course_variant_id;
      }

      // Filter by search
      if (params.search) {
        const search = params.search.toLowerCase();
        return eduframeCourse.name.toLowerCase().includes(search);
      }

      // No filter parameters, include all courses
      return true;
    });

    this.filterActive = true;
    this.coursesError = this.courses.length == 0 ? { error: 404 } : null;
  }

  public getPrismicContent(type): void {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.prismic
          .getDocumentByType(type)
          .then((data: PrismicBaseInterface) => {
            this.prismicContent = data.results[0].data;
            this.meta.setMetaTags(data.results[0].data);
          })
          .then(() => {
            this.prismicLoaded = this.general.showContent();
          });
      }
    });
  }

  public getMoreFilteredCourses(params): void {
    const offset = this.courses.length + 1;
    if (this.courses.length > 1) {
      this.educationService.filterCourses('Y0003', params, null, offset).then(
        (data: FmResponse) => {
          this.courses.push(...data.response.data);
        },
        err => {
          console.error(err);
        }
      );
    }
  }

  public clearSortingAndFilters(): void {
    this.globalVariablesService.setButtonHiddenState(false);
    this.globalVariablesService.setActiveFilter(undefined);
    this.educationService.clearSortingAndFilters();
  }

  private getCourses(): void {
    this.coursesError = null;

    this.educationService
      .getCourses()
      .then(
        (data: EduframeAugmentedFmResponse) => {
          this.courses = data.response.data;
          this.allCourses = data.response.data;

          // After courses are loaded, apply the filters
          const params = this.route.snapshot.queryParams;
          this.filterCourses(params as FilterParameters); // Apply filters based on query params
        },
        err => {
          console.error(err);
          this.coursesError = { error: 401 };
        }
      )
      .then(() => {
        this.fmLoaded = this.general.showContent();
      });
  }

  public getMoreCourses(): void {
    const offset = this.courses.length + 1;
    this.educationService.getCourses(offset.toString()).then(
      (data: FmResponse) => {
        this.courses.push(...data.response.data);
      },
      err => {
        console.error(err);
      }
    );
  }

  private scrollResultsIntoView(): void {
    const el = this.searchResults.nativeElement;
    el.scrollIntoView({ behaviour: 'smooth', block: 'start' });
  }

  getEduframeCourseLink(course: Course, plannedCourse: PlannedCourse) {
    return `/namskeid/${course.id}/${course.slug}/${plannedCourse.id}`;
  }

  public loadMoreCourses(): void {
    if (this.renderLimit >= this.courses.length) {
      return;
    }

    this.renderLimit += 10;
  }
}
