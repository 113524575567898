<div class="hero-banner">
  <div
    class="hero-banner__content trs-hero-content"
    [ngClass]="{ loaded: (prismicLoaded) }"
  >
    <en-overhead-title>Menntun fyrir alla</en-overhead-title>
    <en-heading
      [headingContent]="{ type: 'heading1', text: 'Námskeið' }"
    ></en-heading>
    <en-search [viewAll]="false"></en-search>
  </div>

  <div
    class="hero-banner__media trs-hero-img"
    [ngClass]="{ loaded: (prismicLoaded | async) }"
  >
    <img
      [src]="prismicContent?.banner_image.url"
      [alt]="prismicContent?.banner_image.alt"
      loading="lazy"
    />
  </div>
</div>

<en-filter [activeCourseType]="'Y0003'"></en-filter>

<div class="nam_component wrapper" #results>
  <ng-container *ngIf="courses?.length && !coursesError; else rendering">
    <div class="main-grid">
      <ng-container *ngFor="let course of courses.slice(0, this.renderLimit); let last = last">
        <a
          [routerLink]="[
            getEduframeCourseLink(
              course.eduframeCourse,
              course.eduframePlannedCourse
            )
          ]"
        >
          <en-course-card [cont]="course" [loaded]="fmLoaded"></en-course-card>
        </a>
      </ng-container>

      <en-button
        *ngIf="courses.length > renderLimit"
        (click)="loadMoreCourses()"
        [buttonType]="'regular'"
        [buttonContent]="'Birta fleiri námskeið'"
        [buttonColor]="'blue'"
      ></en-button>

    </div>
  </ng-container>


  <ng-template #rendering>
    <ng-container *ngIf="coursesError; else loading">
      Engin námskeið fundust undir þessum forsendum
    </ng-container>
    <ng-template #loading>
      <en-course-grid-skeleton></en-course-grid-skeleton>
    </ng-template>
  </ng-template>
</div>

<ng-container *ngIf="courseFilterIsActive === true">
  <ng-container *ngIf="filterActive">
    <en-button
      [buttonType]="'clear'"
      [buttonContent]="'Hreinsa val'"
      (click)="clearSortingAndFilters()"
    ></en-button>
  </ng-container>
</ng-container>
