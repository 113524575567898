<div class="course-rating_component">
  <en-overhead-title> Á mælikvarðanum 1 til 5 </en-overhead-title>

  <h1>Mat þátttakenda</h1>

  <div class="rating">
    <div class="rating-block">
      <div
        class="rating-fill"
        [ngStyle]="{ width: stars >= 1 ? '100%' : decimalNo + '0%' }"
      ></div>
    </div>

    <div class="rating-block">
      <div
        class="rating-fill"
        [ngStyle]="{ width: stars >= 2 ? '100%' : decimalNo + '0%' }"
      ></div>
    </div>

    <div class="rating-block">
      <div
        class="rating-fill"
        [ngStyle]="{ width: stars >= 3 ? '100%' : decimalNo + '0%' }"
      ></div>
    </div>

    <div class="rating-block">
      <div
        class="rating-fill"
        [ngStyle]="{ width: stars >= 4 ? '100%' : decimalNo + '0%' }"
      ></div>
    </div>

    <div class="rating-block">
      <div
        class="rating-fill"
        [ngStyle]="{ width: stars >= 5 ? '100%' : decimalNo + '%' }"
      ></div>
    </div>
  </div>

  <span class="rating-number text"> {{ rating }} af 5 </span>
</div>
