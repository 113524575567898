import { FmResponse } from './../education/interfaces/filemaker-query.interface';
import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
// Models
import { NamskeidRes } from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import { Namskeid } from './../education/interfaces/namskeid.interface';
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import slugify from 'slugify';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';

@Component({
  selector: 'en-courses-english',
  templateUrl: './courses-english.component.html',
  styleUrls: ['./courses-english.component.less'],
})
export class CoursesEnglishComponent implements OnInit {
  prismicContent: any;
  courses: Array<{ fieldData?: Namskeid }>;
  coursesInEnglishActiveTab: any = 'About';

  constructor(
    private prismic: PrismicService,
    private meta: MetaDataService,
    private educationService: FakeMakerService
  ) {}

  ngOnInit(): void {
    this.getPrismicContent();
    this.educationService.bearerToken.subscribe(value => {
      if (value) {
        this.getFileMakerContent();
      }
    });
  }

  slugifyString(text) {
    return slugify(text, {
      replacement: '-',
      remove: /[*+~.()'"!:@?]/g,
      lower: true,
    });
  }

  async getFileMakerContent(): Promise<void> {
    this.educationService.getCoursesInEnglish().then(
      (data: FmResponse) => {
        this.courses = data.response.data;
      },
      err => {
        console.error(err);
      }
    );
  }

  getPrismicContent(): void {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.prismic
          .getDocumentByType('courses_in_english')
          .then((data: PrismicBaseInterface) => {
            this.prismicContent = data.results[0].data;
            this.meta.setMetaTags(data.results[0].data);
          });
      }
    });
  }

  toggleAbout(tab): void {
    this.coursesInEnglishActiveTab = tab;
  }
}
