<div class="employee-card">
  <figure class="image-container">
    <img [src]="content?.employee_image.url" alt="" />
  </figure>

  <div class="employee-card__content">
    <div class="opening-hours" *ngIf="content?.opening_hours">
      <span class="icon info" (click)="toggleOpeningHours()">
        <img src="../../../../../assets/Media/icons/info.png" alt="" />
      </span>
      <div
        class="window"
        *ngIf="openingHoursOpen === true"
        [ngClass]="{ open: openingHoursOpen === true }"
      >
        <p>{{ content?.employee_opening_hours }}</p>
        <span class="icon exit" (click)="toggleOpeningHours()">
          <img src="../../../../../assets/Media/icons/Close.png" alt="" />
        </span>
      </div>
    </div>

    <h2>{{ content?.employee_name[0].text }}</h2>
    <span class="text-under">{{ content?.employee_title }}</span>

    <div class="description">
      <div
        class="description-item-container phone"
        *ngIf="content?.employee_phone"
      >
        <span class="icon">
          <img src="../../../../../assets/Media/icons/phone.png" alt="" />
        </span>
        <a href="tel:{{ content?.employee_phone }}">{{
          content?.employee_phone
        }}</a>
      </div>
      <div
        class="description-item-container mail"
        *ngIf="content?.employee_email"
      >
        <span class="icon">
          <img src="../../../../../assets/Media/mail.png" alt="" />
        </span>
        <a href="mailto:{{ content?.employee_email }}">{{
          content?.employee_email
        }}</a>
      </div>
      <div class="description__paragraph" *ngIf="content?.lysing_stjornanda">
        <en-prismic-text
          [prismicText]="{ content: content?.lysing_stjornanda }"
        ></en-prismic-text>
      </div>
    </div>
  </div>
</div>
