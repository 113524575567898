<div class="pdf-container">
  <div class="pdf-document" id="htmlData" #htmlData>
    <h1>{{ filemakerContent?.Heiti }}</h1>

    <div class="verd">
      <span>Verð</span>
      <h5>{{ filemakerContent?.Verd }}</h5>
    </div>

    <div class="description md-col-8">
      <p>{{ filemakerContent?.Kynning }}</p>
    </div>
  </div>
</div>
