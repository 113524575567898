import { FmResponse } from './../interfaces/filemaker-query.interface';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
// Services
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { Namskeid } from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';

@Component({
  selector: 'en-registration-form',
  templateUrl: './registration-form.component.html',
  styleUrls: ['./registration-form.component.less'],
})
export class RegistrationFormComponent implements OnInit {
  course: Namskeid;
  namskeidId = this.location.path().split('/').pop();
  registrationType: string;
  public fmLoaded: Observable<boolean>;

  private query = {
    query: [
      {
        Namskeid_Id: this.namskeidId,
      },
    ],
  };

  constructor(
    private router: Router,
    private location: Location,
    public general: GeneralService,
    private educationService: FakeMakerService
  ) {}

  ngOnInit(): void {
    this.educationService.bearerToken.subscribe(value => {
      if (value) {
        this.getCourse();
      }
    });
  }

  getCourse() {
    this.educationService
      .getSingleCourseOrClass(this.query)
      .then(
        (data: FmResponse) => {
          this.course = data.response.data[0].fieldData;
          this.course.Stadsetning = this.course.Stadsetning.replace(
            /\u00a0/g,
            ' '
          );
          this.registrationType =
            data.response.data[0].fieldData.Tegund_eydublads;
          this.checkIfRegistrationIsAvailable(this.course);
        },
        err => {
          // TODO: Handle error
          console.error(err);
        }
      )
      .then(() => {
        this.fmLoaded = this.general.showContent();
      });
  }

  public hasEarlyRegistrationEnabled(course: Namskeid): boolean {
    if (course?.Verd_Snemmskraning === undefined) {
      return false;
    }

    if (course?.Verd === undefined) {
      return false;
    }

    return course.Verd_Snemmskraning != course.Verd;
  }

  private checkIfRegistrationIsAvailable(course: any): void {
    if (
      course.Fjoldi_laus_saeti === 'Fullt' ||
      course.Fjoldi_laus_saeti === 'Stopp' ||
      course.Fjoldi_laus_saeti === 'Loka' ||
      course.Fjoldi_laus_saeti === '0' ||
      this.general.checkIfPast(course.Endar_Dags, course.Hefst_KL)
    ) {
      course.ID_Yfirflokkur.includes('Y0003')
        ? this.router.navigate(['namskeid'])
        : this.router.navigate(['namsbrautir']);
    }
  }
}
