import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ElementsModule } from './../elements/elements.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';

// Components
import { CourseCardComponent } from './course-card-component/course-card.component';
import { HeroBannerComponent } from './hero-banner-component/hero-banner.component';
import { ReferenceCardComponent } from './reference-card-component/reference-card.component';
import { SliderComponent } from './slider/slider.component';
import { CourseRatingComponent } from './course-rating-component/course-rating.component';
import { FrettCardComponent } from './frett-card-component/frett-card.component';
import { NewFrettirComponent } from './new-frettir/new-frettir.component';
import { CourseGridComponent } from './course-grid/course-grid.component';

// pipes
import { RemoveCommaPipe } from '@endurmenntun/_pipes/remove-comma-pipe.pipe';
import { SecureImagePipe } from '@endurmenntun/_pipes/secure-image.pipe';
import { CourseCardSkeletonComponent } from './skeleton/course-card-skeleton/course-card-skeleton.component';
import { CourseGridSkeletonComponent } from './skeleton/course-grid-skeleton/course-grid-skeleton.component';

@NgModule({
  declarations: [
    CourseCardComponent,
    HeroBannerComponent,
    SliderComponent,
    ReferenceCardComponent,
    CourseRatingComponent,
    RemoveCommaPipe,
    NewFrettirComponent,
    FrettCardComponent,
    SecureImagePipe,
    CourseGridComponent,
    CourseCardSkeletonComponent,
    CourseGridSkeletonComponent,
  ],
  imports: [
    CommonModule,
    ElementsModule,
    FormsModule,
    RouterModule,
    NgxUsefulSwiperModule,
  ],
  exports: [
    CourseCardComponent,
    HeroBannerComponent,
    SliderComponent,
    ReferenceCardComponent,
    CourseRatingComponent,
    RemoveCommaPipe,
    NewFrettirComponent,
    FrettCardComponent,
    SecureImagePipe,
    CourseGridComponent,
    CourseGridSkeletonComponent,
    CourseCardSkeletonComponent,
  ],
})
export class ComponentsModule {}
