import { Component } from '@angular/core';

@Component({
  selector: 'en-course-grid-skeleton',
  templateUrl: './course-grid-skeleton.component.html',
  styleUrls: ['./course-grid-skeleton.component.less'],
})
export class CourseGridSkeletonComponent {
  coursesSkeletonPlaceholder: Array<number> = new Array(30);
}
