import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
// services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-news-grid',
  templateUrl: './news-grid.component.html',
  styleUrls: ['./news-grid.component.less'],
})
export class NewsGridComponent implements OnInit {
  @Input() newsType: 'greinar' | 'frettir';
  prismicContentSubscription: Subscription;
  prismicNews: any;
  prismicArticles: any;

  constructor(private prismic: PrismicService) {}

  getNews(): void {
    this.prismic
      .getArticlesByType('frett')
      .then((data: PrismicBaseInterface) => {
        this.prismicNews = data.results;
      });
  }

  getGreinar(): void {
    this.prismic
      .getArticlesByType('greinar')
      .then((data: PrismicBaseInterface) => {
        this.prismicArticles = data.results;
      });
  }

  ngOnInit(): void {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.getNews();
        this.getGreinar();
      }
    });
  }
}
