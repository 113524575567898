import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GlobalVariablesService {
  // +++++
  // This is for the námskeið filter button visibility

  private _courseFilterIsActive = new BehaviorSubject<boolean>(false);
  private _activeFilter = new BehaviorSubject<string | undefined>(undefined);

  public courseFilterIsActive$ = this._courseFilterIsActive.asObservable();
  public activeFilter$ = this._activeFilter.asObservable();

  setButtonHiddenState(state: boolean) {
    this._courseFilterIsActive.next(state);
  }

  getButtonHiddenState() {
    return this._courseFilterIsActive.getValue();
  }

  setActiveFilter(filter: string | undefined) {
    this._activeFilter.next(filter);
  }

  getActiveFilter(): string | undefined {
    return this._activeFilter.getValue();
  }
}
