import { Component, Input} from '@angular/core';

export interface ReviewEntry {
  type: 'generic' | 'extra';
  review: string;
  image_url?: string;
  name?: string;
}
@Component({
  selector: 'en-reference-card',
  templateUrl: './reference-card.component.html',
  styleUrls: ['./reference-card.component.less'],
})
export class ReferenceCardComponent {
  @Input() cont: ReviewEntry;
  @Input() type: 'fm' | 'prismic';
  @Input() addShadowPadding: boolean = false;

  constructor() {}

 
}
