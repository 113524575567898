<div class="footer-actions wrapper">
  <div class="back">
    <a
      routerLink="/namskeid"
      class="text"
      *ngIf="!courseContent?.ID_Yfirflokkur.includes('Y0004')"
    >
      <img src="../../../../../assets/svg/arrow-left.svg" alt="" />
      Til baka í yfirlit
    </a>
    <a
      routerLink="/namsbrautir"
      class="text"
      *ngIf="courseContent?.ID_Yfirflokkur.includes('Y0004')"
    >
      <img src="../../../../../assets/svg/arrow-left.svg" alt="" />
      Til baka í yfirlit
    </a>
  </div>

  <div class="actions">
    Aðgerðir -
    <!-- <span class="text" (click)="pdf.openPDF()">
        <img src="../../../../../assets/Media/icons/printer.png" alt="">
        Prenta
      </span> -->
    <span class="text" (click)="toggleCalendar()">
      <img src="../../../../../assets/svg/calendar.svg" alt="" />
      Bæta í dagatal
      <ul [ngClass]="{ active: calendarOpen === true }">
        <a target="_new" [href]="googlePath">
          <li>Google</li>
        </a>

        <!-- <a target="_new" [href]="getSantizeUrl(iCalPath)">
          <li>ICal</li>
        </a> -->

        <a target="_new" [href]="outlookPath">
          <li>Outlook</li>
        </a>

        <a target="_new" [href]="outlookPath">
          <li>Outlook live</li>
        </a>
      </ul>
    </span>
  </div>

  <div class="share">
    Deila -
    <a>
      <img src="../../../../../assets/svg/facebook.svg" />
      <div
        class="fb-share-button"
        [attr.data-href]="'https://endurmenntun.is' + activePath"
      ></div>
    </a>

    <a
      href="http://www.linkedin.com/shareArticle?mini=true&url={{
        path
      }}&title=endurmenntun&summary=description&source=Endurmenntun"
      target="_new"
    >
      <img src="../../../../../assets/svg/linkedin.svg" />
    </a>

    <a
      href="https://twitter.com/share?url=http://www.google.com&text=Endurmenntun.is -"
      target="_new"
    >
      <img src="../../../../../assets/svg/twitter.svg" />
    </a>

    <a href="">
      <img src="../../../../../assets/svg/mail-red.svg" />
    </a>
  </div>
</div>

<en-pdf [filemakerContent]="courseContent" #pdf></en-pdf>
