import {
  Component,
  OnInit,
  AfterViewInit,
  AfterContentChecked,
  Inject,
  PLATFORM_ID,
  ViewChild,
  HostListener,
} from '@angular/core';
import { Location, isPlatformBrowser, ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { BehaviorSubject, fromEvent } from 'rxjs';
import { filter } from 'rxjs/operators';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { FakeMakerService } from './fake-maker/fake-maker.service';

declare let gtag: Function;

@Component({
  selector: 'en-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less'],
})
export class AppComponent
  implements OnInit, AfterViewInit, AfterContentChecked
{
  @ViewChild('headerElement') headerElement;
  public headerHeight: number = 100;
  static isBrowser = new BehaviorSubject<boolean>(null);
  public showHeaderAndFooter = true;
  public sticky: boolean = false;
  public searchInput: string;
  public loaded = false;
  public hideLoader = false;

  public rendering: boolean = true;

  constructor(
    private location: Location,
    public router: Router,
    private prismic: PrismicService,
    public viewportScroller: ViewportScroller,
    private educationService: FakeMakerService,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    AppComponent.isBrowser.next(isPlatformBrowser(platformId));

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.clearSearchResults();
        if (isPlatformBrowser(platformId)) {
          gtag('config', 'UA-37128849-1', {
            page_path: event.urlAfterRedirects,
          });
        }
      }
    });

    router.events
      .pipe(filter((e): e is Scroll => e instanceof Scroll))
      .subscribe(e => {
        if (e.position) {
          // backward navigation
          // restore navigation
          setTimeout(() => {
            viewportScroller.scrollToPosition(e.position);
          }, 10);
        } else if (e.anchor) {
          // anchor navigation
          viewportScroller.scrollToAnchor(e.anchor);
        } else if (!e.routerEvent.url.includes('?search=')) {
          // forward navigation
          // go to top
          // not search results
          viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
  @HostListener('window:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Backspace') {
      // Check if the event target is an input field
      if (
        (<HTMLElement>event.target).nodeName !== 'INPUT' &&
        (<HTMLElement>event.target).nodeName !== 'TEXTAREA'
      ) {
        this.location.back();
        event.preventDefault();
      }
    }
  }
  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) [(this.rendering = false)];
    this.prismic.assignRef();
    this.checkIfIframe();
    this.educationService.assignBearerToken();
  }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll').subscribe((e: Event) => {
        const fromTop = e.target['scrollingElement']['scrollTop'];
        fromTop > this.headerHeight
          ? (this.sticky = true)
          : (this.sticky = false);
      });
    }
  }

  checkIfIframe() {
    const loc = this.location.path().split('?')[0];
    if (loc === '/paymentiframe') {
      this.showHeaderAndFooter = false;
    }
  }

  // search for search header
  toggleSearch($event) {
    this.searchInput = $event;
  }

  clearSearchResults() {
    this.searchInput = undefined;
  }

  ngAfterContentChecked(): void {
    if (this.headerElement) {
      this.headerHeight = this.headerElement.elHeight;
    }
  }
}
