<div class="content-section">
  <div class="content-container">
    <ng-content></ng-content>
  </div>
</div>

<en-button
  class="desktop"
  [buttonType]="'main'"
  [buttonContent]="'Meira um okkur'"
  routerLink="um-okkur"
></en-button>

<div class="image-section">
  <ng-container *ngFor="let tab of cont">
    <img
      *ngIf="activeTab === tab?.section_slug && !tab.google_maps_"
      [src]="tab?.section_image.url"
      alt="About us Mynd"
    />

    <img
      *ngIf="activeTab === tab?.section_slug && tab.google_maps_"
      [src]="tab?.section_image.url"
      alt="Staðsetning mynd"
    />
  </ng-container>
</div>

<en-button
  class="mobile"
  [buttonType]="'main'"
  [buttonContent]="'Meira um okkur'"
  routerLink="um-okkur"
></en-button>
