import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'en-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.less'],
})
export class AboutUsComponent implements OnInit {
  @Input() activeTab = 'um-ehí'; // Default initial value
  @Input() cont;

  constructor() {}

  ngOnInit() {}
}
