<en-alert-banner></en-alert-banner>
<div class="navigation trs-base" [ngClass]="{ loaded: (loaded | async) }">
  <!-- <div class="top-nav wrapper" *ngIf="prismicContent"></div> -->

  <div class="main-nav wrapper" *ngIf="prismicContent">
    <a routerLink="/">
      <img
        class="logo"
        src="../../../assets/Media/logos/Endurmenntun-hi.png"
        alt="Endurmenntun Lógó"
      />
      <img
        class="mobile-logo"
        src="../../../assets/Media/logos/Endurmenntun-hi.png"
        alt="Endurmenntun Mobile Logo"
      />
    </a>
    <div class="nav-mobile">
      <a href="#">Námskeið</a>
      <a href="#">Námsbrautir</a>
    </div>

    <div class="nav-section">
      <nav>
        <ul class="noStyle">
          <li *ngFor="let nav of prismicContent?.navigation">
            <ng-container
              id="redbg"
              *ngIf="nav.navigation_item.link_type === 'Document'"
            >
              <a
                [routerLink]="nav.navigation_item.uid"
                [routerLinkActive]="'active'"
                [routerLinkActiveOptions]="{ exact: true }"
              >
                {{ nav.navigation_item_label }}
              </a>
            </ng-container>
            <ng-container *ngIf="nav.navigation_item.link_type === 'Web'">
              <a [href]="nav.navigation_item.url" target="_blank">
                {{ nav.navigation_item_label }}
              </a>
            </ng-container>
          </li>
        </ul>
      </nav>

      <div class="search">
        <span class="search-icon" (click)="activateSearch()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="27.414"
            height="27.414"
            viewBox="0 0 27.414 27.414"
          >
            <g
              id="search_1_"
              data-name="search (1)"
              transform="translate(-2.198 -2.197)"
            >
              <circle
                id="Ellipse_4"
                data-name="Ellipse 4"
                cx="11"
                cy="11"
                r="11"
                transform="translate(3.197 3.197)"
                stroke-width="2"
                stroke="#5a5a5a"
                stroke-linecap="round"
                stroke-linejoin="round"
                fill="none"
              />
              <line
                id="Line_6"
                data-name="Line 6"
                x1="6.089"
                y1="6.089"
                transform="translate(22.108 22.108)"
                fill="none"
                stroke="#5a5a5a"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
              />
            </g>
          </svg>
        </span>

        <en-search
          [header]="true"
          (searchQuery)="search($event)"
          (closeSearch)="activateSearch($event)"
          *ngIf="searchActive"
        >
        </en-search>
      </div>
      <div
        class="faux-button"
        (click)="toggleNavigation()"
        [ngClass]="{ active: navigationActive === true }"
      >
        <span class="open">Valmynd</span>
        <span class="burger">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="35"
            height="25"
            viewBox="0 0 35 25"
          >
            <g
              id="Group_22"
              data-name="Group 22"
              transform="translate(-1711 -96)"
            >
              <rect
                id="Rectangle_1"
                data-name="Rectangle 1"
                width="35"
                height="3"
                rx="1.5"
                transform="translate(1711 96)"
              />
              <rect
                id="Rectangle_3"
                data-name="Rectangle 3"
                width="35"
                height="3"
                rx="1.5"
                transform="translate(1711 118)"
              />
              <rect
                id="Rectangle_4"
                data-name="Rectangle 4"
                width="25"
                height="3"
                rx="1.5"
                transform="translate(1721 107)"
              />
            </g>
          </svg>
        </span>
      </div>
    </div>
  </div>

  <ng-container *ngIf="navigationActive === true">
    <en-navigation
      [prismicNavigation]="prismicNavigation"
      [eduframeCategoriesTree]="eduframeCategoriesTree"
      (closeNav)="toggleNavigation()"
    ></en-navigation>
  </ng-container>
</div>
