<section class="course-grid">
  <div class="section-header wrapper">
    <en-overhead-title>
      {{ prismicContent?.overhead_title }}
    </en-overhead-title>

    <div class="filter-container">
      <en-heading
        class="main_item"
        [headingContent]="prismicContent?.main_title[0]"
      ></en-heading>
      <div class="filter_items">
        <ng-container *ngFor="let tab of prismicContent?.body">
          <en-heading
            class="filter_item"
            [headingContent]="tab.primary.tab_name[0]"
            [ngClass]="{
              active: coursesInEnglishActiveTab === tab.primary.tab_name[0].text
            }"
            (click)="toggleAbout(tab.primary.tab_name[0].text)"
          ></en-heading>
        </ng-container>
      </div>
    </div>
  </div>

  <ng-container *ngFor="let contents of prismicContent?.body">
    <div
      class="wrapper courses"
      *ngIf="
        coursesInEnglishActiveTab === 'Courses' &&
        contents.primary.type_of_grid === 'courses'
      "
    >
      <div class="main-grid">
        <ng-container *ngFor="let course of courses">
          <a
            [routerLink]="
              '/namskeid/' +
              course.fieldData.Namskeid_Id +
              '/' +
              slugifyString(course.fieldData.Heiti)
            "
          >
            <en-course-card [cont]="course"></en-course-card>
          </a>
        </ng-container>
      </div>
    </div>

    <div
      class="staff"
      *ngIf="
        coursesInEnglishActiveTab === 'Staff' &&
        contents.primary.type_of_grid === 'staff'
      "
    >
      <en-staff></en-staff>
    </div>

    <div
      class="wrapper text"
      *ngIf="
        coursesInEnglishActiveTab === contents.primary.tab_name[0].text &&
        contents.slice_type === 'tab'
      "
    >
      <div class="content">
        <div class="content__text">
          <en-prismic-text
            [prismicText]="{
              content: contents?.primary.content_for_text_column
            }"
          ></en-prismic-text>
        </div>

        <div class="content__media">
          <img
            [src]="contents.primary.image.url"
            [alt]="contents.primary.image.alt"
          />
        </div>
      </div>
    </div>
  </ng-container>
</section>
