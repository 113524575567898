<form (ngSubmit)="onSubmit(skraningarForm)" #skraningarForm="ngForm">
  <div class="form-inputs">
    <div class="form-input-group">
      <label for="nafn">Nafn <span class="must-fill">*</span></label>
      <input
        type="text"
        id="nafn"
        required
        [(ngModel)]="model.Nafn"
        name="nafn"
        #name="ngModel"
        autocomplete="name"
      />
      <div class="alert" [hidden]="name.valid || name.pristine">
        Nafn þarf að vera fyllt út
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (kennitala.invalid &&
            kennitala.errors.required &&
            kennitala.touched) ||
          (kennitala.invalid && kennitalaBlur && kennitala.errors.minlength)
      }"
    >
      <label for="nafn">Kennitala <span class="must-fill">*</span></label>
      <input
        type="text"
        id="kennitala"
        required
        enValidKennitala
        [(ngModel)]="model.Kennitala"
        name="kennitala"
        #kennitala="ngModel"
        minlength="10"
        maxlength="10"
        (paste)="onPaste($event)"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="kennitalaBlur = true"
        autocomplete="off"
      />

      <ng-container
        *ngIf="kennitala.invalid && (kennitala.dirty || kennitala.touched)"
      >
        <div class="alert" *ngIf="kennitala.control.errors.required">
          Kennitala þarf að vera fyllt út
        </div>
        <div
          class="alert"
          *ngIf="kennitala.control.errors.minlength && kennitalaBlur === true"
        >
          Kennitala þarf að vera 10 stafir
        </div>
        <div
          class="alert"
          *ngIf="
            kennitala.control.errors.invalidKennitala && kennitalaBlur === true
          "
        >
          {{
            lang === 'is' || !lang
              ? 'Þessi kennitala er ekki gild.'
              : 'This personal ID is not valid.'
          }}
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="nafn">Heimilisfang <span class="must-fill">*</span></label>
      <input
        type="text"
        id="heimilisfang"
        required
        [(ngModel)]="model.Heimilisfang"
        name="heimilisfang"
        #heimilisfang="ngModel"
        autocomplete="street-address"
      />
      <div class="alert" [hidden]="heimilisfang.valid || heimilisfang.pristine">
        Heimilisfang þarf að vera fyllt út
      </div>
    </div>

    <div class="form-input-group">
      <label for="nafn">Póstnúmer <span class="must-fill">*</span></label>
      <select
        name="postnumer"
        id="postnumer"
        required
        [(ngModel)]="model.Postnumer"
        #postnumer="ngModel"
        autocomplete="postal-code"
      >
        <option disabled selected value>-- Veldu póstnúmer --</option>
        <ng-container *ngFor="let postalCode of postalCodes">
          <option [value]="getOnlyPostal(postalCode)">{{ postalCode }}</option>
        </ng-container>
      </select>
      <div class="alert" [hidden]="postnumer.valid || postnumer.pristine">
        Póstnúmer þarf að vera fyllt út
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (farsimi.invalid && farsimi.errors.required && farsimi.touched) ||
          (farsimi.invalid && farsimiBlur && farsimi.errors.minlength)
      }"
    >
      <label for="nafn">Farsími <span class="must-fill">*</span></label>
      <input
        type="text"
        id="farsimi"
        required
        [(ngModel)]="model.Farsimi"
        name="farsimi"
        #farsimi="ngModel"
        minlength="7"
        maxlength="7"
        autocomplete="tel"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="farsimiBlur = true"
        (paste)="onPhoneNumberFromClipboard($event)"
      />

      <ng-container
        *ngIf="farsimi.invalid && (farsimi.dirty || farsimi.touched)"
      >
        <div class="alert" *ngIf="farsimi.control.errors.required">
          Farsími þarf að vera fyllt út
        </div>
        <div
          class="alert"
          *ngIf="farsimi.control.errors.minlength && farsimiBlur === true"
        >
          Farsími þarf að vera 7 stafir
        </div>
      </ng-container>
    </div>
  </div>
  <div class="form-inputs">
    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (netfang.invalid && netfang.errors.required && netfang.touched) ||
          (netfang.invalid && netfangBlur && netfang.errors.minlength)
      }"
    >
      <label for="nafn">Netfang <span class="must-fill">*</span></label>
      <input
        type="email"
        id="netfang"
        required
        [(ngModel)]="model.Netfang"
        name="netfang"
        #netfang="ngModel"
        autocomplete="email"
        (ngModelChange)="model.Netfang = $event.toLowerCase()"
        pattern="^(.+@.+\..+)$"
        (blur)="netfangBlur = true"
      />

      <ng-container
        *ngIf="netfang.invalid && (netfang.dirty || netfang.touched)"
      >
        <div class="alert" *ngIf="netfang.errors.required">
          Netfang þarf að vera fyllt út
        </div>
        <div
          class="alert"
          *ngIf="netfang.errors.pattern && netfangBlur === true"
        >
          Netfang þarf að vera gilt
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="netfangStadfest">
        Endurtaka netfang <span class="must-fill">*</span>
      </label>
      <input
        type="email"
        id="netfangStadfest"
        required
        name="netfangStadfest"
        #netfangStadfest="ngModel"
        [(ngModel)]="netfangEndurtekid"
        (ngModelChange)="netfangEndurtekid = $event.toLowerCase()"
        pattern="{{ netfang.value }}"
      />
      <ng-container
        *ngIf="
          netfangStadfest.invalid &&
          (netfangStadfest.dirty || netfangStadfest.touched)
        "
      >
        <div class="alert" *ngIf="netfangStadfest.errors.required">
          Vinsamlegast endurtaktu netfangið
        </div>
        <div class="alert" *ngIf="netfangStadfest.errors.pattern">
          Netföng passa ekki
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-input-group fullwidth checkbox">
    <div class="checkbox-wrapper">
      <div class="checkmark-container">
        <input
          type="checkbox"
          id="postlisti"
          [value]="'true'"
          [(ngModel)]="model.Postlisti"
          name="postlisti"
          #postlisti="ngModel"
        />
        <div class="checkmark"></div>
      </div>
      <label for="nafn"
        >Já, takk. Ég vil fá senda tölvupósta um námskeið og námsbrautir
        Endurmenntunar - skrá mig á póstlista.</label
      >
    </div>
  </div>

  <!-- Námsferill -->
  <h2>Námsferill</h2>
  <div class="form-inputs education add-bottom-spacing">
    <div class="form-input-group no-btm-padding fullwidth">
      <label for="skoli1">Skóli</label>
      <input
        type="text"
        id="skoli1"
        [(ngModel)]="model.Skoli_1"
        name="skoli1"
        #skoli1="ngModel"
      />
    </div>

    <div class="form-input-group no-btm-padding">
      <label for="utskr1">Útskriftarár</label>
      <input
        type="text"
        id="utskr1"
        [(ngModel)]="model.Utskr_Ar_1"
        name="utskr1"
        #utskr1="ngModel"
      />
    </div>

    <div class="form-input-group">
      <label for="grada1">Prófgráða</label>
      <input
        type="text"
        id="grada1"
        [(ngModel)]="model.Grada_1"
        name="grada1"
        #grada1="ngModel"
      />
    </div>

    <div class="form-input-group no-btm-padding fullwidth">
      <label for="skoli2">Skóli</label>
      <input
        type="text"
        id="skoli2"
        [(ngModel)]="model.Skoli_2"
        name="skoli2"
        #skoli2="ngModel"
      />
    </div>

    <div class="form-input-group no-btm-padding">
      <label for="utskr2">Útskriftarár</label>
      <input
        type="text"
        id="utskr2"
        [(ngModel)]="model.Utskr_Ar_2"
        name="utskr2"
        #utskr2="ngModel"
      />
    </div>

    <div class="form-input-group no-btm-padding">
      <label for="grada2">Prófgráða</label>
      <input
        type="text"
        id="grada2"
        [(ngModel)]="model.Grada_2"
        name="grada2"
        #grada2="ngModel"
      />
    </div>
  </div>

  <div class="form-input-group fullwidth text">
    <label for="nafn">Athugasemdir</label>
    <textarea
      type="text"
      id="athugasemdir"
      rows="6"
      [(ngModel)]="model.Athugasemd"
      name="athugasemdir"
      #athugasemdir="ngModel"
      maxlength="300"
    ></textarea>
    <div class="max-letters">
      <span>
        <ng-container
          *ngIf="athugasemdir?.value && athugasemdir?.value.length !== 0"
        >
          {{ athugasemdir.value.length }}
        </ng-container>
      </span>
      <span>Hámark 300 stafabil</span>
    </div>
  </div>

  <div class="files">
    <div class="files__header">
      <h2>Fylgiskjöl</h2>
    </div>

    <div class="files-inputs">
      <div class="form-inputs">
        <div class="form-input-group">
          <label for="profskirteini"> Prófskírteini eitt </label>

          <button (click)="uploadProfskirteini()" type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-upload"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            <span *ngIf="!profskirteiniFileUploaded">Upload</span>
            <ng-container *ngFor="let file of files">
              <ng-container *ngIf="file.type && file.type === 'profskirteini'">
                {{ file.data.name }}
              </ng-container>
            </ng-container>
          </button>

          <ng-container
            *ngIf="!profskirteiniFileValid || !profskirteiniFileTypeValid"
          >
            <div class="alert" *ngIf="!profskirteiniFileValid">
              Skjal má ekki vera stærra en 30mb
            </div>
            <div class="alert" *ngIf="!profskirteiniFileTypeValid">
              Þessi gerð af skrá er ekki leyfileg. Dæmi um skrár sem eru
              leyfilegar: ".pdf, .jpg, .doc, .docx, .png, .jpg, .jpeg, .rtf,
              .pptx, .txt
            </div>
          </ng-container>

          <input
            type="file"
            #profskirteini
            id="profskirteini"
            name="profskirteini"
            accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .rtf, .pptx, .txt"
          />
        </div>

        <div class="form-input-group">
          <label for="profskirteiniTvo"> Prófskírteini tvö </label>

          <button (click)="uploadProfskirteiniTvo()" type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-upload"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            <span *ngIf="!profskirteiniTvoFileUploaded">Upload</span>
            <ng-container *ngFor="let file of files">
              <ng-container
                *ngIf="file.type && file.type === 'profskirteiniTvo'"
              >
                {{ file.data.name }}
              </ng-container>
            </ng-container>
          </button>

          <ng-container
            *ngIf="!profskirteiniTvoFileValid || !profskirteiniTvoFileTypeValid"
          >
            <div class="alert" *ngIf="!profskirteiniTvoFileValid">
              Skjal má ekki vera stærra en 30mb
            </div>
            <div class="alert" *ngIf="!profskirteiniTvoFileTypeValid">
              Þessi gerð af skrá er ekki leyfileg. Dæmi um skrár sem eru
              leyfilegar: ".pdf, .jpg, .doc, .docx, .png, .jpg, .jpeg, .rtf,
              .pptx, .txt
            </div>
          </ng-container>

          <input
            type="file"
            #profskirteiniTvo
            id="profskirteiniTvo"
            name="profskirteiniTvo"
            accept=".pdf, .doc, .docx, .png, .jpg, .jpeg, .rtf, .pptx, .txt"
          />
        </div>

        <div class="form-input-group">
          <label for="cvUpload"> Ferilskrá </label>

          <button (click)="uploadCV()" type="button">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="feather feather-upload"
            >
              <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
              <polyline points="17 8 12 3 7 8"></polyline>
              <line x1="12" y1="3" x2="12" y2="15"></line>
            </svg>
            <span *ngIf="!cvFileUploaded">Upload</span>
            <ng-container *ngFor="let file of files">
              <ng-container *ngIf="file.type && file.type === 'cv'">
                {{ file.data.name }}
              </ng-container>
            </ng-container>
          </button>

          <ng-container *ngIf="!CvFileValid || !CvFileTypeValid">
            <div class="alert" *ngIf="!CvFileValid">
              Skjal má ekki vera stærra en 30mb
            </div>
            <div class="alert" *ngIf="!CvFileTypeValid">
              Þessi gerð af skrá er ekki leyfileg. Dæmi um skrár sem eru
              leyfilegar: ".pdf, .jpg, .doc, .docx, .png, .jpg, .jpeg, .rtf,
              .pptx, .txt
            </div>
          </ng-container>

          <input
            type="file"
            #cvUpload
            id="cvUpload"
            name="cvUpload"
            accept=".pdf, .doc, .docx, .xml, .png, .jpg, .jpeg, .rtf, .pptx, .txt"
          />
        </div>
      </div>
      <div class="form-inputs add-bottom-spacing">
        <div class="form-input-group fullwidth">
          <label for="otherUpload"> Önnur skjöl </label>

          <button class="other-files-button" type="button">
            <div class="content" (click)="uploadOtherfile()">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                class="feather feather-upload"
              >
                <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                <polyline points="17 8 12 3 7 8"></polyline>
                <line x1="12" y1="3" x2="12" y2="15"></line>
              </svg>
              <span>Upload</span>
            </div>
            <div class="otherFileList" [ngClass]="{ active: files.length }">
              <ul>
                <ng-container *ngFor="let file of files">
                  <li *ngIf="file.type === 'other'">
                    <div class="information">
                      {{ file.data.name }} - {{ file.data.size | filesize }}
                    </div>

                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="49.663"
                      height="50"
                      viewBox="0 0 49.663 50"
                      (click)="checkIfExistsByName(file.data.name)"
                      title="Eyða"
                    >
                      <g id="Close" transform="translate(-1665.491 -36.494)">
                        <g id="chevron" transform="translate(1538.978 36.495)">
                          <g
                            id="Group_65"
                            data-name="Group 65"
                            transform="translate(126.513)"
                          >
                            <path
                              id="Path_52"
                              data-name="Path 52"
                              d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                              transform="translate(-126.513)"
                              fill="#433F34"
                            />
                          </g>
                        </g>
                        <g
                          id="chevron-2"
                          data-name="chevron"
                          transform="translate(1840.959 86.117) rotate(180)"
                        >
                          <g
                            id="Group_65-2"
                            data-name="Group 65"
                            transform="translate(126.513)"
                          >
                            <path
                              id="Path_52-2"
                              data-name="Path 52"
                              d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                              transform="translate(-127.22 -0.707)"
                              fill="#433F34"
                            />
                            <path
                              id="Path_54"
                              data-name="Path 54"
                              d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                              transform="translate(-126.513)"
                              fill="#433F34"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </li>
                </ng-container>
              </ul>
            </div>
          </button>

          <ng-container *ngIf="!OtherFileValid || !OtherFileTypeValid">
            <div class="alert" *ngIf="!OtherFileValid">
              Skjal má ekki vera stærra en 30mb
            </div>
            <div class="alert" *ngIf="!OtherFileTypeValid">
              Þessi gerð af skrá er ekki leyfileg. Dæmi um skrár sem eru
              leyfilegar: ".pdf, .jpg, .doc, .docx, .png, .jpg, .jpeg, .rtf,
              .pptx, .txt
            </div>
          </ng-container>

          <input
            type="file"
            #fileUpload
            id="fileUpload"
            name="fileUpload"
            multiple="multiple"
            (change)="getProgress($event.target.files)"
            accept=".pdf, .doc, .docx, .xml, .png, .jpg, .jpeg, .rtf, .pptx, .txt"
          />
        </div>
      </div>
      <div class="loadedOtherfile" *ngIf="otherFileMessageActive">
        <h5>{{ otherFileMessage }}</h5>
      </div>
    </div>
  </div>

  <ng-container *ngIf="disclaimer !== ''">
    <div class="form-info terms-and-conditions">
      <h2>Skilmálar og fyrirvari</h2>
      <div class="form-info__content">
        <p [innerHTML]="disclaimer"></p>
      </div>

      <div class="form-input-group fullwidth checkbox">
        <div class="checkbox-wrapper">
          <div class="checkmark-container">
            <input
              type="checkbox"
              id="skilmalar"
              required
              [value]="'true'"
              name="skilmalar"
              [(ngModel)]="model['Samþykkja_skilmala']"
              #skilmalar="ngModel"
            />
            <div class="checkmark"></div>
          </div>
          <label for="skilmalar">Ég samþykki ofangreinda skilmála</label>
        </div>
        <div
          class="alert"
          [hidden]="
            skilmalar.valid || skilmalar.pristine || skilmalar.value === 'true'
          "
        >
          Vinsamlega samþykktu skilmála
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-info teaching-material">
    <div class="form-info__content">
      <p>
        Tölvupóstur verður sendur sem staðfesting á móttöku umsóknar. Ef þér
        berst engin staðfesting, vinsamlega hafðu samband við ENDURMENNTUN í
        síma 525 4444 eða sendu tölvupóst á
        <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
      </p>
    </div>
  </div>

  <en-button
    [buttonContent]="'Staðfesta'"
    [buttonType]="'regular'"
    [buttonColor]="'red'"
    type="submit"
  ></en-button>
  <ng-container *ngIf="reqProcessing"> Vinn úr skráningu... </ng-container>

  <div class="alert" [hidden]="formValid || reqProcessing">
    <ng-container *ngIf="reqProcessing === false">
      Villa kom upp í skráningu, vinsamlegast fylgdu villumeldingum hér fyrir
      ofan
    </ng-container>
  </div>
  <div class="alert" [hidden]="!registerStudentError">
    <ng-container *ngIf="registerStudentError && registerStudentError.status">
      Villa kom upp í skráningarferlinu. Vinsamlegast hafðu samband við
      ENDURMENNTUN í síma <a href="tel:5254444">525 4444</a> eða sendu tölvupóst
      á <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
    </ng-container>
  </div>
</form>
