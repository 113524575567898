import { FmResponse } from './../../interfaces/filemaker-query.interface';
import { Component, OnInit } from '@angular/core';
import { Location, formatDate } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
// Services
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import {
  Namskeid,
  NamskeidRes,
} from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';
import {
  Namsgogn,
  NamsgognRes,
} from '@endurmenntun/_components/pages/education/interfaces/namsgogn.interface';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';

@Component({
  selector: 'en-confirmation-documents',
  templateUrl: './confirmation-documents.component.html',
  styleUrls: ['./confirmation-documents.component.less'],
})
export class ConfirmationDocumentsComponent implements OnInit {
  course: Namskeid;
  gallery: any;
  gogn: Array<{ fieldData: Namsgogn }>;
  public fmLoaded: Observable<boolean>;
  // determines class documents or conformation page after registration
  type: string;
  public date;
  private query = {
    query: [
      {
        Namskeid_Id: this.location.path().split('?')[0].split('/').pop(),
      },
    ],
  };

  constructor(
    private educationService: FakeMakerService,
    private location: Location,
    public general: GeneralService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.type = this.route.parent.snapshot.routeConfig.path;
    this.educationService.bearerToken.subscribe(value => {
      if (value) {
        if (this.type === 'rafraen-gogn') {
          this.getNamsgogn();
        }
        this.getCourse();
      }
    });
  }

  private getCourse() {
    this.educationService
      .getSingleCourseOrClass(this.query)
      .then(
        (data: FmResponse) => {
          this.course = data.response.data[0].fieldData;
          this.course.Stadsetning = this.course.Stadsetning.replace(
            /\u00a0/g,
            ' '
          );
          this.gallery = data.response.data[0].portalData;
          this.formatCourseDate();
        },
        err => {
          // TODO: Handle error
          console.error(err);
        }
      )
      .then(() => {
        this.fmLoaded = this.general.showContent();
      });
  }

  private getNamsgogn() {
    this.educationService.getStudyMaterials(this.query).then(
      (data: NamsgognRes) => {
        this.gogn = data.response.data;
      },
      err => {
        console.error(err);
      }
    );
  }

  formatCourseDate() {
    const thedate = new Date(this.course.Byrjar_Dags);
    const day = thedate.getDate();
    const month = thedate.getMonth() + 1; // Months are zero-based
    const year = thedate.getFullYear();
    this.date = `${day.toString().padStart(2, '0')}/${month
      .toString()
      .padStart(2, '0')}/${year}`;
  }
}
