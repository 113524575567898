import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
// Services
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-alert-page',
  templateUrl: './alert-page.component.html',
  styleUrls: ['./alert-page.component.less'],
})
export class AlertPageComponent implements OnInit {
  private query: string;
  prismicContent: any;

  constructor(
    private location: Location,
    private prismic: PrismicService,
    private meta: MetaDataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.query = this.location.path().split('/').pop().split('?')[0];
    this.getPrismicContent();
  }

  getPrismicContent() {
    this.prismic
      .getAlertByUid(this.query)
      .then((data: PrismicBaseInterface) => {
        this.prismicContent = data.results[0].data;
        this.meta.setMetaTags(data.results[0].data);
      });
  }
}
