import { Router, NavigationEnd } from '@angular/router';
import {
  AfterViewChecked,
  Component,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Location, isPlatformBrowser } from '@angular/common';
import { Observable } from 'rxjs';
import slugify from 'slugify';
// Services
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-custom-content-page',
  templateUrl: './custom-content-page.component.html',
  styleUrls: ['./custom-content-page.component.less'],
})
export class CustomContentPageComponent implements AfterViewChecked {
  private query: string;
  public prismicContent: any;
  public loaded: Observable<boolean>;

  public hash: string = '';
  public scroll: boolean = false;

  constructor(
    private location: Location,
    private prismic: PrismicService,
    private meta: MetaDataService,
    private router: Router,
    private general: GeneralService,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    router.events.subscribe(val => {
      this.query = this.location
        .path()
        .split('/')
        .pop()
        .split('?')[0]
        .replace(/\s/g, '');

      if (isPlatformBrowser(platformId)) {
        this.hash = window.location.hash.replace('#', '');
      }

      if (val instanceof NavigationEnd) {
        const isDefinedRoute: Boolean = router.config.some(route => {
          return route.path === this.query;
        });
        if (!isDefinedRoute) {
          this.fetchContentOnRouteChange();
        }
      }
    });
  }

  ngAfterViewChecked() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.hash !== '' && this.scroll === false) {
        var scrollItem = document.getElementById(this.hash);

        if (scrollItem !== null && this.scroll === false) {
          var elmOffset = scrollItem.getBoundingClientRect();
          this.scroll = true;
          window.scrollTo({
            top: elmOffset.top,
            behavior: 'smooth',
          });
        }
      }
    }
  }

  private fetchContentOnRouteChange(): void {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.getCustomContentPage();
      }
    });
  }

  makeAnchorLink(prismicData: string): void {
    // changing the string into a href slug
    const slugged: string = slugify(prismicData);

    navigator.clipboard.writeText(
      'endurmenntun.is' + this.location.path() + '/#' + slugged
    );

    // tooltip
    let tooltip = document.getElementById('copied');
    tooltip.innerHTML = 'Hlekkur afritaður';
    // add class
    tooltip.classList.add('copied');
    setTimeout(() => {
      tooltip.innerHTML = '';
      tooltip.classList.remove('copied');
    }, 1000);
  }

  makeId(prismicData: string): string {
    // changing the string into a href slug
    const slugged: string = slugify(prismicData);

    return slugged;
  }

  toggleAccordian(event, index) {
    var element = event.currentTarget;
    element.classList.toggle('active');
    var panel = element.nextElementSibling;
    panel.classList.toggle('active');
  }

  private getCustomContentPage(): void {
    this.prismicContent = null;
    this.prismic
      .getCustomPageByUID(this.query)
      .then((data: PrismicBaseInterface) => {
        if (data.results.length) {
          this.prismicContent = data.results[0].data;
          this.prismicContent.body = data.results[0].data['body'];
          this.meta.setMetaTags(data.results[0].data);
        } else {
          this.router.navigate(['not-found']);
        }
      })
      .then(() => {
        this.loaded = this.general.showContent();
      })
      .catch(err => {
        this.router.navigate(['not-found']);
        throw err;
      });
  }
}
