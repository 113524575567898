<ng-container *ngIf="!courseNotFound; else notFound">
  <en-hero-banner
    [heroBannerContent]="course"
    [heroBannerGallery]="gallery"
    [contentType]="'filemaker'"
    [herobannerType]="'namskeid'"
    [signupUrl]="getSignupUrl()"
    [loaded]="fmLoaded"
  ></en-hero-banner>
  <div class="namskeid_component" [ngClass]="{ loaded: (fmLoaded | async) }">
    <section
      class="alert trs-base wrapper"
      [ngClass]="{ loaded: (prismicLoaded | async) }"
      *ngIf="
        prismicAlert?.namsbraut_alert_active ||
        prismicAlert?.namskeid_alert_active
      "
    >
      <ng-container *ngIf="prismicAlert?.namsbraut_alert_active">
        <div class="alert__container">
          <en-prismic-text
            [prismicText]="{ content: prismicAlert?.namsbraut_alert }"
          ></en-prismic-text>
        </div>
      </ng-container>

      <ng-container *ngIf="prismicAlert?.namskeid_alert_active">
        <div class="alert__container">
          <en-prismic-text
            [prismicText]="{ content: prismicAlert?.namskeid_alert }"
          ></en-prismic-text>
        </div>
      </ng-container>
    </section>

    <section
      class="more-info trs-base wrapper"
      [ngClass]="{ loaded: (fmLoaded | async) }"
    >
      <div class="more-info__details">
        <div class="details">
          <span class="timi">
            <img src="/assets/Media/calendar.png" alt="" />
            <p [innerHTML]="course?.Timi"></p>
          </span>

          <span class="lengd" *ngIf="course?.Lengd">
            <img src="/assets/Media/clock.png" alt="" />
            {{
              course.Lengd +
                (course.ID_Yfirflokkur.includes('Y0005') ? ' hours' : ' klst.')
            }}
          </span>

          <ng-container *ngFor="let teacher of eduframeTeacherEnrollment">
            <span class="teacher">
              <img src="/assets/Media/user.png" alt="" />
              <p>{{ teacher.first_name }} {{ teacher.last_name }}</p>
            </span>
          </ng-container>

          <span class="location">
            <img src="/assets/Media/map-pin.png" alt="" />
            <p [innerHTML]="course?.Stadsetning"></p>
          </span>
          <div>
            <div class="price-container">
              <img src="/assets/svg/coin.svg" alt="Peningur" />
              <span
                class="price"
                *ngIf="
                  general.ifSeatsAndPriceNotEmpty(
                    course?.Fjoldi_laus_saeti,
                    course?.Verd
                  ) &&
                  this.course?.Tegund_eydublads !== '2' &&
                  course?.Verd !== 0
                "
              >
                {{ course?.Verd | number | removeCommaPipe }} kr.
              </span>
              <span class="price" *ngIf="this.course?.Verd === 0">
                Gjaldfrjálst
              </span>
              <span class="price" *ngIf="this.course?.Tegund_eydublads === '2'">
                {{
                  course.ID_Yfirflokkur.includes('Y0005')
                    ? 'Free'
                    : 'Gjaldfrjálst'
                }}
              </span>
            </div>
            <ng-container>
              <span
                class="snemmskraning"
                [ngClass]="{
                  blue: course?.ID_Yfirflokkur === 'Y0004'
                }"
              >
                {{
                  course?.Text_Snemmskraning ? course?.Text_Snemmskraning : ''
                }}
              </span>
            </ng-container>
          </div>
          <!-- TODO: -->
        </div>
      </div>

      <div
        class="more-info__content trs-base main"
        [ngClass]="{
          secondary: course?.ID_Yfirflokkur === 'Y0004',
          loaded: (fmLoaded | async)
        }"
      >
        <en-overhead-title
          [color]="course?.ID_Yfirflokkur === 'Y0004' ? 'Y0004' : 'Y0003'"
        >
          {{
            course?.ID_Yfirflokkur === 'Y0004'
              ? 'Námsbraut'
              : course?.ID_Yfirflokkur.includes('Y0005')
              ? 'Course'
              : (course?.Fjarkennsla_ === 'vidburdur' &&
                  course?.Fjarkennsla === 'Viðburður') ||
                (course?.Fjarkennsla_ === 'prof' &&
                  course?.Fjarkennsla === 'Próf')
              ? 'Upplýsingar'
              : 'Námskeið'
          }}
        </en-overhead-title>

        <ng-container
          *ngFor="
            let courseTabContent of eduframeCourseTabContents;
            let i = index
          "
        >
          <div
            class="main__paragraph"
            *ngIf="courseTabContent.content !== undefined"
          >
            <h2 *ngIf="i !== 0">{{ courseTabContent.tab.name }}</h2>
            <p [innerHTML]="courseTabContent.content.content"></p>
          </div>
        </ng-container>

        <ng-container *ngIf="course?.Skilmalar">
          <div class="main__paragraph">
            <h2>
              {{ course?.ID_Yfirflokkur.includes('Y0005') ? '' : 'Skilmálar' }}
            </h2>
            <p [innerHTML]="course?.Skilmalar"></p>
          </div>
        </ng-container>

        <div class="main__paragraph" *ngIf="prismicEducationGrant">
          <ng-container
            *ngIf="
              course?.ID_Yfirflokkur.includes('Y0005');
              else fraedslustyrkur
            "
          >
            <en-prismic-text
              [prismicText]="{
                content: prismicEducationGrant?.educational_grant
              }"
            ></en-prismic-text>
          </ng-container>
          <ng-template #fraedslustyrkur>
            <en-prismic-text
              [prismicText]="{
                content: prismicEducationGrant?.fraedslustyrkur
              }"
            ></en-prismic-text>
          </ng-template>
        </div>
      </div>
      <div class="more-info__sidebar sidebar">
        <div
          class="details"
          [ngClass]="{ secondary: course?.ID_Yfirflokkur === 'Y0004' }"
        >
          <span class="timi">
            <img src="/assets/svg/calendar.svg" alt="Tímasetningar" />
            <p [innerHTML]="course?.Timi"></p>
          </span>

          <ng-container *ngIf="course?.ID_Yfirflokkur.includes('Y0004')">
            <span
              class="remote"
              *ngIf="
                course?.Fjarkennsla_ === 'true' && course?.Fjarkennsla === 'já'
              "
            >
              <img src="/assets/svg/video.svg" alt="Fjarnám" />
              <p>
                {{
                  course.ID_Yfirflokkur.includes('Y0005') ? 'Online' : 'Fjarnám'
                }}
              </p>
            </span>
            <span
              class="remote"
              *ngIf="
                course?.Fjarkennsla_ === 'false' &&
                course?.Fjarkennsla === 'nei'
              "
            >
              <img src="/assets/svg/home.svg" alt="Staðnám" />
              <p>
                {{
                  course.ID_Yfirflokkur.includes('Y0005')
                    ? 'In classroom'
                    : 'Staðnám'
                }}
              </p>
            </span>
            <span
              class="remote"
              *ngIf="
                course?.Fjarkennsla_ === 'true' &&
                course?.Fjarkennsla === 'Bæði'
              "
            >
              <img src="/assets/svg/both.svg" alt="Stað- og fjarnám" />
              <p>
                {{
                  course.ID_Yfirflokkur.includes('Y0005')
                    ? 'In classroom & online'
                    : 'Stað- og fjarnám'
                }}
              </p>
            </span>
          </ng-container>

          <span class="lengd" *ngIf="course?.Lengd">
            <img src="/assets/svg/clock.svg" alt="Lengd" />
            <p>
              {{
                course.Lengd +
                  (course.ID_Yfirflokkur.includes('Y0005')
                    ? ' hours'
                    : ' klst.')
              }}
            </p>
          </span>

          <ng-container *ngFor="let teacher of eduframeTeacherEnrollment">
            <span class="teacher">
              <img src="/assets/svg/user.svg" alt="Kennari" />
              <p>
                {{ teacher.first_name }} {{ teacher.last_name }}<br />
                <span
                  *ngIf="teacher.teacher_headline"
                  class="teacher-headline"
                  >{{ teacher.teacher_headline }}</span
                >
              </p>
            </span>
          </ng-container>

          <span class="location" *ngIf="course?.Stadsetning">
            <img src="/assets/svg/map-pin.svg" alt="Staðsetning" />
            <p [innerHTML]="course?.Stadsetning"></p>
          </span>

          <div>
            <div class="price-container">
              <img src="/assets/svg/coin.svg" alt="Peningur" />
              <span
                class="price"
                *ngIf="
                  general.ifSeatsAndPriceNotEmpty(
                    course?.Fjoldi_laus_saeti,
                    course?.Verd
                  ) &&
                  this.course?.Tegund_eydublads !== '2' &&
                  course?.Verd !== 0
                "
              >
                {{ course?.Verd | number | removeCommaPipe }} kr.
              </span>
              <span class="price" *ngIf="this.course?.Verd === 0">
                Gjaldfrjálst
              </span>
              <span class="price" *ngIf="this.course?.Tegund_eydublads === '2'">
                {{
                  course.ID_Yfirflokkur.includes('Y0005')
                    ? 'Free'
                    : 'Gjaldfrjálst'
                }}
              </span>
            </div>
            <ng-container>
              <span
                class="snemmskraning"
                [ngClass]="{
                  blue: course?.ID_Yfirflokkur === 'Y0004'
                }"
              >
                {{
                  course?.Text_Snemmskraning ? course?.Text_Snemmskraning : ''
                }}
              </span>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="course?.Fagleg_Umsjon !== ''">
          <h3>
            {{
              course?.ID_Yfirflokkur.includes('Y0005')
                ? 'Project manager'
                : 'Fagleg umsjón'
            }}
          </h3>
          <div class="course-manager">
            <div class="course-manager-image">
              <img [src]="course?.Mynd" alt="" />
            </div>
            <div class="course-manager-info">
              <p>
                <b>{{ course?.Fagleg_Umsjon }}</b
                ><br />{{ course?.Fag_Umsjon_almennt }}
              </p>
              <p
                *ngIf="course?.Fag_Umsjon_netfang !== ''"
                class="contact email"
              >
                <a href="mailto:{{ course?.Fag_Umsjon_netfang }}">{{
                  course?.Fag_Umsjon_netfang
                }}</a>
              </p>
              <p *ngIf="course?.Fag_Umsjon_simi !== ''" class="contact phone">
                <a href="tel:{{ course?.Fag_Umsjon_simi }}">{{
                  course?.Fag_Umsjon_simi
                }}</a>
              </p>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="course?.Umsjon_almennt !== ''">
          <h3>
            {{
              course?.ID_Yfirflokkur.includes('Y0005')
                ? course?.Umsjon2 !== '' && course?.Umsjon2.length > 0
                  ? 'Project managers'
                  : 'Project manager'
                : course?.Umsjon2 !== '' && course?.Umsjon2.length > 0
                ? 'Verkefnastjórar'
                : 'Verkefnastjóri'
            }}
          </h3>
          <div class="course-manager">
            <div class="course-manager-image">
              <img
                *ngIf="course?.Umsjon_Mynd !== ''"
                [src]="course?.Umsjon_Mynd"
                alt=""
              />
            </div>
            <div class="course-manager-info">
              <p>
                <b>{{ course?.Umsjon }}</b
                ><br />{{ course?.Umsjon_almennt }}
              </p>
              <p *ngIf="course?.Umsjon_netfang !== ''" class="contact email">
                <a href="mailto:{{ course?.Umsjon_netfang }}">{{
                  course?.Umsjon_netfang
                }}</a>
              </p>
              <p *ngIf="course?.Umsjon_simi !== ''" class="contact phone">
                <a href="tel:{{ course?.Umsjon_simi }}">{{
                  course?.Umsjon_simi
                }}</a>
              </p>
            </div>
          </div>
        </ng-container>

        <en-youtube-player
          *ngIf="course?.Myndband_Vefslod"
          [ytUrl]="course?.Myndband_Vefslod"
        ></en-youtube-player>

        <en-course-rating
          *ngIf="course?.Stjornur"
          [rating]="course?.Stjornur"
        ></en-course-rating>

        <ng-container *ngIf="umsagnir && umsagnir.length > 1">
          <div class="section-header">
            <en-overhead-title>
              {{
                course?.ID_Yfirflokkur.includes('Y0005')
                  ? 'Our People'
                  : 'Fólkið okkar'
              }}
            </en-overhead-title>

            <div class="filter-container">
              <h1>
                {{
                  course?.ID_Yfirflokkur.includes('Y0005')
                    ? 'Reviews'
                    : 'Umsagnir'
                }}
              </h1>
            </div>
          </div>
          <en-slider
            [type]="'condensedSlider'"
            [arrowSides]="true"
            [singleCard]="true"
            [sliderContent]="umsagnir"
            [contentType]="'fm'"
            [addShadowPadding]="true"
          ></en-slider>
        </ng-container>
      </div>
    </section>
    <en-footer-actions
      [courseContent]="course"
      [calendarDetails]="{
        title: course?.Heiti,
        description: course?.Kynning,
        location: course?.Stadsetning,
        start: course?.Byrjar_Dags_,
        end: course?.Endar_Dags_
      }"
    ></en-footer-actions>
  </div>
</ng-container>

<section class="more-courses" *ngIf="courseType">
  <en-course-grid [specificType]="courseType?.type">
    <div class="section-header wrapper">
      <en-overhead-title>
        {{
          course?.ID_Yfirflokkur.includes('Y0005')
            ? 'Education for all'
            : 'Menntun fyrir alla'
        }}
      </en-overhead-title>

      <div class="filter-container">
        <en-heading
          class="main-item"
          [headingContent]="{
            text: course?.ID_Yfirflokkur.includes('Y0005')
              ? 'Other Courses'
              : 'Önnur námskeið',
            type: 'heading2'
          }"
        ></en-heading>
      </div>
    </div>
  </en-course-grid>
</section>

<ng-template #notFound>
  <en-not-found></en-not-found>
</ng-template>
