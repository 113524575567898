import { Component, Input } from '@angular/core';

@Component({
  selector: 'en-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.less'],
})
export class ButtonComponent {
  @Input() buttonContent?;
  @Input() buttonType: 'regular' | 'main' | 'clear';
  @Input() buttonColor?;
}
