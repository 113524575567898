import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { ActivatedRoute, Scroll } from '@angular/router';
import { Observable, fromEvent } from 'rxjs';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-front-page',
  templateUrl: './front-page.component.html',
  styleUrls: ['./front-page.component.less'],
})
export class FrontPageComponent implements OnInit, AfterViewInit {
  @ViewChild('results') searchResults: ElementRef;
  public prismicContent: any;
  public searchInput: string;
  public umEhiActiveTab: 'um-okkur' | 'location' = 'um-okkur';
  public showSearchPage = false;
  public loaded: Observable<boolean>;

  // Fréttir | Greinar
  public newsType: 'frettir' | 'greinar' = 'frettir';

  constructor(
    private prismic: PrismicService,
    private meta: MetaDataService,
    private route: ActivatedRoute,
    private general: GeneralService,
    public viewportScroller: ViewportScroller,
    @Inject(PLATFORM_ID) public platformId: any
  ) {
    this.route.queryParams.subscribe(params => {
      if (params.search) {
        this.showSearchPage = true;
      } else {
        this.showSearchPage = false;
      }
    });
  }

  ngOnInit() {
    this.getPrismicContent();

    // temp fix for initializeing the um-ehi tab
    this.toggleAbout('um-ehi');
  }

  public getPrismicContent(): any {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.prismic
          .getDocumentByType('frontpage')
          .then((data: PrismicBaseInterface) => {
            this.prismicContent = data.results[0].data;
            this.meta.setMetaTags(data.results[0].data);
          })
          .then(() => {
            this.loaded = this.general.showContent();
          });
      }
    });
  }

  ngAfterViewInit(): void {
    // After View init because "ViewChild" doesn't render before view
    // Wait for view to populate

    // Check if user is landing by url og searching for same query (check active param)
    let activeParam: string = this.route.snapshot.queryParams.search;
    this.route.queryParams.subscribe(param => {
      // If the query remains the same, don't scroll
      if (activeParam !== param.search) {
        activeParam = param.search;
        this.scrollResultsIntoView();
      }
    });
  }

  private scrollResultsIntoView(): void {
    const el = this.searchResults.nativeElement;
    el.scrollIntoView({ behaviour: 'smooth', block: 'start' }); // Not supported in IE
  }

  public toggleSearch($event): void {
    this.searchInput = $event;
  }

  public clearSearchResults(): void {
    this.searchInput = undefined;
  }

  public toggleAbout(tab): void {
    this.umEhiActiveTab = tab;
  }
}
