<div class="form wrapper">
  <div class="form__header">
    <en-overhead-title>
      {{ prismicContent?.overhead_title }}
    </en-overhead-title>
    <en-heading [headingContent]="prismicContent?.title[0]"></en-heading>
  </div>

  <div class="form__content">
    <div class="form__form-container">
      <en-contact
        *ngIf="loc === 'hafa-samband' && prismicContent"
        (pushSuccess)="triggerSuccess($event)"
      ></en-contact>
      <en-mailing-list
        *ngIf="loc === 'postlisti' && prismicContent"
        (pushSuccess)="triggerSuccess($event)"
      ></en-mailing-list>
    </div>

    <div class="image">
      <img
        *ngIf="loc === 'hafa-samband' && prismicContent && !success"
        src="../../../../assets/illustrations/undraw_Mail_sent_re_0ofv.svg"
        alt=""
      />
      <img
        *ngIf="loc === 'postlisti' && prismicContent && !success"
        src="../../../../assets/illustrations/undraw_Mailbox_re_dvds.svg"
        alt="Mailing list illustration"
      />
      <!-- Success image-->
      <img
        *ngIf="
          (loc === 'postlisti' && prismicContent && success) ||
          (loc === 'hafa-samband' && prismicContent && success)
        "
        src="../../../../assets/illustrations/undraw_winners_ao2o.svg"
        alt="Success illustration"
      />
    </div>
  </div>
</div>
