<div class="form-container">
  <ng-container *ngIf="registrationType === '1'">
    <en-form-type-one
      [namskeidId]="namskeidId"
      [lang]="course?.ID_Yfirflokkur.includes('Y0005') ? 'en' : 'is'"
      [disclaimer]="course?.Skilmala_Texti"
      [remote]="course?.Fjarkennsla"
      [digital]="course?.rafraenskjol"
      [registerStudentError]="registrationError"
      [reqProcessing]="proccessingRequest"
      (registerStudent)="newRegistration($event)"
    >
    </en-form-type-one>
  </ng-container>

  <ng-container *ngIf="registrationType === '2'">
    <en-form-type-two
      [namskeidId]="namskeidId"
      [lang]="course?.ID_Yfirflokkur.includes('Y0005') ? 'en' : 'is'"
      [disclaimer]="course?.Skilmala_Texti"
      [remote]="course?.Fjarkennsla"
      [digital]="course?.rafraenskjol"
      [registerStudentError]="registrationError"
      [reqProcessing]="proccessingRequest"
      (registerStudent)="newRegistration($event)"
    >
    </en-form-type-two>
  </ng-container>

  <ng-container *ngIf="registrationType === '3'">
    <en-form-type-three
      [namskeidId]="namskeidId"
      [lang]="course?.ID_Yfirflokkur.includes('Y0005') ? 'en' : 'is'"
      [disclaimer]="course?.Skilmala_Texti"
      [remote]="course?.Fjarkennsla"
      [registerStudentError]="registrationError"
      [reqProcessing]="proccessingRequest"
      (registerStudent)="newRegistration($event)"
    >
    </en-form-type-three>
  </ng-container>

  <ng-container *ngIf="registrationType === '4'">
    <en-form-type-four
      [namskeidId]="namskeidId"
      [lang]="course?.ID_Yfirflokkur.includes('Y0005') ? 'en' : 'is'"
      [disclaimer]="course?.Skilmala_Texti"
      [remote]="course?.Fjarkennsla"
      [registerStudentError]="registrationError"
      [reqProcessing]="proccessingRequest"
      (registerStudent)="newRegistration($event)"
    >
    </en-form-type-four>
  </ng-container>
</div>
