<!-- Course Slider Full Width -->

<ng-container *ngIf="type === 'fullSlider' && isBrowser">
  <swiper [config]="config">
    <div class="swiper-wrapper">
      <ng-container *ngIf="!loaded">
        <ng-container *ngFor="let item of skeletonSliderCounter">
          <a class="swiper-slide">
            <en-course-card-skeleton></en-course-card-skeleton>
          </a>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="loaded">
        <ng-container *ngFor="let slide of sliderContent; let i = index">
          <a
            class="swiper-slide"
            [routerLink]="getEduframeCourseLink(slide.eduframeCourse, slide.eduframePlannedCourse)"
          >
            <en-course-card [cont]="slide" [loaded]="loaded"></en-course-card>
          </a>
        </ng-container>
      </ng-container>
    </div>

    <div class="arrows-container fullSliderArrows">
      <span class="arrow arrow-left swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.29"
          height="50"
          viewBox="0 0 25.29 50"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
            transform="translate(-126.513 0.001)"
            fill="#433f34"
          />
        </svg>
      </span>
      <span class="arrow arrow-right swiper-button-next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.29"
          height="50"
          viewBox="0 0 25.29 50"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
            transform="translate(-126.513 0.001)"
            fill="#433f34"
          />
        </svg>
      </span>
    </div>
  </swiper>
</ng-container>

<!-- Condensed Slider-->

<section
  class="condensed"
  *ngIf="type === 'condensedSlider' && isBrowser"
  [ngClass]="{ single: singleCard, 'shadow-padding': addShadowPadding }"
>
  <swiper
    [config]="singleCard === true ? configSingleCardSlider : configCondensed"
  >
    <div class="swiper-wrapper">
      <ng-container *ngFor="let cont of sliderContent">
        <en-reference-card
          class="swiper-slide"
          [cont]="cont"
          [type]="contentType === 'fm' ? 'fm' : 'prismic'"
          [addShadowPadding]="addShadowPadding === true ? true : false"
        ></en-reference-card>
      </ng-container>
    </div>
  </swiper>

  <div
    class="arrows-container condensedSliderArrows"
    [ngClass]="{ 'side-arrows': arrowSides === true }"
  >
    <span class="arrow arrow-left swiper-button-prev">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.29"
        height="50"
        viewBox="0 0 25.29 50"
      >
        <path
          id="Path_52"
          data-name="Path 52"
          d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
          transform="translate(-126.513 0.001)"
          fill="#433f34"
        />
      </svg>
    </span>
    <span class="arrow arrow-right swiper-button-next">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25.29"
        height="50"
        viewBox="0 0 25.29 50"
      >
        <path
          id="Path_52"
          data-name="Path 52"
          d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
          transform="translate(-126.513 0.001)"
          fill="#433f34"
        />
      </svg>
    </span>
  </div>
</section>

<!-- Image slider -->

<section
  class="image-slider condensed"
  *ngIf="type === 'imgSlider' && isBrowser && sliderContent"
>
  <swiper [config]="imagesliderConfig">
    <div class="swiper-wrapper">
      <ng-container *ngIf="sliderContent.length; else defaultImage">
        <div class="swiper-slide" *ngFor="let slide of sliderContent">
          <img [src]="slide['Myndir_Namskeid']" alt="" />
        </div>
      </ng-container>
      <ng-template #defaultImage>
        <div class="swiper-slide">
          <img src="/assets/Media/Default-image.png" alt="" />
        </div>
      </ng-template>
    </div>

    <div
      class="arrows-container imgSliderArrows"
      [ngClass]="{
        hide: sliderContent && sliderContent.length <= 1,
        'side-arrows': arrowSides === true
      }"
    >
      <span class="arrow arrow-left swiper-button-prev">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.29"
          height="50"
          viewBox="0 0 25.29 50"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
            transform="translate(-126.513 0.001)"
            fill="#fff"
          />
        </svg>
      </span>
      <span class="arrow arrow-right swiper-button-next">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25.29"
          height="50"
          viewBox="0 0 25.29 50"
        >
          <path
            id="Path_52"
            data-name="Path 52"
            d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
            transform="translate(-126.513 0.001)"
            fill="#fff"
          />
        </svg>
      </span>
    </div>
  </swiper>
</section>
