<div
  class="filters_container wrapper"
  [ngClass]="{ 'bg-blue': activeCourseType === 'Y0004' }"
>
  <div class="filters_container__left">
    <div
      class="categories"
      [ngClass]="{
        active: categoriesActive === true,
        blue: activeCourseType === 'Y0004'
      }"
    >
      <span class="text" (click)="toggleCat()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
        >
          <g id="grid" transform="translate(-2 -2)">
            <rect
              id="Rectangle_10"
              data-name="Rectangle 10"
              width="10"
              height="10"
              transform="translate(3 3)"
              stroke-width="2"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
            <rect
              id="Rectangle_11"
              data-name="Rectangle 11"
              width="10"
              height="10"
              transform="translate(17 3)"
              stroke-width="2"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
            <rect
              id="Rectangle_12"
              data-name="Rectangle 12"
              width="10"
              height="10"
              transform="translate(17 17)"
              stroke-width="2"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
            <rect
              id="Rectangle_13"
              data-name="Rectangle 13"
              width="10"
              height="10"
              transform="translate(3 17)"
              stroke-width="2"
              stroke="#fff"
              stroke-linecap="round"
              stroke-linejoin="round"
              fill="none"
            />
          </g>
        </svg>

        <span>
          Hvað viltu læra?
          <ng-container *ngIf="activeFilter">
            <span class="filter-active">
              {{ activeFilter }}
            </span>
          </ng-container>
        </span>
      </span>

      <div class="categories__window">
        <ng-container *ngFor="let filterCategory of filters">
          <span
            class="category-title"
            *ngIf="true"
            [ngClass]="{ active: activeCat === filterCategory.name }"
            (click)="toggleCat()"
          >
            {{ filterCategory.name }}
            <ng-container *ngIf="activeCat === filterCategory.name">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="49.663"
                height="50"
                viewBox="0 0 49.663 50"
              >
                <g id="Close" transform="translate(-1665.491 -36.494)">
                  <g id="chevron" transform="translate(1538.978 36.495)">
                    <g
                      id="Group_65"
                      data-name="Group 65"
                      transform="translate(126.513)"
                    >
                      <path
                        id="Path_52"
                        data-name="Path 52"
                        d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                        transform="translate(-126.513)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                  <g
                    id="chevron-2"
                    data-name="chevron"
                    transform="translate(1840.959 86.117) rotate(180)"
                  >
                    <g
                      id="Group_65-2"
                      data-name="Group 65"
                      transform="translate(126.513)"
                    >
                      <path
                        id="Path_52-2"
                        data-name="Path 52"
                        d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                        transform="translate(-127.22 -0.707)"
                        fill="#fff"
                      />
                      <path
                        id="Path_54"
                        data-name="Path 54"
                        d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                        transform="translate(-126.513)"
                        fill="#fff"
                      />
                    </g>
                  </g>
                </g>
              </svg>
            </ng-container>
          </span>

          <ul class="noStyle">
            <ng-container *ngFor="let item of filterCategory.items">
              <ng-container>
                <li
                  [ngClass]="{
                    active: activeTag === item.label.name
                  }"
                >
                  <a
                    [routerLink]="[]"
                    [queryParams]="{ label_id: item.label.id }"
                    queryParamsHandling="merge"
                    href="/namskeid?label_id={{ item.label.id }}"
                    (click)="filterLabel($event, item.label)"
                  >
                    {{ item.label.name }}
                  </a>

                  <ng-container *ngIf="activeFilter === item.label.name">
                    <span [routerLink]="[]" (click)="removeFilter()">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="49.663"
                        height="50"
                        viewBox="0 0 49.663 50"
                      >
                        <g id="Close" transform="translate(-1665.491 -36.494)">
                          <g
                            id="chevron"
                            transform="translate(1538.978 36.495)"
                          >
                            <g
                              id="Group_65"
                              data-name="Group 65"
                              transform="translate(126.513)"
                            >
                              <path
                                id="Path_52"
                                data-name="Path 52"
                                d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                                transform="translate(-126.513)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                          <g
                            id="chevron-2"
                            data-name="chevron"
                            transform="translate(1840.959 86.117) rotate(180)"
                          >
                            <g
                              id="Group_65-2"
                              data-name="Group 65"
                              transform="translate(126.513)"
                            >
                              <path
                                id="Path_52-2"
                                data-name="Path 52"
                                d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                                transform="translate(-127.22 -0.707)"
                                fill="#fff"
                              />
                              <path
                                id="Path_54"
                                data-name="Path 54"
                                d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                                transform="translate(-126.513)"
                                fill="#fff"
                              />
                            </g>
                          </g>
                        </g>
                      </svg>
                    </span>
                  </ng-container>
                </li>
              </ng-container>
            </ng-container>
          </ul>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="filters_container__right">
    <div class="sorting">
      <ng-container *ngFor="let courseVariant of eduframeCourseVariants">
        <div
          class="sorting__tab"
          [ngClass]="{
            active: courseVariant.id == this.courseVariantId,
            blue: activeCourseType === 'Y0004'
          }"
          [routerLink]="[]"
          [queryParams]="{
            course_variant_id: courseVariantId ? null : courseVariant.id
          }"
          queryParamsHandling="merge"
        >
          <a style="color: white; cursor: pointer">
            {{ courseVariant.name }}
          </a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
