<div class="ehi wrapper" [ngClass]="{ fadein: (loaded | async) }">
  <div class="ehi-intro">
    <div class="ehi-intro__left">
      <p *ngFor="let content of prismicContent?.intro_text">
        {{ content.text }}
      </p>
    </div>

    <div class="ehi-intro__right">
      <figure>
        <img
          [src]="prismicContent?.intro_img.url"
          [alt]="prismicContent?.intro_img.alt"
        />
      </figure>
    </div>
  </div>

  <div class="fagrad">
    <en-overhead-title></en-overhead-title>
    <en-heading
      [headingContent]="prismicContent?.council_title[0]"
    ></en-heading>
    <p>
      {{ prismicContent?.council_text[0].text }}
    </p>
    <div class="fagrad-grid">
      <div class="fagrad-grid__section">
        <h5>{{ prismicContent?.council_finance }}</h5>
        <ul>
          <li *ngFor="let listItem of prismicContent?.council_finance_list">
            <en-prismic-text
              [prismicText]="{ content: listItem.council_finance_list_item }"
            ></en-prismic-text>
          </li>
        </ul>
      </div>

      <div class="fagrad-grid__section">
        <h5>{{ prismicContent?.council_management }}</h5>
        <ul>
          <li *ngFor="let listItem of prismicContent?.council_management_list">
            <en-prismic-text
              [prismicText]="{ content: listItem.council_management_item }"
            ></en-prismic-text>
          </li>
        </ul>
      </div>

      <div class="fagrad-grid__section">
        <h5>{{ prismicContent?.council_engineering }}</h5>
        <ul>
          <li *ngFor="let listItem of prismicContent?.council_engineering_list">
            <en-prismic-text
              [prismicText]="{ content: listItem.council_engineering_item }"
            ></en-prismic-text>
          </li>
        </ul>
      </div>

      <div class="fagrad-grid__section">
        <h5>{{ prismicContent?.council_information }}</h5>
        <ul>
          <li *ngFor="let listItem of prismicContent?.council_information_list">
            <en-prismic-text
              [prismicText]="{ content: listItem.council_information_item }"
            ></en-prismic-text>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="privacy-policy">
    <en-overhead-title></en-overhead-title>
    <en-heading
      [headingContent]="prismicContent?.privacypolicy_title[0]"
    ></en-heading>
    <en-prismic-text
      *ngIf="prismicContent?.privacypolicy_text"
      [prismicText]="{ content: prismicContent?.privacypolicy_text }"
    ></en-prismic-text>
  </div>

  <div class="affiliations">
    <en-overhead-title></en-overhead-title>
    <en-heading
      [headingContent]="prismicContent?.affiliations_title[0]"
    ></en-heading>
    <en-prismic-text
      *ngIf="prismicContent?.affiliations_text"
      [prismicText]="{ content: prismicContent?.affiliations_text }"
    ></en-prismic-text>
    <div class="affiliations-lists">
      <ng-container *ngFor="let slice of prismicContent?.body">
        <ng-container *ngIf="slice?.slice_type === 'fyrirt_ki___felog'">
          <h5>{{ slice.primary.titill }}</h5>
          <ul>
            <li *ngFor="let listItem of slice.items">
              <a
                [href]="listItem.item_anchor?.url"
                [target]="listItem.item_anchor?.target"
              >
                {{ listItem.item_title }}
              </a>
            </li>
          </ul>
        </ng-container>
        <ng-container *ngIf="slice.slice_type === 'text_component'">
          <div style="max-width: 960px">
            <en-prismic-text
              [prismicText]="{ content: slice?.primary.content }"
            ></en-prismic-text>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
