import { Injectable } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class UtilityService {
  constructor() {}

  public appendQueryParams(baseUrl: string, queryParams: string): string {
    if (baseUrl.includes('?')) {
      return `${baseUrl}&${queryParams}`;
    } else {
      return `${baseUrl}?${queryParams}`;
    }
  }

  public setHeaders(): HttpHeaders {
    // Do nothing, our generated client takes care of header setup
    // This is just a placeholder due to the old FileMaker service had this method
    return new HttpHeaders();
  }

  public inDateRange(): string {
    const theDate = new Date(Date.now());
    const format = 'MM/dd/yyyy';
    const locale = 'en-US';
    const dateString = formatDate(theDate, format, locale);
    return dateString;
  }
}
