<div class="header wrapper">
  <div class="section-header">
    <en-overhead-title> Hlutverk </en-overhead-title>

    <div class="filter-container">
      <en-heading
        class="main_item"
        [headingContent]="{ text: 'Endurmenntun', type: 'heading2' }"
        [routerLink]="['/um-okkur']"
        [routerLinkActive]="'active'"
        [routerLinkActiveOptions]="{ exact: true }"
      ></en-heading>
      <en-heading
        class="filter_item"
        [headingContent]="{ text: 'Starfsfólk', type: 'heading3' }"
        [routerLink]="['starfsfolk']"
        [routerLinkActive]="'active'"
      ></en-heading>
      <en-heading
        class="filter_item"
        [headingContent]="{ text: 'Stjórnin', type: 'heading3' }"
        [routerLink]="['stjornin']"
        [routerLinkActive]="'active'"
      ></en-heading>
    </div>
  </div>
</div>

<router-outlet></router-outlet>
