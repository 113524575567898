import {
  Component,
  Output,
  EventEmitter,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterContentInit,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';

@Component({
  selector: 'en-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.less'],
})
export class SearchComponent implements OnInit, OnDestroy, AfterContentInit {
  @Input() color: string;
  @Input() viewAll: boolean;
  @Input() header?: true;
  @Output() closeSearch = new EventEmitter<any>();
  @ViewChild('searchEl') searchElement: ElementRef;

  public searchInput: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private educationService: FakeMakerService
  ) {}

  ngOnInit(): void {
    const params = this.route.snapshot.queryParams;
    params.search
      ? (this.searchInput = params.search)
      : (this.searchInput = null);
  }

  search() {
    if (this.searchInput !== '') {
      if (this.header) {
        this.router.navigate(['/search-results'], {
          queryParams: { search: this.searchInput },
        });
        this.closeSearch.emit('');
      } else {
        this.router.navigate([], { queryParams: { search: this.searchInput } });
      }
    }
  }

  ngAfterContentInit(): void {
    if (this.header) {
      setTimeout(() => {
        this.searchElement.nativeElement.focus();
      }, 0);
    }
  }

  ngOnDestroy(): void {
    if (this.header) {
      this.searchElement.nativeElement.blur();
    }
  }

  public clearSearchInput(): void {
    const params = this.route.snapshot.queryParams;
    this.searchInput = '';
    if (Object.keys(params).length) {
      this.educationService.clearSortingAndFilters();
    }
  }
}
