<form (ngSubmit)="onSubmit(skraningarForm)" #skraningarForm="ngForm">
  <div class="form-inputs">
    <div class="form-input-group">
      <label for="nafn">
        {{ lang === 'is' || !lang ? 'Nafn' : 'Name' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="nafn"
        required
        [(ngModel)]="model.Nafn"
        name="nafn"
        #name="ngModel"
        autocomplete="name"
      />
      <div class="alert" [hidden]="name.valid || name.pristine">
        {{
          lang === 'is' || !lang
            ? 'Nafn þarf að vera fyllt út'
            : 'Name is required'
        }}
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (kennitala.invalid &&
            kennitala.errors.required &&
            kennitala.touched) ||
          (kennitala.invalid && kennitalaBlur && kennitala.errors.minlength)
      }"
    >
      <label for="kennitala">
        {{
          lang === 'is' || !lang ? 'Kennitala' : 'Personal ID/ Date of Birth'
        }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="kennitala"
        required
        enValidKennitala
        [(ngModel)]="model.Kennitala"
        name="kennitala"
        placeholder="{{
          lang === 'is' || !lang ? '' : 'Personal ID or DDMMYY'
        }}"
        #kennitala="ngModel"
        minlength="{{ lang === 'is' || !lang ? 10 : 6 }}"
        maxlength="{{ lang === 'is' || !lang ? 10 : 10 }}"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (paste)="onPaste($event)"
        (blur)="kennitalaBlur = true"
        autocomplete="off"
      />

      <ng-container
        *ngIf="kennitala.invalid && (kennitala.dirty || kennitala.touched)"
      >
        <div class="alert" *ngIf="kennitala.control.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Kennitala þarf að vera fyllt út'
              : 'Personal ID/ Date of Birth is required'
          }}
        </div>
        <div
          class="alert"
          *ngIf="kennitala.control.errors.minlength && kennitalaBlur === true"
        >
          {{
            lang === 'is' || !lang
              ? 'Kennitala þarf að vera 10 stafir'
              : 'Date of birth has to be 6 digits, day-month-year, Example: 241272. Personal ID has to be 10 digits.'
          }}
        </div>
        <div
          class="alert"
          *ngIf="
            kennitala.control.errors.invalidKennitala && kennitalaBlur === true
          "
        >
          {{
            lang === 'is' || !lang
              ? 'Þessi kennitala er ekki gild.'
              : 'This personal ID is not valid.'
          }}
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="heimilisfang">
        {{ lang === 'is' || !lang ? 'Heimilisfang' : 'Address' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="heimilisfang"
        required
        [(ngModel)]="model.Heimilisfang"
        name="heimilisfang"
        #heimilisfang="ngModel"
        autocomplete="street-address"
      />
      <div class="alert" [hidden]="heimilisfang.valid || heimilisfang.pristine">
        {{
          lang === 'is' || !lang
            ? 'Heimilisfang þarf að vera fyllt út'
            : 'Address is required'
        }}
      </div>
    </div>

    <div class="form-input-group">
      <label for="postnumer">
        {{ lang === 'is' || !lang ? 'Póstnúmer' : 'Zip Code' }}
        <span class="must-fill">*</span>
      </label>
      <select
        name="postnumer"
        id="postnumer"
        required
        [(ngModel)]="model.Postnumer"
        #postnumer="ngModel"
        autocomplete="postal-code"
      >
        <option disabled selected value>
          {{
            lang === 'is' || !lang
              ? '-- Veldu póstnúmer -- '
              : 'Select Zip Code'
          }}
        </option>
        <ng-container *ngFor="let postalCode of postalCodes">
          <option [value]="getOnlyPostal(postalCode)">{{ postalCode }}</option>
        </ng-container>
      </select>
      <div class="alert" [hidden]="postnumer.valid || postnumer.pristine">
        {{
          lang === 'is' || !lang
            ? 'Póstnúmer þarf að vera fyllt út'
            : 'Zip code is required'
        }}
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (farsimi.invalid && farsimi.errors.required && farsimi.touched) ||
          (farsimi.invalid && farsimiBlur && farsimi.errors.minlength)
      }"
    >
      <label for="nafn">
        <ng-container [ngSwitch]="this.lang">
          <ng-container *ngSwitchCase="Language.English"
            >Mobile phone</ng-container
          >
          <ng-container *ngSwitchCase="Language.Icelandic"
            >Farsími</ng-container
          >
        </ng-container>
        <span class="must-fill">*</span>
      </label>
      <input
        type="text"
        id="farsimi"
        required
        [(ngModel)]="model.Farsimi"
        name="farsimi"
        #farsimi="ngModel"
        minlength="7"
        maxlength="7"
        autocomplete="tel"
        (paste)="onPhoneNumberFromClipboard($event)"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="farsimiBlur = true"
      />
      <ng-container
        *ngIf="farsimi.invalid && (farsimi.dirty || farsimi.touched)"
      >
        <div class="alert" *ngIf="farsimi.control.errors.required">
          <ng-container [ngSwitch]="this.lang">
            <ng-container *ngSwitchCase="Language.English"
              >Mobile phone is required</ng-container
            >
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Farsími þarf að vera fylltur út</ng-container
            >
          </ng-container>
        </div>
        <div
          class="alert"
          *ngIf="farsimi.control.errors.minlength && farsimiBlur === true"
        >
          {{
            lang === 'is' || !lang
              ? 'Farsími þarf að vera 7 stafir'
              : 'Mobile phone has to be atleast 7 digits'
          }}
        </div>
      </ng-container>
    </div>
  </div>
  <div class="form-inputs">
    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (netfang.invalid && netfang.errors.required && netfang.touched) ||
          (netfang.invalid && netfangBlur && netfang.errors.minlength)
      }"
    >
      <label for="netfang">
        {{ lang === 'is' || !lang ? 'Netfang' : 'Email' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="email"
        id="netfang"
        required
        [(ngModel)]="model.Netfang"
        name="netfang"
        #netfang="ngModel"
        autocomplete="email"
        (ngModelChange)="model.Netfang = $event.toLowerCase()"
        pattern="^(.+@.+\..+)$"
        (blur)="netfangBlur = true"
      />
      <ng-container
        *ngIf="netfang.invalid && (netfang.dirty || netfang.touched)"
      >
        <div class="alert" *ngIf="netfang.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Netfang þarf að vera fyllt út'
              : 'Email is required'
          }}
        </div>
        <div
          class="alert"
          *ngIf="netfang.errors.pattern && netfangBlur === true"
        >
          {{
            lang === 'is' || !lang
              ? 'Netfang þarf að vera gilt'
              : 'Email has to be valid'
          }}
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="netfangStadfest">
        {{ lang === 'is' || !lang ? 'Endurtaka netfang' : 'Repeat email' }}
        <span class="must-fill">*</span>
      </label>
      <input
        type="email"
        id="netfangStadfest"
        required
        name="netfangStadfest"
        #netfangStadfest="ngModel"
        [(ngModel)]="netfangEndurtekid"
        (ngModelChange)="netfangEndurtekid = $event.toLowerCase()"
        pattern="{{ netfang.value }}"
      />
      <ng-container
        *ngIf="
          netfangStadfest.invalid &&
          (netfangStadfest.dirty || netfangStadfest.touched)
        "
      >
        <div class="alert" *ngIf="netfangStadfest.errors.required">
          {{
            lang === 'is' || !lang
              ? 'Vinsamlegast endurtaktu netfangið'
              : 'Please repeat your email'
          }}
        </div>
        <div class="alert" *ngIf="netfangStadfest.errors.pattern">
          {{
            lang === 'is' || !lang
              ? 'Netföng passa ekki'
              : 'Emails do not match'
          }}
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-input-group fullwidth checkbox add-btm-spacing">
    <div class="checkbox-wrapper">
      <div class="checkmark-container">
        <input
          type="checkbox"
          id="postlisti"
          [value]="'true'"
          [(ngModel)]="model.Postlisti"
          name="postlisti"
          #postlisti="ngModel"
        />
        <div class="checkmark"></div>
      </div>
      <label for="nafn" *ngIf="lang === 'is' || !lang"
        >Já, takk. Ég vil fá senda tölvupósta um námskeið og námsbrautir
        Endurmenntunar - skrá mig á póstlista.</label
      >
      <label for="nafn" *ngIf="lang === 'en'"
        >I want to receive emails about courses and classes from Endurmenntun -
        Sign me up for mailing list.</label
      >
    </div>
  </div>

  <div class="form-info payment-method">
    <h1>
      <ng-container [ngSwitch]="lang">
        <span *ngSwitchCase="'en'"> Payment information </span>
        <span *ngSwitchDefault> Upplýsingar um greiðslu </span>
      </ng-container>
    </h1>

    <ng-container [ngSwitch]="lang">
      <h2 *ngSwitchCase="'en'">Paid by</h2>
      <h2 *ngSwitchDefault>Greiðandi</h2>
    </ng-container>

    <div class="form-input-group fullwidth radio">
      <div class="radio-wrapper">
        <div class="radio-container">
          <input
            type="radio"
            id="greidandi"
            [value]="'Þátttakandi'"
            [(ngModel)]="model.Hver_Greidir"
            name="greidandi"
            #greidandi="ngModel"
            [checked]="true"
          />
          <div class="radio-mark"></div>
        </div>
        <label for="greidandi">
          {{ lang === 'is' || !lang ? 'Þátttakandi' : 'Participant' }}
        </label>
      </div>

      <div class="radio-wrapper">
        <div class="radio-container">
          <input
            type="radio"
            id="greidandi"
            [value]="'Annar en þátttakandi'"
            [(ngModel)]="model.Hver_Greidir"
            name="greidandi"
            #greidandi="ngModel"
          />
          <div class="radio-mark"></div>
        </div>
        <label for="greidandi">
          <ng-container [ngSwitch]="lang">
            <span *ngSwitchCase="'en'">
              Other than participant or organization
            </span>
            <span *ngSwitchDefault>
              Annar en þátttakandi eða fyrirtæki/stofnun
            </span>
          </ng-container>
        </label>
      </div>
    </div>

    <ng-container *ngIf="model.Hver_Greidir !== 'Annar en þátttakandi'">
      <div class="payment-info-pei">
        <div class="image">
          <img src="/assets/Media/logos/pei-logo-web.png" />
        </div>
        <div class="content" [ngSwitch]="lang">
          <p *ngSwitchCase="'en'">
            Individuals will receive a payment link from
            <a
              target="_BLANK"
              alt="Pei Greiðslumiðlun"
              href="https://www.pei.is/"
              >Pei</a
            >
            where the payment method is chosen (see
            <i>terms and conditions</i>).
          </p>
          <p *ngSwitchDefault>
            Einstaklingar fá greiðsluhlekk frá
            <a
              target="_BLANK"
              alt="Pei Greiðslumiðlun"
              href="https://www.pei.is/"
              >Pei</a
            >
            þar sem valinn er greiðslumáti (sjá <i>skilmála og fyrirvara</i>).
          </p>
        </div>
      </div>
    </ng-container>

    <!-- Ef annar en þáttakandi, ná í upplýsingar um þann einstakling -->

    <ng-container *ngIf="model.Hver_Greidir === 'Annar en þátttakandi'">
      <div class="form-inputs">
        <div class="form-input-group">
          <label for="nafngreidanda">
            {{
              lang === 'is' || !lang
                ? 'Nafn/Fyrirtæki/Stofnun'
                : 'Name/Organization'
            }}
            <span class="must-fill">*</span>
          </label>
          <input
            type="text"
            id="nafngreidanda"
            required
            [(ngModel)]="model.Nafn_Greidanda"
            name="nafngreidanda"
            #nafngreidanda="ngModel"
            autocomplete="name"
          />
          <div
            class="alert"
            [hidden]="nafngreidanda.valid || nafngreidanda.pristine"
          >
            {{
              lang === 'is' ? 'Nafn þarf að vera fyllt út' : 'Name is required'
            }}
          </div>
        </div>

        <div
          class="form-input-group"
          [ngClass]="{
            error:
              (kennitalagreidanda.invalid &&
                kennitalagreidanda.errors.required &&
                kennitalagreidanda.touched) ||
              (kennitalagreidanda.invalid &&
                kennitalaGreidandaBlur &&
                kennitalagreidanda.errors.minlength)
          }"
        >
          <label for="kennitalagreidanda">
            {{
              lang === 'is' || !lang
                ? 'Kennitala'
                : 'Personal ID/ Date of Birth'
            }}
            <span class="must-fill">*</span>
          </label>
          <input
            type="text"
            id="kennitalagreidanda"
            required
            [(ngModel)]="model.Kennitala_Greidanda"
            name="kennitalagreidanda"
            #kennitalagreidanda="ngModel"
            minlength="10"
            maxlength="10"
            (paste)="onPaste($event)"
            onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
            (blur)="kennitalaGreidandaBlur = true"
          />

          <ng-container
            *ngIf="
              kennitalagreidanda.invalid &&
              (kennitalagreidanda.dirty || kennitalagreidanda.touched)
            "
          >
            <div
              class="alert"
              *ngIf="kennitalagreidanda.control.errors.required"
            >
              {{
                lang === 'is' || !lang
                  ? 'Kennitala þarf að vera fyllt út'
                  : 'Personal ID/ Date of Birth is required'
              }}
            </div>
            <div
              class="alert"
              *ngIf="
                kennitalagreidanda.control.errors.minlength &&
                kennitalaBlur === true
              "
            >
              {{
                lang === 'is' || !lang
                  ? 'Kennitala þarf að vera 10 stafir'
                  : 'Social Security Number has '
              }}
            </div>
          </ng-container>
        </div>

        <div class="form-input-group">
          <label for="heimilisfanggreidanda">
            {{ lang === 'is' || !lang ? 'Heimilisfang' : 'Address' }}
            <span class="must-fill">*</span>
          </label>
          <input
            type="text"
            id="heimilisfanggreidanda"
            required
            [(ngModel)]="model.Heimilisfang_Greidanda"
            name="heimilisfanggreidanda"
            #heimilisfanggreidanda="ngModel"
            autocomplete="street-address"
          />
          <div
            class="alert"
            [hidden]="
              heimilisfanggreidanda.valid || heimilisfanggreidanda.pristine
            "
          >
            {{
              lang === 'is'
                ? 'Heimilisfang þarf að vera fyllt út'
                : 'Address is required'
            }}
          </div>
        </div>

        <div class="form-input-group">
          <label for="postnumergreidanda">
            {{ lang === 'is' || !lang ? 'Póstnúmer' : 'Zip Code' }}
            <span class="must-fill">*</span>
          </label>
          <select
            name="postnumergreidanda"
            id="postnumergreidanda"
            required
            [(ngModel)]="model.Postnumer_Greidandi"
            #postnumergreidanda="ngModel"
            autocomplete="postal-code"
          >
            <option disabled selected value>
              {{
                lang === 'is' || !lang
                  ? '-- Veldu póstnúmer -- '
                  : 'Select Zip Code'
              }}
            </option>
            <ng-container *ngFor="let postalCode of postalCodes">
              <option [value]="getOnlyPostal(postalCode)">
                {{ postalCode }}
              </option>
            </ng-container>
          </select>
          <div
            class="alert"
            [hidden]="postnumergreidanda.valid || postnumergreidanda.pristine"
          >
            {{
              lang === 'is' || !lang
                ? 'Póstnúmer þarf að vera fyllt út'
                : 'Zip code is required'
            }}
          </div>
        </div>

        <div class="form-input-group">
          <label for="gsmnumerfyr">
            {{ lang === 'is' || !lang ? 'Farsími' : 'Cell phone number' }}
            <span class="must-fill">*</span>
          </label>
          <input
            type="text"
            id="gsmnumerfyr"
            required
            [(ngModel)]="model.GSM_numer_FYR"
            name="gsmnumerfyr"
            #gsmnumerfyr="ngModel"
            autocomplete="gsm-numer-fyr"
          />
          <div
            class="alert"
            [hidden]="gsmnumerfyr.valid || gsmnumerfyr.pristine"
          >
            {{
              lang === 'is'
                ? 'Farsími þarf að koma fram'
                : 'Cell phone number is required'
            }}
          </div>
        </div>
      </div>

      <div class="form-inputs">
        <div class="form-input-group">
          <label for="deliverto">
            {{ lang === 'is' || !lang ? 'Berist til' : 'Send to' }}
          </label>
          <input
            type="text"
            id="deliverto"
            [(ngModel)]="model.Greidandi_Berist_Til"
            name="deliverto"
            #deliverto="ngModel"
          />
        </div>

        <div class="form-input-group">
          <label for="vegna">
            {{ lang === 'is' ? 'Vegna' : 'Because of' }}
          </label>
          <input
            type="text"
            id="vegna"
            [(ngModel)]="model.Vegna"
            name="vegna"
            #vegna="ngModel"
          />
        </div>

        <div
          class="form-input-group"
          [ngClass]="{
            error:
              (netfanggreidanda.invalid &&
                netfanggreidanda.errors.required &&
                netfanggreidanda.touched) ||
              (netfanggreidanda.invalid &&
                netfangGreidandaBlur &&
                netfanggreidanda.errors.minlength)
          }"
        >
          <label for="netfanggreidanda">
            {{
              lang === 'is'
                ? 'Netfang greiðanda reiknings'
                : 'Email of invoice payee'
            }}
            <span class="must-fill">*</span>
          </label>
          <input
            type="email"
            id="netfanggreidanda"
            required
            [(ngModel)]="model.Netfang_Greidanda"
            name="netfanggreidanda"
            #netfanggreidanda="ngModel"
            autocomplete="email"
            (ngModelChange)="model.Netfang_Greidanda = $event.toLowerCase()"
            pattern="^(.+@.+\..+)$"
            (blur)="netfangGreidandaBlur = true"
          />
          <ng-container
            *ngIf="
              netfanggreidanda.invalid &&
              (netfanggreidanda.dirty || netfanggreidanda.touched)
            "
          >
            <div class="alert" *ngIf="netfanggreidanda.errors.required">
              {{
                lang === 'is'
                  ? 'Netfang þarf að vera fyllt út'
                  : 'Email is required'
              }}
            </div>
            <div
              class="alert"
              *ngIf="
                netfanggreidanda.errors.pattern && netfangGreidandaBlur === true
              "
            >
              {{
                lang === 'is'
                  ? 'Netfang þarf að vera gilt'
                  : 'Email has to be valid'
              }}
            </div>
          </ng-container>
        </div>

        <div class="form-input-group">
          <label for="netfanggreidandaStadfest">
            {{
              lang === 'is' || !lang
                ? 'Endurtaka netfang greiðanda'
                : 'Repeat payee email'
            }}
            <span class="must-fill">*</span>
          </label>
          <input
            type="email"
            id="netfanggreidandaStadfest"
            required
            name="netfanggreidandaStadfest"
            #netfanggreidandaStadfest="ngModel"
            [(ngModel)]="netfangGreidandaEndurtekid"
            (ngModelChange)="netfangGreidandaEndurtekid = $event.toLowerCase()"
            pattern="{{ netfanggreidanda.value }}"
          />
          <ng-container
            *ngIf="
              netfanggreidandaStadfest.invalid &&
              (netfanggreidandaStadfest.dirty ||
                netfanggreidandaStadfest.touched)
            "
          >
            <div class="alert" *ngIf="netfanggreidandaStadfest.errors.required">
              {{
                lang === 'is' || !lang
                  ? 'Vinsamlegast endurtaktu netfangið'
                  : 'Please repeat your email'
              }}
            </div>
            <div class="alert" *ngIf="netfanggreidandaStadfest.errors.pattern">
              {{
                lang === 'is' || !lang
                  ? 'Netföng passa ekki'
                  : 'Emails do not match'
              }}
            </div>
          </ng-container>
        </div>

        <div
          class="form-input-group"
          [ngClass]="{
            error:
              (netfangmottakanda.invalid &&
                netfangmottakanda.errors.required &&
                netfangmottakanda.touched) ||
              (netfangmottakanda.invalid &&
                netfangMottakandaBlur &&
                netfangmottakanda.errors.minlength)
          }"
        >
          <label for="netfanggreidanda">
            {{ lang === 'is' ? 'Netfang afrit' : 'Confirmation copy to' }}
          </label>
          <input
            type="email"
            id="netfangmottakanda"
            [(ngModel)]="model.Netfang_Mottakandi_Reiknings"
            name="netfangmottakanda"
            #netfangmottakanda="ngModel"
            autocomplete="email"
            (ngModelChange)="
              model.Netfang_Mottakandi_Reiknings = $event.toLowerCase()
            "
            pattern="^(.+@.+\..+)$"
            (blur)="netfangMottakandaBlur = true"
          />

          <ng-container
            *ngIf="
              netfangmottakanda.invalid &&
              (netfangmottakanda.dirty || netfangmottakanda.touched)
            "
          >
            <div
              class="alert"
              *ngIf="
                netfangmottakanda.errors.pattern &&
                netfangMottakandaBlur === true
              "
            >
              Netfang þarf að vera gilt
            </div>
          </ng-container>
          <p class="sub-text">
            {{
              lang === 'is'
                ? 'Ef þú óskar eftir því að þriðji aðili fái afrit af staðfestingarskeyti vinsamlegast settu netfangið hér'
                : 'If you wish for a confirmation to be sent to a third party please register their email here'
            }}
          </p>
        </div>
      </div>
    </ng-container>

    <!--<div class="form-input-group fullwidth radio">
			<div class="radio-wrapper">
				<div class="radio-container">
					<input type="radio" id="greidslumati" [value]="'Kreditkort'" [(ngModel)]="model.Greidslumati" name="greidslumati" #greidslumati="ngModel" [checked]="true">
					<div class="radio-mark"></div>
				</div>
				<label for="greidslumati">
					{{(lang === 'is' ? 'Kreditkort' : 'Credit Card')}}
				</label>
			</div>

			<div class="radio-wrapper">
				<div class="radio-container">
					<input type="radio"  id="greidslumati" [value]="'Greiðsluseðill'" [(ngModel)]="model.Greidslumati" name="greidslumati" #greidslumati="ngModel">
					<div class="radio-mark"></div>
				</div>
				<label for="greidslumati">{{lang === 'is' ? 'Greiðslukrafa í banka' : 'Invoice to online bank'}}</label>
			</div>
		</div>-->
  </div>

  <div class="form-input-group fullwidth text">
    <label for="nafn">
      {{ lang === 'is' || !lang ? 'Athugasemdir' : 'Comments' }}
    </label>
    <textarea
      type="text"
      id="athugasemdir"
      rows="6"
      [(ngModel)]="model.Athugasemd"
      name="athugasemdir"
      #athugasemdir="ngModel"
      maxlength="300"
    ></textarea>
    <div class="max-letters">
      <span>
        <ng-container
          *ngIf="athugasemdir?.value && athugasemdir?.value.length !== 0"
        >
          {{ athugasemdir.value.length }}
        </ng-container>
      </span>
      <span *ngIf="lang === 'is' || !lang">Hámark 300 stafabil</span>
      <span *ngIf="lang === 'en'">Maximum 300 characters</span>
    </div>
  </div>

  <ng-container *ngIf="disclaimer !== ''">
    <div class="form-info terms-and-conditions">
      <h2>
        {{
          lang === 'is' || !lang
            ? 'Skilmálar og fyrirvari'
            : 'Terms and conditions'
        }}
      </h2>
      <div class="form-info__content">
        <p [innerHTML]="disclaimer"></p>
      </div>

      <div class="form-input-group fullwidth checkbox">
        <div class="checkbox-wrapper">
          <div class="checkmark-container">
            <input
              type="checkbox"
              id="skilmalar"
              required
              [value]="'true'"
              name="skilmalar"
              [(ngModel)]="model['Samþykkja_skilmala']"
              #skilmalar="ngModel"
            />
            <div class="checkmark"></div>
          </div>
          <label for="skilmalar" *ngIf="lang === 'is' || !lang"
            >Ég samþykki ofangreinda skilmála
            <span class="must-fill">*</span></label
          >
          <label for="skilmalar" *ngIf="lang === 'en'"
            >I accept the aforementioned terms
            <span class="must-fill">*</span></label
          >
        </div>
        <div
          class="alert"
          [hidden]="
            skilmalar.valid || skilmalar.pristine || skilmalar.value === 'true'
          "
        >
          {{
            lang === 'is' || !lang
              ? 'Vinsamlega samþykktu skilmála'
              : 'Terms and conditions have to be agreed upon'
          }}
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-info teaching-material" *ngIf="digital">
    <h2>{{ lang === 'is' || !lang ? 'Námsgögn' : 'Course material' }}</h2>
    <div class="form-info__content">
      <p *ngIf="lang === 'is' || !lang">
        Ef námsgögn fylgja námskeiðinu þá verða þau eingöngu á rafrænu formi og
        send á það netfang sem þátttakandi gefur upp við skráningu. Það er svo
        ákvörðun viðkomandi kennara hvort námsgögn verði send út áður en
        námskeið hefst eða eftir að því lýkur.
      </p>
      <p *ngIf="lang === 'en'">
        If study materials accompany the course, they will only be in electronic
        form and sent to the email address provided by the participant during
        registration. It is the decision of the respective teacher whether study
        materials will be sent out before the course begins or after it ends.
      </p>
    </div>
  </div>

  <div class="form-info teaching-material">
    <div class="form-info__content">
      <p *ngIf="lang === 'is' || !lang">
        Tölvupóstur verður sendur sem staðfesting á móttöku skráningar. Ef þér
        berst engin staðfesting, vinsamlega hafðu samband við ENDURMENNTUN í
        síma 525 4444 eða sendu tölvupóst á
        <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
      </p>
      <p *ngIf="lang === 'en'">
        An email will be sent as confirmation for your registration. Please
        contact us via phone +354 525 4444 if confirmation does not show up in
        your email or email us at
        <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
      </p>
    </div>
  </div>

  <en-button
    [buttonContent]="lang === 'is' ? 'Staðfesta' : 'Confirm'"
    [buttonType]="'regular'"
    [buttonColor]="'red'"
    type="submit"
  ></en-button>
  <ng-container *ngIf="reqProcessing"> Vinn úr skráningu... </ng-container>

  <div class="alert" [hidden]="formValid || reqProcessing">
    <ng-container *ngIf="reqProcessing === false">
      {{
        lang === 'is'
          ? 'Villa kom upp í skráningu, vinsamlegast fylgdu villumeldingum hér fyrir ofan'
          : 'An error occured in the registration, please follow the error messages above.'
      }}
    </ng-container>
  </div>
  <div class="alert" [hidden]="!registerStudentError">
    <ng-container *ngIf="registerStudentError && registerStudentError.status">
      Villa kom upp í skráningarferlinu. Vinsamlegast hafðu samband við
      ENDURMENNTUN í síma <a href="tel:5254444">525 4444</a> eða sendu tölvupóst
      á <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
    </ng-container>
  </div>
</form>
