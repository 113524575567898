import { Component, Input } from '@angular/core';

@Component({
  selector: 'en-overhead-title',
  template: `
    <span [ngClass]="{ secondary: color === 'Y0004' || color === 'blue' }">
      <ng-content></ng-content>
    </span>
  `,
  styleUrls: ['./overhead-title.component.less'],
})
export class OverheadTitleComponent {
  @Input() color;
  @Input() overHeadContent;

  constructor() {}
}
