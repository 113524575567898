import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular-ivy';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { version } from './version';

import 'hammerjs';
import { OpenAPI } from './eduframe';

// Sentry.init({
//   dsn: 'https://a86f8dd7265406f44ee735723e8d1cff@sentry.opinkerfi.dev/4',
//   release: version.release,
// });

OpenAPI.BASE = environment.eduframe.endpoint;

if (environment.mode !== "development") {
  enableProdMode();
}

enableProdMode();
document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .then(ref => {
      if (window['ngRef']) {
        window['ngRef'].destroy();
      }
      window['ngRef'] = ref;
    })
    .catch(err => console.error(err));
});
