<ng-content></ng-content>

<div class="news-grid-container wrapper" *ngIf="prismicNews">
  <div class="article-featured">
    <a
      href=""
      [routerLink]="
        newsType +
        '/' +
        (newsType === 'frettir' ? prismicNews[0]?.uid : prismicArticles[0]?.uid)
      "
    >
      <figure>
        <img
          [src]="
            newsType === 'frettir'
              ? prismicNews[0]?.data.featured_image.url
              : prismicArticles[0]?.data.featured_image.url
          "
          [alt]="
            newsType === 'frettir'
              ? prismicNews[0]?.data.featured_image.alt
              : prismicArticles[0]?.data.featured_image.alt
          "
        />
        <div class="overlay">
          <span>Skoða frétt</span>
        </div>
      </figure>

      <div class="article-content">
        <a href="">
          <span class="text-over">
            {{
              newsType === 'frettir'
                ? prismicNews[0]?.data.article_date
                : prismicArticles[0]?.data.article_date
            }}
          </span>
          <h3>
            {{
              newsType === 'frettir'
                ? prismicNews[0]?.data.title[0].text
                : prismicArticles[0]?.data.title[0].text
            }}
          </h3>
        </a>
      </div>
    </a>
  </div>

  <div class="articles-side">
    <ng-container
      *ngFor="
        let news of newsType === 'frettir' ? prismicNews : prismicArticles;
        let i = index
      "
    >
      <div class="article" *ngIf="i >= 1 && i! < 5">
        <a href="" [routerLink]="newsType + '/' + news?.uid">
          <span class="text-over">
            {{ news.data.article_date }}
          </span>
          <h3>{{ news.data.title[0].text }}</h3>
        </a>
      </div>
    </ng-container>

    <en-button
      [buttonType]="'main'"
      [buttonContent]="
        'Skoða fleiri ' + (newsType === 'frettir' ? 'fréttir' : 'greinar')
      "
      [routerLink]="newsType"
    ></en-button>
  </div>
</div>
