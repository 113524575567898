import { formatDate, isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { timer } from 'rxjs';
import { map, share } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  SERVER_URL: string = '/api/form/course';

  constructor(
    private http: HttpClient,
    @Inject(PLATFORM_ID) public platformId: any
  ) {}

  // returns date if lokadags_skraningar is greater than the date now
  formatEarlyRegistrationDate(
    content,
    year?,
    customLocale?,
    customFormat?,
    ordinal?
  ): string {
    if (content) {
      let format;
      if (customFormat) {
        format = customFormat;
      } else {
        format = year ? 'd. MMMM yyyy' : 'd. MMMM';
      }

      const dateSplitter = content.split('/');
      const mergedDate = new Date(
        dateSplitter[1] + '/' + dateSplitter[0] + '/' + dateSplitter[2]
      ).setHours(23, 59, 59, 999);
      const theDate = new Date(mergedDate);
      const locale = customLocale ? customLocale : 'is-IS';
      if (ordinal) {
        const day = this.ordinal(formatDate(theDate, 'd', locale));
        format = day + ' ' + format;
      }
      const dateNow = new Date(Date.now());
      if (theDate >= dateNow) {
        return formatDate(theDate, format, locale);
      } else {
        return null;
      }
    }
  }

  ordinal(day): string {
    switch (day) {
      case '1':
      case '21':
      case '31':
        return day + "'st of'";
        break;
      case '2':
      case '22':
        return day + "'nd of'";
        break;
      case '3':
      case '23':
        return day + "'rd of'";
        break;
      default:
        return day + "'th of'";
    }
  }

  formatSeats(seats): number {
    if (seats !== 'loka' && seats !== '') {
      return Number(seats);
    } else {
      return null;
    }
  }

  // gets file extension
  getExtension(filename) {
    const parts = filename.split('.');
    return parts[parts.length - 1];
  }

  // Custom Validations
  checkIfRightFileType(filesEl): boolean {
    for (const file of filesEl.files) {
      const extension = this.getExtension(file.name);
      if (file.name) {
        switch (extension.toLowerCase()) {
          case 'jpg':
          case 'pdf':
          case 'doc':
          case 'docx':
          case 'png':
          case 'jpeg':
          case 'rtf':
          case 'pptx':
          case 'txt':
            return true;
        }
        return false;
      } else {
        return true;
      }
    }
  }

  // checks if file is larger than 30mb
  checkIfTooLarge(filesEl): boolean {
    for (const file of filesEl.files) {
      const size = file.size / (1024 * 1024);
      if (size > 60.0) {
        return true;
      } else {
        return false;
      }
    }
  }

  // Courses and Classes Conditionals
  // when register button (classes) should be disabled
  ifSeatsAndPriceNotEmpty(seats, price): boolean {
    if (seats === 'Loka' && price === 0) {
      return false;
    } else {
      return true;
    }
  }

  // Change sentence to URL Slug
  toURL(text: string): string {
    return text
      .toLowerCase()
      .replace(/ /g, '-')
      .replace(/[^\w-]+/g, '');
  }

  public checkIfPast(Endar_Dags, Hefst_KL = '00:00:00'): boolean {
    const now = new Date();
    const courseEndDate = new Date(`${Endar_Dags} ${Hefst_KL}`);
    if (now > courseEndDate) {
      return true;
    } else {
      return false;
    }
  }

  public checkIfStarted(Byrjar_Dags, Hefst_KL): boolean {
    const now = new Date();
    const courseStartDate = new Date(Byrjar_Dags + ' ' + Hefst_KL);
    if (now > courseStartDate) {
      return true;
    } else {
      return false;
    }
  }

  showContent(): any {
    return timer(0).pipe(
      map(() => true),
      share()
    );
  }

  public getBrowserName() {
    if (isPlatformBrowser(this.platformId)) {
      const agent = window.navigator.userAgent.toLowerCase();
      switch (true) {
        case agent.indexOf('edge') > -1:
          return 'edge';
        case agent.indexOf('opr') > -1 && !!(<any>window).opr:
          return 'opera';
        case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
          return 'chrome';
        case agent.indexOf('trident') > -1:
          return 'ie';
        case agent.indexOf('firefox') > -1:
          return 'firefox';
        case agent.indexOf('safari') > -1:
          return 'safari';
        default:
          return 'other';
      }
    }
  }
}
