import { Injectable } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { Namskeid } from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService {
  constructor(
    private titleService: Title,
    private metaTagService: Meta,
    private location: Location
  ) {}

  setMetaTags(data): void {
    let title;
    let description;
    let keywords;
    const url = this.location.path;
    let imageUrl;

    if (data.meta_title && data.meta_title.length) {
      this.titleService.setTitle(data.meta_title[0].text);
      title = data.meta_title[0].text;
    } else {
      this.titleService.setTitle('Endurmenntun HÍ');
      title = 'Endurmenntun HÍ';
    }

    if (data.meta_description && data.meta_description.length) {
      description = data.meta_description[0].text;
    } else {
      description = 'Endurmenntun HÍ';
    }

    if (data.meta_keywords && data.meta_keywords.length) {
      keywords = data.meta_keywords[0].text;
    }

    if (data.meta_image && data.meta_image.url) {
      imageUrl = data.meta_image.url;
    } else {
      imageUrl =
        'https://images.prismic.io/endurmenntunis/c9b6df48-bb81-4383-9467-3e652c4e353d_endurment-22-04-20-32951-Resize.jpg?auto=compress,format';
    }

    this.pushMetaTags(title, description, imageUrl, url, keywords);
  }

  setFmMetaTags(course?: Namskeid): void {
    let title;
    let description;
    let imageUrl;
    const url = this.location.path;

    this.titleService.setTitle(course.Heiti);
    title = course.Heiti;
    description = course.Kynning;
    imageUrl = course.Mynd;
    this.pushMetaTags(title, description, imageUrl, url);
  }

  pushMetaTags(title, description, imageUrl, url, keywords?): void {
    this.metaTagService.addTags([
      { name: 'description', content: description },
      { name: 'keywords', content: keywords },
      // Open Graph
      { property: 'og:title', content: title },
      { property: 'og:description', content: description },
      { property: 'og:url', content: url },
      { property: 'og:image', content: imageUrl },
      // Twitter
      { name: 'twitter:title', content: title },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: imageUrl },
      { name: 'twitter:card', content: 'summary_large_image' },
    ]);
  }
}
