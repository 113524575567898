<div class="management wrapper">
  <div class="management__container">
    <div class="management-text">
      <en-heading
        [headingContent]="management?.data.management_title[0]"
      ></en-heading>
      <ng-container *ngIf="management?.data">
        <en-prismic-text
          [prismicText]="{ content: management?.data.management_content }"
        ></en-prismic-text>
      </ng-container>
    </div>
    <div class="right">
      <figure>
        <img
          [src]="management?.data?.body[0]?.primary?.image?.url"
          [alt]="management?.data?.body[0]?.primary?.image?.alt"
        />
      </figure>
    </div>
  </div>
</div>
