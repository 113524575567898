import { ElementsModule } from './../../elements/elements.module';
import { ComponentsModule } from './../../components/components.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

// Components
import { referencesGridComponent } from './references-grid/references-grid.component';
import { NewsGridComponent } from './news-grid/news-grid.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { SearchPageComponent } from '@endurmenntun/_components/pages/search-page/search-page.component';
import { FrontPageComponent } from './front-page.component';

@NgModule({
  declarations: [
    AboutUsComponent,
    NewsGridComponent,
    referencesGridComponent,
    SearchPageComponent,
    FrontPageComponent,
  ],
  imports: [CommonModule, ComponentsModule, ElementsModule, RouterModule],
  exports: [
    AboutUsComponent,
    NewsGridComponent,
    referencesGridComponent,
    FrontPageComponent,
    SearchPageComponent,
  ],
})
export class FrontPageModule {}
