/**
 * Clean up icelandic phone number
 * 
 * @param phoneNumber Hopefully a valid phone number
 * @returns Clean phone number
 * @throws Error on invalid phone number
 */
export function cleanIcelandicPhoneNumber(phoneNumber: string): string
{
    const countryCode: string = '+354';
    const validLength: number = 7;

    // Strip country code from phone number, if that's present
    if (phoneNumber.startsWith(countryCode)) {
      phoneNumber = phoneNumber.slice(countryCode.length);
    }

    // Remove every non-digit from phone umber
    phoneNumber = phoneNumber.replace(/\D/g, '');

    // Reject if we're outside our allowed length parameter
    if (phoneNumber.length !== validLength) {
      throw new Error('Invalid phone number');
    }

    return phoneNumber;
}