import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'en-course-rating',
  templateUrl: './course-rating.component.html',
  styleUrls: ['./course-rating.component.less'],
})
export class CourseRatingComponent implements OnInit {
  @Input() rating: any;
  public stars: number;
  public decimalNo: string;

  ngOnInit(): void {
    this.setRating();
  }

  async setRating(): Promise<void> {
    await this.rating;
    this.stars = Number(parseFloat(this.rating.replace(',', '.')).toFixed(2));
    this.decimalNo = Number(this.stars % 1)
      .toFixed(2)
      .toString()
      .substring(2, 4);
  }
}
