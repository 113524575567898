import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { YouTubePlayerModule } from '@angular/youtube-player';

// Components
import { HeadingComponent } from './heading/heading.component';
import { OverheadTitleComponent } from './overhead-title/overhead-title.component';
import { ButtonComponent } from './button/button.component';
import { SearchComponent } from './search/search.component';
import { FilterComponent } from './filter/filter.component';
import { PrismicTextComponent } from './prismic-text/prismic-text.component';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ArticleQuoteComponent } from './article-quote/article-quote.component';
import { FooterActionsComponent } from './footer-actions/footer-actions.component';
import { PdfComponent } from './footer-actions/pdf/pdf.component';
import { AlertBannerComponent } from './alert-banner/alert-banner.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';
import { SortPipe } from '@endurmenntun/_pipes/sort.pipe';

@NgModule({
  declarations: [
    HeadingComponent,
    OverheadTitleComponent,
    ButtonComponent,
    SearchComponent,
    FilterComponent,
    PrismicTextComponent,
    ArticleQuoteComponent,
    FooterActionsComponent,
    PdfComponent,
    AlertBannerComponent,
    YoutubePlayerComponent,
    SortPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    YouTubePlayerModule,
    BsDatepickerModule.forRoot(),
  ],
  exports: [
    HeadingComponent,
    OverheadTitleComponent,
    ButtonComponent,
    SearchComponent,
    FilterComponent,
    PrismicTextComponent,
    ArticleQuoteComponent,
    FooterActionsComponent,
    AlertBannerComponent,
    YoutubePlayerComponent,
    SortPipe,
  ],
})
export class ElementsModule {}
