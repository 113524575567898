<ng-container *ngIf="prismicContent?.tilkynningar_banner_synilegur === true">
  <div class="alert-banner trs-base" [ngClass]="{ loaded: (loaded | async) }">
    <div class="alert-banner__content">
      <en-prismic-text
        [prismicText]="{ content: this.prismicContent.tilkynning }"
        [addClass]="'alert-colors'"
      ></en-prismic-text>
    </div>
  </div>
</ng-container>
