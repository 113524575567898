import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { checkboxDataList } from './mailing-list';

interface MailChimpResponse {
  result: string;
  msg: string;
}
@Injectable({
  providedIn: 'root',
})
export class MailinglistService {
  mailChimpEndpoint =
    '//endurmenntun.us3.list-manage.com/subscribe/post-json?u=f4b194647590bbe9a96a9a81d&id=1c3f790d40';
  mailChimpId = '1c3f790d40';
  checkboxList = checkboxDataList;

  constructor(private http: HttpClient) {}

  newRegistration(email) {
    let params = new HttpParams().set('EMAIL', email);

    this.checkboxList.forEach(key => {
      if (key.isChecked) {
        params = params.append(key.name, key.value.toString());
      }
    });
    const mailChimpUrl = this.mailChimpEndpoint + '&' + params.toString();

    return this.http.jsonp<MailChimpResponse>(mailChimpUrl, 'c');
  }
}
