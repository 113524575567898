import {
  Component,
  OnInit,
  Inject,
  Output,
  EventEmitter,
  HostBinding,
  ElementRef,
  AfterContentInit,
  AfterViewChecked,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { fromEvent, Observable } from 'rxjs';
import { CategoriesService, Category } from 'src/eduframe';
import {
  throttleTime,
  map,
  pairwise,
  distinctUntilChanged,
  share,
  filter,
} from 'rxjs/operators';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

enum Direction {
  Up = 'Up',
  Down = 'Down',
}

enum VisibilityState {
  Visible = 'visible',
  Hidden = 'hidden',
}

interface Tree<T> {
  id: number;
  name: string;
  node: T;
  parent?: number;
  children?: Tree<T>[];
}

@Component({
  selector: 'en-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.less'],
  animations: [
    trigger('toggle', [
      state(
        VisibilityState.Hidden,
        style({ opacity: 1, transform: 'translateY(-100%)' })
      ),
      state(
        VisibilityState.Visible,
        style({ opacity: 1, transform: 'translateY(0)' })
      ),
      transition('* => hidden', animate('200ms ease-out')),
      transition('* => visible', animate('400ms ease-out')),
    ]),
  ],
})
export class HeaderComponent
  implements OnInit, AfterContentInit, AfterViewChecked
{
  @Output() pushSearchQuery = new EventEmitter<any>();
  public prismicContent: any;
  public prismicNavigation: any;
  public navigationActive = false;
  public searchActive = false;
  private isVisible = true;
  private elHeight: number;
  public loaded: Observable<Boolean>;
  public eduframeCategoriesTree: Tree<Category>[];

  @HostBinding('@toggle')
  get toggle(): VisibilityState {
    return this.isVisible ? VisibilityState.Visible : VisibilityState.Hidden;
  }

  constructor(
    private prismic: PrismicService,
    private el: ElementRef,
    private general: GeneralService,
    private eduframeCategoriesService: CategoriesService,
    @Inject(PLATFORM_ID) public platformId: any,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.getPrismicHeader();
        this.getPrismicNavigation();
        this.getCategories(); // Call the method to fetch categories
      }
    });
  }

  private getPrismicHeader(): void {
    this.prismic
      .getDocumentByType('header')
      .then((data: PrismicBaseInterface) => {
        this.prismicContent = data.results[0].data;
      })
      .then(() => {
        this.loaded = this.general.showContent();
      })
      .catch(err => {
        throw err;
      });
  }

  private getPrismicNavigation(): void {
    this.prismic
      .getDocumentByType('navigation')
      .then((data: PrismicBaseInterface) => {
        this.prismicNavigation = data.results[0].data;
      });
  }

  private getCategories(): void {
    this.eduframeCategoriesService
      .getCategories({ perPage: 500 })
      .subscribe(data => {
        const eduframeCategories = data;

        // Build the tree structure
        this.eduframeCategoriesTree = eduframeCategories
          .filter((category: Category) => category.parent_id === null)
          .map((category: Category) => {
            return {
              id: category.id,
              name: category.name,
              parent: category.parent_id,
              node: category,
              children: eduframeCategories
                .filter(
                  (subCategory: Category) =>
                    subCategory.parent_id === category.id
                )
                .map((subCategory: Category) => {
                  return {
                    id: subCategory.id,
                    name: subCategory.name,
                    parent: subCategory.parent_id,
                  } as Tree<Category>;
                }),
            } as Tree<Category>;
          })
          .filter((category: Tree<Category>) => category.children.length > 0);
      });
  }

  toggleNavigation(close?) {
    if (close && close === 'close') {
      this.navigationActive = false;
      this.document.documentElement.style.cssText = '';
    }

    if (!close && this.navigationActive === false) {
      this.navigationActive = true;
      this.document.documentElement.style.overflowY = 'hidden';
    } else if (!close && this.navigationActive === true) {
      this.navigationActive = false;
      this.document.documentElement.style.cssText = '';
    } else {
      this.navigationActive = false;
      this.document.documentElement.style.cssText = '';
    }
  }

  search($event) {
    this.pushSearchQuery.emit($event);
  }

  activateSearch(state?) {
    if (state) {
      this.searchActive = state;
    } else {
      this.searchActive
        ? (this.searchActive = false)
        : (this.searchActive = true);
    }
  }

  ngAfterContentInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      const scroll$ = fromEvent(window, 'scroll').pipe(
        throttleTime(10),
        map(() => window.pageYOffset),
        pairwise(),
        map(([y1, y2]): Direction => (y2 < y1 ? Direction.Up : Direction.Down)),
        distinctUntilChanged(),
        share()
      );

      const scrollUp$ = scroll$.pipe(
        filter(direction => direction === Direction.Up)
      );

      const scrollDown$ = scroll$.pipe(
        filter(direction => direction === Direction.Down)
      );

      scrollUp$.subscribe(() => {
        this.isVisible = true;
      });

      scrollDown$.subscribe(() => {
        if (window.pageYOffset > 0) {
          this.isVisible = false;
        }
      });
    }
  }

  ngAfterViewChecked(): void {
    this.elHeight = this.el.nativeElement.offsetHeight;
  }
}
