import {
  Component,
  OnInit,
  Inject,
  HostListener,
  AfterViewInit,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
// Models
import { Namskeid } from '../interfaces/namskeid.interface';
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import { FmResponse } from '../interfaces/filemaker-query.interface';
import slugify from 'slugify';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';
import {
  Course,
  CourseWithIncludes,
  PlannedCourseWithIncludes,
} from 'src/eduframe';

interface FilterParameters {
  label_id?: string | Array<string>;
  course_variant_id?: string | Array<string>;
  search?: string;
}

@Component({
  selector: 'en-classes',
  templateUrl: './classes.component.html',
  styleUrls: ['./classes.component.less'],
})
export class ClassesComponent implements OnInit, AfterViewInit {
  @ViewChild('results') searchResults: ElementRef;
  public prismicContent: any;
  public classesError: { error: number };
  public classes: any;
  public allClasses: any[];
  public classesfiltered: Array<{ fieldData: Namskeid }> = [];
  public classesRendered: boolean = false;
  public classesRendering = true;
  // Filtering
  public filterTags: Array<{}> = [];
  public filterSubcat: string;
  public filterActive;
  // infinite scroll
  private scrollTimer: any = 0;
  private lastScrollFireTime = 0;
  public searchActive: boolean = false;
  public prismicLoaded: Observable<boolean>;
  public fmLoaded: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private educationService: FakeMakerService,
    private prismic: PrismicService,
    private meta: MetaDataService,
    private route: ActivatedRoute,
    private general: GeneralService
  ) {
    this.route.queryParams.subscribe(params => {
      this.getClassesByParams(params);
    });
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const minScrollTime = 100;
    const now = new Date().getTime();
    const scrollPosition = window.pageYOffset;
    const windowSize = window.innerHeight;
    const bodyHeight = this.document.body.offsetHeight;
    const lengthFromBottom = Math.max(
      bodyHeight - (scrollPosition + windowSize),
      0
    );

    if (lengthFromBottom <= 1200 && lengthFromBottom >= 1100) {
      if (!this.scrollTimer) {
        if (now - this.lastScrollFireTime > 3 * minScrollTime) {
          this.lastScrollFireTime = now;
          if (Object.keys(this.route.snapshot.queryParams).length !== 0) {
            this.getMoreFilteredClasses(this.route.snapshot.queryParams);
          } else {
            this.getMoreClasses();
          }
        }

        this.scrollTimer = setTimeout(() => {
          this.scrollTimer = null;
          this.lastScrollFireTime = new Date().getTime();
        }, minScrollTime);
      }
    }
  }

  slugifyString(text) {
    return slugify(text, {
      replacement: '-',
      remove: /[*+~.()'"!:@?]/g,
      lower: true,
    });
  }

  public getPrismicContent(type): void {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.prismic
          .getDocumentByType(type)
          .then((data: PrismicBaseInterface) => {
            this.prismicContent = data.results[0].data;
            this.meta.setMetaTags(data.results[0].data);
          })
          .then(() => {
            this.prismicLoaded = this.general.showContent();
          });
      }
    });
  }

  public clearSortingAndFilters(): void {
    this.educationService.clearSortingAndFilters();
  }

  private getClasses() {
    this.classesError = null;
    this.educationService
      .getClasses()
      .then(
        (data: FmResponse) => {
          this.classes = data.response.data;
          this.allClasses = data.response.data;
        },
        err => {
          this.classesError = { error: 401 };
        }
      )
      .then(() => {
        this.fmLoaded = this.general.showContent();
      });
  }

  public getMoreClasses(): void {
    const offset = this.classes.length;
    this.educationService.getClasses(offset.toString()).then(
      (data: FmResponse) => {
        // Check for duplicates before pushing new data
        const newClasses = data.response.data.filter(
          (newClass) => !this.classes.some(existingClass =>
            existingClass.fieldData?.ID === newClass.fieldData?.ID
          )
        );
        this.classes.push(...newClasses);
      },
      err => {
        // TODO: handle error
        console.error(err);
      }
    );
  }

  public getFilteredClasses(params: FilterParameters): void {
    // this.filterActive = true;
    // this.classesError = null;
    // this.educationService.filterCourses('Y0004', params).then(
    //   (data: FmResponse) => {
    //     this.classes = data.response.data;
    //   },
    //   err => {
    //     this.classesError = { error: 401 };
    //   }
    // );
    // Only filter if we have all courses
    if (this.allClasses == undefined) {
      return;
    }

    this.classes = this.allClasses.filter(single_class => {
      // allCourses is Augmented with eduframeCourse and eduframePlannedCourse
      const eduframeCourse = single_class.eduframeCourse as CourseWithIncludes;
      const eduframePlannedCourse =
        single_class.eduframePlannedCourse as PlannedCourseWithIncludes;

      // Filter by label
      if (params.label_id) {
        const label_id = Number(params.label_id);
        return eduframeCourse.label_ids.includes(label_id);
      }

      // Filter by variant
      if (params.course_variant_id) {
        const course_variant_id = Number(params.course_variant_id);
        return eduframePlannedCourse.course_variant_id == course_variant_id;
      }

      // Filter by search
      if (params.search) {
        const search = params.search.toLowerCase();
        return eduframeCourse.name.toLowerCase().includes(search);
      }

      // No filter parameters
      if (!params.label_id && !params.course_variant_id) {
        return true;
      }

      return false;
    });

    // FIXME: This should enable some 'clear filter' button in the ui, but it doesn't work
    this.filterActive = true;

    this.classesError = this.classes.length == 0 ? { error: 404 } : null;
  }

  public getMoreFilteredClasses(params): void {
    const offset = this.classes.length;
    if (this.classes.length > 1) {
      this.educationService.filterCourses('Y0004', params, null, offset).then(
        (data: FmResponse) => {
          // Check for duplicates before pushing new data
          const newClasses = data.response.data.filter(
            (newClass) => !this.classes.some(existingClass =>
              existingClass.fieldData?.ID === newClass.fieldData?.ID
            )
          );
          this.classes.push(...newClasses);
        },
        err => {
          // TODO: handle error
          console.error(err);
        }
      );
    }
  }

  public getClassesByParams(params: FilterParameters) {
    if (Object.keys(params).length) {
      this.getFilteredClasses(params);
    } else {
      this.filterActive = false;
      this.getClasses();
    }
  }

  private scrollResultsIntoView(): void {
    const el = this.searchResults.nativeElement;
    el.scrollIntoView({ behaviour: 'smooth', block: 'start' });
  }

  ngOnInit(): void {
    this.getPrismicContent('namsbrautir');
  }

  ngAfterViewInit(): void {
    let activeParam: string = this.route.snapshot.queryParams.search;
    this.route.queryParams.subscribe(param => {
      // If the query remains the same, don't scroll
      if (activeParam !== param.search) {
        activeParam = param.search;
        this.scrollResultsIntoView();
      }
    });
  }

  getEduframeClassLink(
    singleClass: Course,
    plannedClass: PlannedCourseWithIncludes
  ) {

    return `/namsbrautir/${singleClass.id}/${singleClass.slug}/${plannedClass.id}`;
  }
}
