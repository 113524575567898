import { Component, OnInit } from '@angular/core';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less'],
})
export class FooterComponent implements OnInit {
  public prismicContent: any;
  public phoneNumber: string;

  constructor(private prismic: PrismicService) {}

  ngOnInit() {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.getPrismicContent();
      }
    });
  }

  private getPrismicContent(): void {
    this.prismic
      .getDocumentByType('footer')
      .then((data: PrismicBaseInterface) => {
        this.prismicContent = data.results[0].data;
        this.phoneNumber =
          'tel:+354' + this.prismicContent?.phone_number.replace(/\s/g, '');
      });
  }
}
