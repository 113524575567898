<div class="rendering" [ngClass]="{ rendered: rendering === false }">
  <en-header
    (pushSearchQuery)="toggleSearch($event)"
    #headerElement
    [ngClass]="{ sticky: sticky }"
  ></en-header>
  <div id="main">
    <ng-container *ngIf="headerHeight">
      <div [ngStyle]="{ height: headerHeight + 'px' }"></div>
    </ng-container>

    <ng-container *ngIf="searchInput; else normalContent">
      <en-search-page (clearSearch)="clearSearchResults()"></en-search-page>
    </ng-container>

    <ng-template #normalContent>
      <router-outlet></router-outlet>
    </ng-template>
  </div>
  <en-footer></en-footer>
</div>
