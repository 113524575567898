export var postals = {
  101: '101	Reykjavík',
  102: '102	Reykjavík',
  103: '103	Reykjavík',
  104: '104	Reykjavík',
  105: '105	Reykjavík',
  107: '107	Reykjavík',
  108: '108	Reykjavík',
  109: '109	Reykjavík',
  110: '110	Reykjavík',
  111: '111	Reykjavík',
  112: '112	Reykjavík',
  113: '113	Reykjavík',
  116: '116	Reykjavík',
  161: '161	Reykjavík',
  162: '162	Reykjavík',
  170: '170	Seltjarnarnes',
  190: '190	Vogar',
  191: '191	Vogar',
  200: '200	Kópavogur',
  201: '201	Kópavogur',
  203: '203	Kópavogur',
  206: '206	Kópavogur',
  210: '210	Garðabær',
  220: '220	Hafnarfjörður',
  221: '221	Hafnarfjörður',
  225: '225	Garðabær (Álftanes)',
  230: '230	Reykjanesbær',
  233: '233	Reykjanesbær',
  235: '235	Keflavíkurflugvöllur',
  240: '240	Grindavík',
  241: '241	Grindavík',
  245: '245	Sandgerði',
  246: '246	Sandgerði',
  250: '250	Garður',
  251: '251	Garður',
  260: '260	Reykjanesbær',
  262: '262	Reykjanesbær',
  270: '270	Mosfellsbær',
  271: '271	Mosfellsbær',
  276: '276	Mosfellsbær',
  300: '300	Akranes',
  301: '301	Akranes',
  310: '310	Borgarnes',
  311: '311	Borgarnes',
  320: '320	Reykholt í Borgarfirði',
  340: '340	Stykkishólmur',
  341: '341	Stykkishólmur',
  342: '342	Stykkishólmur',
  345: '345	Flatey á Breiðafirði',
  350: '350	Grundarfjörður',
  351: '351	Grundarfjörður',
  355: '355	Ólafsvík',
  356: '356	Snæfellsbær',
  360: '360	Hellissandur',
  370: '370	Búðardalur',
  371: '371	Búðardalur',
  380: '380	Reykhólahreppur',
  381: '381	Reykhólahreppur',
  400: '400	Ísafjörður',
  401: '401	Ísafjörður',
  410: '410	Hnífsdalur',
  415: '415	Bolungarvík',
  416: '416	Bolungarvík',
  420: '420	Súðavík',
  421: '421	Súðavík',
  425: '425	Flateyri',
  426: '426	Flateyri',
  430: '430	Suðureyri',
  431: '431	Suðureyri',
  450: '450	Patreksfjörður',
  451: '451	Patreksfjörður',
  460: '460	Tálknafjörður',
  461: '461	Tálknafjörður',
  465: '465	Bíldudalur',
  466: '466	Bíldudalur',
  470: '470	Þingeyri',
  471: '471	Þingeyri',
  500: '500	Staður',
  510: '510	Hólmavík',
  511: '511	Hólmavík',
  512: '512	Hólmavík',
  520: '520	Drangsnes',
  524: '524	Árneshreppur',
  530: '530	Hvammstangi',
  531: '531	Hvammstangi',
  540: '540	Blönduós',
  541: '541	Blönduós',
  545: '545	Skagaströnd',
  546: '546	Skagaströnd',
  550: '550	Sauðárkrókur',
  551: '551	Sauðárkrókur',
  560: '560	Varmahlíð',
  561: '561	Varmahlíð',
  565: '565	Hofsós',
  566: '566	Hofsós',
  570: '570	Fljótum',
  580: '580	Siglufjörður',
  581: '581	Siglufjörður',
  600: '600	Akureyri',
  601: '601	Akureyri',
  603: '603	Akureyri',
  604: '604	Akureyri',
  605: '605	Akureyri',
  606: '606	Akureyri',
  607: '607	Akureyri',
  610: '610	Grenivík',
  616: '616	Grenivík',
  611: '611	Grímsey',
  620: '620	Dalvík',
  621: '621	Dalvík',
  625: '625	Ólafsfjörður',
  626: '626	Ólafsfjörður',
  630: '630	Hrísey',
  640: '640	Húsavík',
  641: '641	Húsavík',
  645: '645	Fosshóll',
  650: '650	Laugar',
  660: '660	Mývatn',
  670: '670	Kópasker',
  671: '671	Kópasker',
  675: '675	Raufarhöfn',
  676: '676	Raufarhöfn',
  680: '680	Þórshöfn',
  681: '681	Þórshöfn',
  685: '685	Bakkafjörður',
  686: '686	Bakkafjörður',
  690: '690	Vopnafjörður',
  691: '691	Vopnafjörður',
  700: '700	Egilsstaðir',
  701: '701	Egilsstaðir',
  710: '710	Seyðisfjörður',
  711: '711	Seyðisfjörður',
  715: '715	Mjóifjörður',
  720: '720	Borgarfjörður (eystri)',
  721: '721	Borgarfjörður (eystri)',
  730: '730	Reyðarfjörður',
  731: '731	Reyðarfjörður',
  735: '735	Eskifjörður',
  736: '736	Eskifjörður',
  740: '740	Neskaupstaður',
  741: '741	Neskaupstaður',
  750: '750	Fáskrúðsfjörður',
  751: '751	Fáskrúðsfjörður',
  755: '755	Stöðvarfjörður',
  756: '756	Stöðvarfjörður',
  760: '760	Breiðdalsvík',
  761: '761	Breiðdalsvík',
  765: '765	Djúpivogur',
  766: '766	Djúpivogur',
  780: '780	Höfn í Hornafirði',
  781: '781	Höfn í Hornafirði',
  785: '785	Öræfi',
  800: '800	Selfoss',
  801: '801	Selfoss',
  803: '803	Selfoss',
  804: '804	Selfoss',
  805: '805	Selfoss',
  806: '806	Selfoss',
  810: '810	Hveragerði',
  815: '815	Þorlákshöfn',
  816: '816	Ölfus',
  820: '820	Eyrarbakki',
  825: '825	Stokkseyri',
  840: '840	Laugarvatn',
  845: '845	Flúðir',
  846: '846	Flúðir',
  850: '850	Hella',
  851: '851	Hella',
  860: '860	Hvolsvöllur',
  861: '861	Hvolsvöllur',
  870: '870	Vík',
  871: '871	Vík',
  880: '880	Kirkjubæjarklaustur',
  881: '881	Kirkjubæjarklaustur',
  900: '900	Vestmannaeyjar',
  0: 'Annað - búsett erlendis',
};

export var postalsEN = {
  101: '101	Reykjavík',
  102: '102	Reykjavík',
  103: '103	Reykjavík',
  104: '104	Reykjavík',
  105: '105	Reykjavík',
  107: '107	Reykjavík',
  108: '108	Reykjavík',
  109: '109	Reykjavík',
  110: '110	Reykjavík',
  111: '111	Reykjavík',
  112: '112	Reykjavík',
  113: '113	Reykjavík',
  116: '116	Reykjavík',
  161: '161	Reykjavík',
  162: '162	Reykjavík',
  170: '170	Seltjarnarnes',
  190: '190	Vogar',
  191: '191	Vogar',
  200: '200	Kópavogur',
  201: '201	Kópavogur',
  203: '203	Kópavogur',
  206: '206	Kópavogur',
  210: '210	Garðabær',
  220: '220	Hafnarfjörður',
  221: '221	Hafnarfjörður',
  225: '225	Garðabær (Álftanes)',
  230: '230	Reykjanesbær',
  233: '233	Reykjanesbær',
  235: '235	Keflavíkurflugvöllur',
  240: '240	Grindavík',
  241: '241	Grindavík',
  245: '245	Sandgerði',
  246: '246	Sandgerði',
  250: '250	Garður',
  251: '251	Garður',
  260: '260	Reykjanesbær',
  262: '262	Reykjanesbær',
  270: '270	Mosfellsbær',
  271: '271	Mosfellsbær',
  276: '276	Mosfellsbær',
  300: '300	Akranes',
  301: '301	Akranes',
  310: '310	Borgarnes',
  311: '311	Borgarnes',
  320: '320	Reykholt í Borgarfirði',
  340: '340	Stykkishólmur',
  341: '341	Stykkishólmur',
  342: '342	Stykkishólmur',
  345: '345	Flatey á Breiðafirði',
  350: '350	Grundarfjörður',
  351: '351	Grundarfjörður',
  355: '355	Ólafsvík',
  356: '356	Snæfellsbær',
  360: '360	Hellissandur',
  370: '370	Búðardalur',
  371: '371	Búðardalur',
  380: '380	Reykhólahreppur',
  381: '381	Reykhólahreppur',
  400: '400	Ísafjörður',
  401: '401	Ísafjörður',
  410: '410	Hnífsdalur',
  415: '415	Bolungarvík',
  416: '416	Bolungarvík',
  420: '420	Súðavík',
  421: '421	Súðavík',
  425: '425	Flateyri',
  426: '426	Flateyri',
  430: '430	Suðureyri',
  431: '431	Suðureyri',
  450: '450	Patreksfjörður',
  451: '451	Patreksfjörður',
  460: '460	Tálknafjörður',
  461: '461	Tálknafjörður',
  465: '465	Bíldudalur',
  466: '466	Bíldudalur',
  470: '470	Þingeyri',
  471: '471	Þingeyri',
  500: '500	Staður',
  510: '510	Hólmavík',
  511: '511	Hólmavík',
  512: '512	Hólmavík',
  520: '520	Drangsnes',
  524: '524	Árneshreppur',
  530: '530	Hvammstangi',
  531: '531	Hvammstangi',
  540: '540	Blönduós',
  541: '541	Blönduós',
  545: '545	Skagaströnd',
  546: '546	Skagaströnd',
  550: '550	Sauðárkrókur',
  551: '551	Sauðárkrókur',
  560: '560	Varmahlíð',
  561: '561	Varmahlíð',
  565: '565	Hofsós',
  566: '566	Hofsós',
  570: '570	Fljótum',
  580: '580	Siglufjörður',
  581: '581	Siglufjörður',
  600: '600	Akureyri',
  601: '601	Akureyri',
  603: '603	Akureyri',
  604: '604	Akureyri',
  605: '605	Akureyri',
  606: '606	Akureyri',
  607: '607	Akureyri',
  610: '610	Grenivík',
  616: '616	Grenivík',
  611: '611	Grímsey',
  620: '620	Dalvík',
  621: '621	Dalvík',
  625: '625	Ólafsfjörður',
  626: '626	Ólafsfjörður',
  630: '630	Hrísey',
  640: '640	Húsavík',
  641: '641	Húsavík',
  645: '645	Fosshóll',
  650: '650	Laugar',
  660: '660	Mývatn',
  670: '670	Kópasker',
  671: '671	Kópasker',
  675: '675	Raufarhöfn',
  676: '676	Raufarhöfn',
  680: '680	Þórshöfn',
  681: '681	Þórshöfn',
  685: '685	Bakkafjörður',
  686: '686	Bakkafjörður',
  690: '690	Vopnafjörður',
  691: '691	Vopnafjörður',
  700: '700	Egilsstaðir',
  701: '701	Egilsstaðir',
  710: '710	Seyðisfjörður',
  711: '711	Seyðisfjörður',
  715: '715	Mjóifjörður',
  720: '720	Borgarfjörður (eystri)',
  721: '721	Borgarfjörður (eystri)',
  730: '730	Reyðarfjörður',
  731: '731	Reyðarfjörður',
  735: '735	Eskifjörður',
  736: '736	Eskifjörður',
  740: '740	Neskaupstaður',
  741: '741	Neskaupstaður',
  750: '750	Fáskrúðsfjörður',
  751: '751	Fáskrúðsfjörður',
  755: '755	Stöðvarfjörður',
  756: '756	Stöðvarfjörður',
  760: '760	Breiðdalsvík',
  761: '761	Breiðdalsvík',
  765: '765	Djúpivogur',
  766: '766	Djúpivogur',
  780: '780	Höfn í Hornafirði',
  781: '781	Höfn í Hornafirði',
  785: '785	Öræfi',
  800: '800	Selfoss',
  801: '801	Selfoss',
  803: '803	Selfoss',
  804: '804	Selfoss',
  805: '805	Selfoss',
  806: '806	Selfoss',
  810: '810	Hveragerði',
  815: '815	Þorlákshöfn',
  816: '816	Ölfus',
  820: '820	Eyrarbakki',
  825: '825	Stokkseyri',
  840: '840	Laugarvatn',
  845: '845	Flúðir',
  846: '846	Flúðir',
  850: '850	Hella',
  851: '851	Hella',
  860: '860	Hvolsvöllur',
  861: '861	Hvolsvöllur',
  870: '870	Vík',
  871: '871	Vík',
  880: '880	Kirkjubæjarklaustur',
  881: '881	Kirkjubæjarklaustur',
  900: '900	Vestmannaeyjar',
  0: 'I live abroad',
};
