import { Component, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ContactForm } from '@endurmenntun/_models/forms/contact-form.class';

@Component({
  selector: 'en-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.less'],
})
export class ContactComponent {
  @Output() pushSuccess = new EventEmitter<boolean>();
  public netfangBlur = false;
  public submitted = false;
  public error: boolean = false;
  public errorMessage: string = '';
  public model = new ContactForm('', '', '');

  constructor(private http: HttpClient) {}
  onSubmit(args) {
    args.form.updateValueAndValidity();

    if (args.form.invalid) {
      Object.keys(args.form.controls).forEach(key => {
        args.form.get(key).markAsDirty();
      });
      return;
    } else {
      this.newRegistration();
    }
  }

  newRegistration() {
    this.http.post('/api/form/contact', this.model).subscribe(
      (res: any) => {
        this.submitted = true;
        this.pushSuccess.emit(true);
      },
      err => {
        console.error(err);
        this.error = true;
        this.errorMessage =
          'Villa kom upp í skráningarferlinu. Vinsamlegast hafðu samband við ENDURMENNTUN í síma <a href="tel:5254444">525 4444</a> eða sendu tölvupóst á <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>';
      }
    );
  }
}
