<section class="course-grid">
  <div class="course-container">
    <ng-content></ng-content>
    <div class="course-grid-container wrapper">
      <en-slider
        [type]="'fullSlider'"
        [sliderContent]="courses"
        [offset]="offset"
        [loaded]="loaded"
      ></en-slider>
    </div>
  </div>
</section>
