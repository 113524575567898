import { Component, OnChanges, Input, SimpleChanges } from '@angular/core';
// services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-new-frettir',
  templateUrl: './new-frettir.component.html',
  styleUrls: ['./new-frettir.component.less'],
})
export class NewFrettirComponent implements OnChanges {
  @Input() newsType: any;
  @Input() newsContent: any | null;
  @Input() activeNews?: string;
  @Input() offset? = 1;
  @Input() max? = 100;
  @Input() title?: string;
  public prismicNews: any;

  constructor(private prismic: PrismicService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.newsContent) {
      this.newsContent = null;
      this.prismic.ref.subscribe(ref => {
        if (ref) {
          this.getArticles();
        }
      });
    }
  }

  getArticles(): void {
    this.prismic
      .getArticlesByType(
        this.newsType.includes('frettir') ? 'frett' : 'greinar',
        this.max // This is the second argument for pageSize
      )
      .then((data: PrismicBaseInterface) => {
        this.prismicNews = data.results;
      });
  }
}
