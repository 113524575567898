import { Component, ViewChild, ElementRef, Input } from '@angular/core';
import * as jsPDF from 'jspdf';

@Component({
  selector: 'en-pdf',
  templateUrl: './pdf.component.html',
  styleUrls: ['./pdf.component.less'],
})
export class PdfComponent {
  @Input() filemakerContent?;
  @ViewChild('htmlData') htmlData: ElementRef;

  public USERS: Array<{}> = [
    {
      id: 1,
      name: 'Leanne Graham',
      email: 'sincere@april.biz',
      phone: '1-770-736-8031 x56442',
    },
    {
      id: 2,
      name: 'Ervin Howell',
      email: 'shanna@melissa.tv',
      phone: '010-692-6593 x09125',
    },
  ];

  // openPDF(): void {

  //   const doc = new jsPDF('p', 'pt', 'a4');
  //   const DATA = document.getElementById('htmlData');

  //   const img = new Image();
  //   img.src = '../../../../../assets/Media/logos/Endurmenntun-hi.png';
  //   doc.addImage(img, 'png', 25, 25, 246, 30);

  //   doc.fromHTML(DATA, 25, 80);
  //   // doc.output('dataurlnewwindow');

  // }

  // downloadPDF(): void {

  //   const DATA = this.htmlData.nativeElement;
  //   const doc = new jsPDF('p', 'pt', 'a4');

  //   const handleElement = {
  //     '#editor'(element, renderer) {
  //       return true;
  //     }
  //   };
  //   doc.fromHTML(DATA.innerHTML, 15, 15, {
  //     width: 200,
  //     elementHandlers: handleElement
  //   });

  //   doc.save('endurmenntun.pdf');

  // }
}
