import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Observable } from 'rxjs';
// services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
import { GeneralService } from '@endurmenntun/_services/general/general.service';

@Component({
  selector: 'en-ehi',
  templateUrl: './ehi.component.html',
  styleUrls: ['./ehi.component.less'],
})
export class EhiComponent implements AfterViewInit {
  public prismicContent: any;
  public loaded: Observable<boolean>;

  constructor(
    private prismic: PrismicService,
    private meta: MetaDataService,
    private general: GeneralService
  ) {}

  ngAfterViewInit(): void {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.getPrismicContent();
      }
    });
  }

  getPrismicContent() {
    this.prismic
      .getDocumentByType('um_ehi')
      .then(
        (data: PrismicBaseInterface) => {
          this.prismicContent = data.results[0].data;
          this.meta.setMetaTags(data.results[0].data);
        },
        err => {
          console.error(err);
        }
      )
      .then(() => {
        this.loaded = this.general.showContent();
      });
  }
}
