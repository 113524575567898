import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.less'],
})
export class FormsComponent implements OnInit {
  prismicContent: any;
  formType: string;
  loc = this.location.path().split('/').pop();
  success = false;

  constructor(
    private location: Location,
    private prismic: PrismicService,
    private meta: MetaDataService
  ) {}

  ngOnInit(): void {
    this.getPrismicContent(this.loc);
  }

  getPrismicContent(formType): void {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.prismic
          .getFormByUid(formType)
          .then((data: PrismicBaseInterface) => {
            this.prismicContent = data.results[0].data;
            this.meta.setMetaTags(data.results[0].data);
          });
      }
    });
  }

  triggerSuccess(event) {
    this.success = event;
  }
}
