import { Injectable } from '@angular/core';
import { Namskeid } from '@endurmenntun/_components/pages/education/interfaces/namskeid.interface';

export enum Language {
  Icelandic = 'is',
  English = 'en',
}

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  /** Detect course language */
  static getCourseLanguage(course: Namskeid): Language {

    // If course is not defined, fallback to Icelandic
    if (course?.ID_Yfirflokkur === undefined) {
      return Language.Icelandic;
    }

    // If course has parent category Y0005, it is in English course
    if (course.ID_Yfirflokkur.includes('Y0005')) {
      return Language.English;
    }

    // Otherwise, it is in Icelandic
    return Language.Icelandic;
  }
}
