<ng-container *ngIf="article">
  <div class="article-item">
    <div class="article-item__banner">
      <img
        [src]="article[0]?.data.featured_image.url"
        [alt]="article[0]?.data.featured_image.alt"
        [height]="article[0]?.data.featured_image.dimensions.height"
        [width]="article[0]?.data.featured_image.dimensions.width"
      />
    </div>
    <div class="wrapper">
      <div class="article-item__content">
        <div class="section-header">
          <span class="article-item__content__date">
            {{
              article[0]?.data.article_date | date: 'd. MMMM yyyy':'+0000':'is'
            }}
          </span>
          <span
            class="article-item__content__date article-item__content__date--mobile"
          >
            {{
              article[0]?.data.article_date | date: 'd. MMM yyyy':'+0000':'is'
            }}
          </span>
          <en-overhead-title> Fréttir </en-overhead-title>
          <en-heading
            class="main_item"
            [headingContent]="{
              text: article[0]?.data.title[0].text,
              type: 'heading1',
              lowercase: true,
              large: true
            }"
          ></en-heading>
        </div>
        <div class="article-item__content__text slices">
          <ng-container
            *ngFor="let item of article[0]?.data.body"
            [ngSwitch]="item.slice_type"
          >
            <ng-container *ngSwitchCase="'text_component'">
              <en-prismic-text
                [prismicText]="{ content: item.primary.content }"
              ></en-prismic-text>
            </ng-container>

            <ng-container *ngSwitchCase="'quote'">
              <en-article-quote
                [quote]="{ content: item.primary.quote_content }"
              ></en-article-quote>
            </ng-container>

            <ng-container *ngSwitchCase="'mynd_me__texta'">
              <div
                class="slices__banner-with-caption slice"
                [ngClass]="{ fadein: (loaded | async) }"
              >
                <div
                  class="slices__banner-with-caption__split"
                  [ngClass]="{ right: item.primary.image_position }"
                >
                  <div
                    class="media"
                    [ngClass]="{ 'media--image': item.primary.image.url }"
                  >
                    <img
                      *ngIf="item.primary.image.url"
                      [src]="item.primary.image.url"
                      [alt]="item.primary.image.alt"
                    />
                    <en-youtube-player
                      *ngIf="item.primary.youtube_video_id"
                      [ytUrl]="item.primary.youtube_video_id"
                      [noSpacing]="false"
                    ></en-youtube-player>
                  </div>

                  <div class="content">
                    <div class="content__container">
                      <div class="slices__banner-with-caption__title">
                        <en-heading
                          class="main_item"
                          [headingContent]="{
                            text: item.primary.title,
                            type: 'heading2',
                            lowercase: true,
                            extraSmall: true
                          }"
                        ></en-heading>
                      </div>
                      <en-prismic-text
                        [prismicText]="{ content: item.primary.description }"
                      ></en-prismic-text>

                      <a
                        *ngIf="item.primary.button_link.url"
                        [href]="item.primary.button_link.url"
                      >
                        <en-button
                          [buttonType]="'regular'"
                          [buttonContent]="item.primary.button_label[0].text"
                        ></en-button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'50_50'">
              <div
                class="slices__two-col slice"
                [ngClass]="{ fadein: (loaded | async) }"
              >
                <div class="left">
                  <div class="slices__two-col__title">
                    <en-heading
                      class="main_item"
                      [headingContent]="{
                        text: item.primary.left_title,
                        type: 'heading2',
                        lowercase: true,
                        extraSmall: true
                      }"
                    ></en-heading>
                  </div>
                  <en-prismic-text
                    [prismicText]="{ content: item.primary.left_content }"
                  ></en-prismic-text>
                </div>

                <div class="right">
                  <div class="slices__two-col__title">
                    <en-heading
                      class="main_item"
                      [headingContent]="{
                        text: item.primary.right_title,
                        type: 'heading2',
                        lowercase: true,
                        extraSmall: true
                      }"
                    ></en-heading>
                  </div>
                  <en-prismic-text
                    [prismicText]="{ content: item.primary.right_content }"
                  ></en-prismic-text>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'fyrirsogn_og_texti'">
              <div
                class="slices__title-and-text slice"
                [ngClass]="{ fadein: (loaded | async) }"
              >
                <div class="content">
                  <div class="slices__title-and-text__title">
                    <en-heading
                      class="main_item"
                      [headingContent]="{
                        text: item.primary.title,
                        type: 'heading2',
                        lowercase: true,
                        extraSmall: true
                      }"
                    ></en-heading>
                  </div>
                  <en-prismic-text
                    [prismicText]="{ content: item.primary.content }"
                  ></en-prismic-text>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'33_33_33'">
              <div class="slices__three-col">
                <div class="slices__three-col__col">
                  <div
                    class="slices__title-and-text__title"
                    *ngIf="item.primary.left_title[0]"
                  >
                    <en-heading
                      class="main_item"
                      [headingContent]="{
                        text: item.primary.left_title[0].text,
                        type: 'heading3'
                      }"
                    ></en-heading>
                  </div>
                  <en-prismic-text
                    [prismicText]="{ content: item.primary.left_content }"
                  ></en-prismic-text>
                </div>
                <div class="slices__three-col__col">
                  <div
                    class="slices__title-and-text__title"
                    *ngIf="item.primary.left_title[0]"
                  >
                    <en-heading
                      class="main_item"
                      [headingContent]="{
                        text: item.primary.center_title[0].text,
                        type: 'heading3'
                      }"
                    ></en-heading>
                  </div>
                  <en-prismic-text
                    [prismicText]="{ content: item.primary.center_content }"
                  ></en-prismic-text>
                </div>
                <div class="slices__three-col__col">
                  <div
                    class="slices__title-and-text__title"
                    *ngIf="item.primary.left_title[0]"
                  >
                    <en-heading
                      class="main_item"
                      [headingContent]="{
                        text: item.primary.right_title[0].text,
                        type: 'heading3'
                      }"
                    ></en-heading>
                  </div>
                  <en-prismic-text
                    [prismicText]="{ content: item.primary.right_content }"
                  ></en-prismic-text>
                </div>
              </div>
            </ng-container>

            <ng-container *ngSwitchCase="'accordion'">
              <div class="slices__accordion">
                <ng-container *ngFor="let acc of item.items">
                  <div
                    class="slices__accordion__item"
                    id="{{ acc.accordion_id }}"
                  >
                    <div
                      class="slices__accordion__item__header"
                      [ngClass]="{ active: hash === acc.accordion_id }"
                      (click)="toggleAccordian($event, i)"
                    >
                      <en-heading
                        class="main_item"
                        [headingContent]="{
                          text: acc.title[0].text,
                          type: 'heading3'
                        }"
                      ></en-heading>
                      <span class="slices__accordion__item__header__expand">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="70.24"
                          height="69.703"
                          viewBox="0 0 70.24 69.703"
                        >
                          <g
                            id="plus"
                            transform="translate(-1540.027 -1794.382)"
                          >
                            <g
                              id="chevron"
                              transform="translate(1485.923 1704.923) rotate(45)"
                            >
                              <g
                                id="Group_65"
                                data-name="Group 65"
                                transform="translate(126.513)"
                              >
                                <path
                                  id="Path_52"
                                  data-name="Path 52"
                                  d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                                  transform="translate(-126.513)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                            <g
                              id="chevron-2"
                              data-name="chevron"
                              transform="translate(1664.368 1953.543) rotate(-135)"
                            >
                              <g
                                id="Group_65-2"
                                data-name="Group 65"
                                transform="translate(126.513)"
                              >
                                <path
                                  id="Path_52-2"
                                  data-name="Path 52"
                                  d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                                  transform="translate(-127.22 -0.707)"
                                  fill="#fff"
                                />
                                <path
                                  id="Path_54"
                                  data-name="Path 54"
                                  d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                                  transform="translate(-126.513)"
                                  fill="#fff"
                                />
                              </g>
                            </g>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div
                      class="slices__accordion__item__content"
                      [ngClass]="{ active: hash === acc.accordion_id }"
                    >
                      <div>
                        <en-prismic-text
                          [prismicText]="{ content: acc.content }"
                        ></en-prismic-text>
                      </div>
                    </div>
                  </div>
                </ng-container>
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>

      <en-footer-actions></en-footer-actions>

      <en-new-frettir
        [newsType]="loc"
        [offset]="0"
        [max]="4"
        [activeNews]="article[0] ? article[0].uid : null"
        [title]="'Nýlegar ' + (loc.includes('frettir') ? 'fréttir' : 'greinar')"
      ></en-new-frettir>
    </div>
  </div>
</ng-container>
