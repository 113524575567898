<div class="wrapper">
  <div class="section-header">
    <en-overhead-title>
      {{ this.loc.includes('frettir') ? 'Fréttasafn' : 'Greinasafn' }}
    </en-overhead-title>

    <div class="filter-container">
      <en-heading
        class="main_item"
        [headingContent]="{
          text: this.loc.includes('frettir') ? 'Fréttir' : 'Greinar',
          type: 'heading2'
        }"
      ></en-heading>
      <div class="filter_items">
        <en-heading
          class="filter_item"
          [routerLink]="['/frettir']"
          [ngClass]="{ active: this.loc.includes('frettir') }"
          [headingContent]="{ text: 'Fréttir', type: 'heading3' }"
        >
        </en-heading>
        <!--<en-heading
				class="filter_item"
				[routerLink]="['/greinar']"
				[ngClass]="{'active': this.loc.includes('greinar') }"
				[headingContent]="{text: 'Greinar', type: 'heading3'}">
			</en-heading>-->
      </div>
    </div>
  </div>

  <div class="hero-news" *ngIf="prismicNews">
    <div class="hero-news__left">
      <figure>
        <img
          *ngIf="prismicNews[0].data"
          [src]="prismicNews[0]?.data.featured_image.Featured.url"
          [height]="
            prismicNews[0]?.data.featured_image.Featured.dimensions.height
          "
          [width]="
            prismicNews[0]?.data.featured_image.Featured.dimensions.width
          "
          [alt]="prismicNews[0]?.data.featured_image.Featured.alt"
        />
      </figure>
    </div>

    <div class="hero-news__right">
      <div class="hero-news-content">
        <a href="" [routerLink]="prismicNews[0]?.uid">
          <span class="date" *ngIf="prismicNews[0]?.data.article_date !== ''">{{
            prismicNews[0]?.data.article_date
              | date: 'd. MMMM yyyy':'+0000':'is'
          }}</span>
          <h3>{{ prismicNews[0]?.data.title[0].text }}</h3>
          <p>
            {{
              this.shortText(
                prismicNews[0]?.data.body[0].primary.content[0].text,
                80
              )
            }}
          </p>

          <a [routerLink]="prismicNews[0]?.uid"
            >Lesa meira <img src="../../../assets/Media/icons/arrow-right.png"
          /></a>
        </a>
      </div>
    </div>
  </div>

  <en-new-frettir
    [newsType]="loc"
    [newsContent]="prismicNews"
    [offset]="1"
  ></en-new-frettir>
</div>
