<div class="news-grid-container">
  <h3 *ngIf="title">{{ title }}</h3>

  <div
    class="news-grid"
    *ngIf="newsContent !== null ? newsContent : prismicNews"
  >
    <ng-container
      *ngFor="
        let news of newsContent !== null ? newsContent : prismicNews;
        let i = index
      "
    >
      <ng-container *ngIf="news.uid !== activeNews">
        <en-frett-card
          *ngIf="offset <= i && i - 1 < max"
          [routerLink]="
            '/' +
            (newsType.includes('frettir') ? 'frettir' : 'greinar') +
            '/' +
            news?.uid
          "
          [content]="news"
          [newsType]="newsType"
        >
        </en-frett-card>
      </ng-container>
    </ng-container>
  </div>
</div>
