<footer>
  <div class="footer-container">
    <div class="logo-container">
      <img
        src="../../../assets/Media/logos/Endurmenntun-hi.png"
        alt="Endurmenntun Logo"
        class="logo"
      />
    </div>

    <div class="contact-container">
      <div class="contact-item">
        <a href="https://goo.gl/maps/prhZLYU9UuohffEy5" target="_blank">
          <img src="../../../assets/svg/map-pin.svg" alt="" class="icon" />
          <p>{{ prismicContent?.location }}</p>
        </a>
      </div>

      <div class="contact-item">
        <a [href]="phoneNumber">
          <img src="../../../assets/svg/phone.svg" alt="" class="icon" />
          <p>{{ this.prismicContent?.phone_number }}</p>
        </a>
      </div>

      <div class="contact-item">
        <a [href]="'mailto:' + this.prismicContent?.email">
          <img src="../../../assets/svg/mail.svg" alt="" class="icon" />
          <p>{{ this.prismicContent?.email }}</p>
        </a>
      </div>
    </div>

    <div class="contact-container">
      <div class="contact-item">
        <img src="../../../assets/svg/clock.svg" alt="" class="icon" />
        <p>{{ this.prismicContent?.opening_hours }}</p>
      </div>

      <div class="contact-item">
        <img src="../../../assets/svg/info.svg" alt="" class="icon" />
        <p>Kt. 600169-2039</p>
      </div>
    </div>

    <div class="social-container">
      <div class="social-item">
        <a [href]="prismicContent?.facebook_link" target="_blank">
          <img
            src="../../../assets/svg/facebook.svg"
            alt=""
            class="social-icon"
          />
          <p>- fylgið okkur á facebook</p>
        </a>
      </div>

      <div class="social-item">
        <a routerLink="/postlisti">
          <img
            src="../../../assets/svg/mail-red.svg"
            alt=""
            class="social-icon"
          />
          <p>- skrá mig á póstlista</p>
        </a>
      </div>
    </div>
  </div>
</footer>
