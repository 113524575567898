import { Component, AfterViewInit } from '@angular/core';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.less'],
})
export class StaffComponent implements AfterViewInit {
  public employees: any;

  constructor(private prismic: PrismicService) {}

  ngAfterViewInit(): void {
    this.getEmployees();
  }

  private getEmployees(): void {
    this.prismic.ref.subscribe(ref => {
      if (ref) {
        this.prismic
          .getAllEmployees('employee_title')
          .then((data: PrismicBaseInterface) => {
            this.employees = data.results;
          });
      }
    });
  }
}
