import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
// services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
import { MetaDataService } from '@endurmenntun/_services/meta.service';
// models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';
import { stringify } from '@angular/compiler/src/util';

@Component({
  selector: 'en-frettir',
  templateUrl: './frettir.component.html',
  styleUrls: ['./frettir.component.less'],
})
export class FrettirComponent implements OnInit {
  public prismicNews: any;
  // public prismicNewsPage: any;
  public loc = this.location.path(false);

  constructor(private prismic: PrismicService, private location: Location) {}

  ngOnInit(): void {
    this.getArticles();
  }

  getArticles(): void {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.prismic
          .getArticlesByType(this.loc.includes('frettir') ? 'frett' : 'greinar')
          .then((data: PrismicBaseInterface) => {
            this.prismicNews = data.results;
          });
      }
    });
  }

  public shortText(str: string, words: number = 40): string {
    const shortString = str.split(' ', words);
    return shortString.join(' ') + '...';
  }
}
