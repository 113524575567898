import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

import * as PrismicDOM from 'prismic-dom';

@Component({
  selector: 'en-article-quote',
  templateUrl: './article-quote.component.html',
  styleUrls: ['./article-quote.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class ArticleQuoteComponent implements OnInit {
  @Input() quote;

  htmlText;

  constructor() {}

  ngOnInit(): void {
    if (this.quote.content.length > 0) {
      this.htmlText = PrismicDOM?.RichText.asHtml(this.quote.content);
    }
  }
}
