<div class="form-container" [hidden]="submitted">
  <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
    <div class="form-inputs">
      <div class="form-input-group">
        <label for="name">Nafn</label>
        <input
          type="text"
          id="name"
          required
          [(ngModel)]="model.name"
          name="name"
          #name="ngModel"
        />
        <div class="alert" [hidden]="name.valid || name.pristine">
          Nafn þarf að vera fyllt út
        </div>
      </div>

      <div class="form-input-group">
        <label for="email">Netfang</label>
        <input
          type="text"
          id="email"
          required
          [(ngModel)]="model.email"
          name="email"
          #email="ngModel"
        />

        <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
          <div class="alert" *ngIf="email.errors.required">
            Netfang þarf að vera fyllt út
          </div>
          <div
            class="alert"
            *ngIf="email.errors.pattern && netfangBlur === true"
          >
            Netfang þarf að vera gilt
          </div>
        </ng-container>
      </div>

      <div class="form-input-group fullwidth text">
        <label for="comments">Athugasemdir</label>
        <textarea
          type="text"
          name="message"
          id="message"
          rows="6"
          [(ngModel)]="model.message"
          #message="ngModel"
          maxlength="300"
        ></textarea>
        <div class="max-letters">
          <span>
            <ng-container *ngIf="message?.value && message?.value.length !== 0">
              {{ message.value.length }}
            </ng-container>
          </span>
        </div>
      </div>
    </div>
    <en-button
      [buttonContent]="'Senda'"
      [buttonType]="'regular'"
      [buttonColor]="'red'"
      type="submit"
    ></en-button>
  </form>
</div>

<ng-container *ngIf="submitted">
  <div class="success-container">
    <en-heading
      [headingContent]="{
        type: 'heading3',
        text: 'Takk fyrir að hafa samband!'
      }"
    ></en-heading>
    <p style="font-size: 30px">
      Við munum vera í sambandi við þig eins fljótlega og við getum.
    </p>
    <p>
      Tölvupóstur er sendur út fyrir hvern námskeiðsflokk á tveggja vikna fresti
      og einu sinni í viku fá allir sendan almennan póst. Ef þú skráir þig í
      einn flokk færðu s.s. að jafnaði tvo pósta eina vikuna og einn hina
      vikuna. Ef þú skráir þig í fleiri flokka fjölgar því póstunum sem því
      nemur.
      <br />
      Netfangalisti ENDURMENNTUNAR er ekki afhentur þriðja aðila.
    </p>
  </div>
</ng-container>

<ng-container *ngIf="error && errorMessage">
  <div class="error-container" [innerHTML]="errorMessage"></div>
</ng-container>
