<div class="banner trs-base" [ngClass]="{ loaded: (fmLoaded | async) }">
  <div class="banner__left">
    <div class="title-container">
      <ng-container
        *ngIf="
          type !== 'rafraen-gogn' && !course?.ID_Yfirflokkur.includes('Y0005')
        "
      >
        <h1>
          {{
            course?.Tegund_eydublads === '3' || course?.Tegund_eydublads === '4'
              ? 'Umsókn'
              : 'Skráning'
          }}
          móttekin
        </h1>
      </ng-container>
      <ng-container
        *ngIf="
          type !== 'rafraen-gogn' && course?.ID_Yfirflokkur.includes('Y0005')
        "
      >
        <h1>Thank you for your registration</h1>
      </ng-container>
      <ng-container *ngIf="type === 'rafraen-gogn'">
        <h1>{{ course?.Heiti }}</h1>
        <h5 *ngIf="course?.Undirtitill">
          {{ course?.Undirtitill }}
        </h5>
      </ng-container>
    </div>

    <div class="description">
      <ng-container *ngIf="type !== 'rafraen-gogn'; else namsgogn">
        <div class="description__header">
          <ng-container
            *ngIf="
              this.course?.ID_Yfirflokkur.includes('Y0004');
              else namskeidConfirmation
            "
          >
            <h5>{{ course?.Heiti }}</h5>
            <h6 *ngIf="course?.Undirtitill !== ''">
              {{ course?.Undirtitill }}
            </h6>

            <div class="description__sidebar sidebar">
              <span class="timi" *ngIf="course?.Tegund_eydublads !== '2'">
                <img src="../../../../../assets/svg/credit-card.svg" alt="" />
                <ng-container
                  *ngIf="
                    general.formatEarlyRegistrationDate(
                      this.course?.Lokadags_Skraningar
                    ) && course?.Verd_Snemmskraning > 0;
                    else almenntVerd
                  "
                >
                  <p>
                    {{ course?.Verd_Snemmskraning | number | removeCommaPipe }}
                    ISK ({{
                      course?.ID_Yfirflokkur.includes('Y0005')
                        ? 'registration fee'
                        : 'snemmskráning'
                    }})
                  </p>
                </ng-container>
              </span>
            </div>
            <p>
              Tölvupóstur verður sendur sem staðfesting á móttöku umsóknar. Ef
              þér berst engin staðfesting, vinsamlega hafðu samband við
              ENDURMENNTUN í síma 525 4444 eða sendu tölvupóst á
              <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>.
            </p>
          </ng-container>

          <ng-template #namskeidConfirmation>
            <ng-container *ngIf="course?.ID_Yfirflokkur.includes('Y0005')">
              <p>
                An email has been sent containing information for the
                application. Please contact us via phone +354 525 4444 if
                confirmation does not show up in your email or email us at
                <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>.
              </p>
            </ng-container>
            <ng-container *ngIf="!course?.ID_Yfirflokkur.includes('Y0005')">
              <p>
                Tölvupóstur verður sendur sem staðfesting á móttöku skráningar.
                Ef þér berst engin staðfesting, vinsamlega hafðu samband við
                ENDURMENNTUN í síma 525 4444 eða sendu tölvupóst á
                <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>.
              </p>
            </ng-container>
            <h5>{{ course?.Heiti }}</h5>
            <h6 *ngIf="course?.Undirtitill !== ''">
              {{ course?.Undirtitill }}
            </h6>

            <div class="description__sidebar sidebar">
              <span class="timi" *ngIf="course?.Tegund_eydublads !== '2'">
                <img src="../../../../../assets/svg/credit-card.svg" alt="" />
                <ng-container
                  *ngIf="
                    general.formatEarlyRegistrationDate(
                      this.course?.Lokadags_Skraningar
                    ) &&
                      course?.Verd_Snemmskraning > 0 &&
                      course?.Verd_Snemmskraning != course?.Verd;
                    else almenntVerd
                  "
                >
                  <p>
                    {{ course?.Verd_Snemmskraning | number | removeCommaPipe }}
                    {{
                      course?.ID_Yfirflokkur.includes('Y0005') ? 'ISK' : 'kr.'
                    }}
                    ({{
                      course?.ID_Yfirflokkur.includes('Y0005')
                        ? 'registration fee'
                        : 'snemmskráning'
                    }})
                  </p>
                </ng-container>
                <ng-template #almenntVerd>
                  <p>
                    {{ course?.Verd | number | removeCommaPipe }}
                    {{
                      course?.ID_Yfirflokkur.includes('Y0005') ? 'ISK' : 'kr.'
                    }}
                  </p>
                </ng-template>
              </span>

              <span class="timi" *ngIf="course?.Tegund_eydublads === '2'">
                <img src="../../../../../assets/svg/credit-card.svg" alt="" />
                <p>
                  {{
                    course?.ID_Yfirflokkur.includes('Y0005')
                      ? 'Free'
                      : 'Gjaldfrjálst'
                  }}
                </p>
              </span>

              <span class="timi">
                <img src="../../../../../assets/svg/calendar.svg" alt="" />
                <p [innerHTML]="course?.Timi"></p>
              </span>

              <span class="location">
                <img src="../../../../../assets/svg/map-pin.svg" alt="" />
                <p [innerHTML]="course?.Stadsetning"></p>
              </span>
            </div>
          </ng-template>
        </div>
      </ng-container>

      <ng-template #namsgogn>
        <h5>Námsgögn:</h5>

        <span class="description__files">
          <img src="../../../../../assets/svg/file-text.svg" alt="" />
          <div class="files">
            <a
              *ngFor="let file of gogn"
              [href]="file.fieldData.File"
              download="{{ file.fieldData.Filename_ext }}"
            >
              {{ file.fieldData.Filename }}
            </a>
          </div>
        </span>
        <div class="description__sidebar sidebar">
          <span class="timi">
            <img src="../../../../../assets/svg/calendar.svg" alt="" />
            <p [innerHTML]="course?.Timi"></p>
          </span>

          <span class="teacher">
            <img src="../../../../../assets/svg/user.svg" alt="" />
            <p [innerHTML]="course?.Kennari"></p>
          </span>

          <span class="location">
            <img src="../../../../../assets/svg/map-pin.svg" alt="" />
            <p [innerHTML]="course?.Stadsetning"></p>
          </span>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="banner__right">
    <div class="image-section">
      <figure>
        <img
          src="{{ gallery?.Myndir_Namskeid[0]['Myndir_Namskeid::Mynd'] }}"
          alt=""
        />
      </figure>
    </div>
  </div>
</div>

<en-footer-actions></en-footer-actions>
