<div class="main-navigation">
  <div class="main-navigation__top nav-wrapper">
    <div class="top-menu-container">
      <a (click)="closeNavigation()" class="logo-container">
        <img
          class="logo desktop"
          routerLink="/"
          src="/assets/Media/logos/endurmenntun-logo-hvitt.png"
          [alt]="prismicNavigation?.nav_logo.alt"
        />
        <img
          class="logo mobile"
          src="/assets/Media/logos/endurmenntun-logo-hvitt.png"
          alt=""
        />
      </a>
      <div class="top-menu">
        <div class="top-menu__items">
          <a routerLink="/courses-in-english">
            <span class="courses-in-english" (click)="closeNavigation()">{{
              prismicNavigation?.courses_in_english_label
            }}</span>
          </a>
        </div>

        <span class="close" (click)="closeNavigation()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="49.663"
            height="50"
            viewBox="0 0 49.663 50"
          >
            <g id="Close" transform="translate(-1665.491 -36.494)">
              <g id="chevron" transform="translate(1538.978 36.495)">
                <g
                  id="Group_65"
                  data-name="Group 65"
                  transform="translate(126.513)"
                >
                  <path
                    id="Path_52"
                    data-name="Path 52"
                    d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                    transform="translate(-126.513)"
                    fill="#fff"
                  />
                </g>
              </g>
              <g
                id="chevron-2"
                data-name="chevron"
                transform="translate(1840.959 86.117) rotate(180)"
              >
                <g
                  id="Group_65-2"
                  data-name="Group 65"
                  transform="translate(126.513)"
                >
                  <path
                    id="Path_52-2"
                    data-name="Path 52"
                    d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                    transform="translate(-127.22 -0.707)"
                    fill="#fff"
                  />
                  <path
                    id="Path_54"
                    data-name="Path 54"
                    d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                    transform="translate(-126.513)"
                    fill="#fff"
                  />
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>
    </div>

    <div class="top-main-navigation nav-wrapper">
      <ng-container *ngFor="let category of eduframeCategoriesTree">
        <h2
          [ngClass]="{ active: activeCat === category.id }"
          (click)="activeCat = category.id"
        >
          {{ category.name }}
        </h2>
      </ng-container>

      <h2
        [ngClass]="{ active: activeCat === 'um-okkur' }"
        (click)="activeCat = 'um-okkur'"
      >
        Um okkur
      </h2>

      <h2
        (click)="openExternalLink('https://endurmenntunhi.eduframe.nl/login/eduframe')"
      >
        Mínar síður
      </h2>
    </div>
  </div>

  <div class="main-navigation__body">
    <div class="nav-container main-navigation-body-col nav-wrapper">
      <nav class="nav">
        <div class="nav__wrap">
          <ng-container *ngFor="let parentCategory of eduframeCategoriesTree">
            <ul class="noStyle" *ngIf="activeCat === parentCategory.id">
              <h3
                [routerLink]="'/' + parentCategory.node.slug"
                [routerLinkActive]="'active'"
                (click)="closeNavigation()"
              >
                - {{ parentCategory.name }}
              </h3>

              <ng-container *ngFor="let subCategory of parentCategory.children">
                <li
                  [routerLink]="'/' + parentCategory.node.slug"
                  [routerLinkActive]="'active'"
                  [queryParams]="{ category_id: subCategory.id }"
                  (click)="closeNavigation()"
                >
                  - {{ subCategory.name }}
                </li>
              </ng-container>
            </ul>
          </ng-container>

          <ng-container
            *ngFor="let navigationSection of prismicNavigation?.navigation_body"
          >
            <ul class="noStyle" *ngIf="activeCat === 'um-okkur'">
              <h3
                [ngClass]="{
                  inactive: !navigationSection?.primary.title_link.slug
                }"
                [routerLink]="
                  navigationSection?.primary.title_link
                    ? navigationSection.primary.title_link.slug
                    : null
                "
                [routerLinkActive]="['active']"
                (click)="
                  navigationSection?.primary.title_link.slug
                    ? closeNavigation()
                    : null
                "
              >
                - {{ navigationSection.primary.title_of_navigation_section }}
              </h3>

              <ng-container
                *ngFor="let item of navigationSection.items"
                [ngSwitch]="item.link_to_page.type"
              >
                <li
                  *ngSwitchCase="'alert_page'"
                  [routerLink]="'/alert/' + item.link_to_page.uid"
                  (click)="closeNavigation()"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.item_name }}
                </li>
                <li
                  *ngSwitchCase="'grein'"
                  [routerLink]="'/greinar/' + item.link_to_page.uid"
                  (click)="closeNavigation()"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.item_name }}
                </li>
                <li
                  *ngSwitchCase="'frett'"
                  [routerLink]="'/frettir/' + item.link_to_page.uid"
                  (click)="closeNavigation()"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.item_name }}
                </li>
                <li
                  *ngSwitchCase="'frettir'"
                  [routerLink]="'/frettir'"
                  (click)="closeNavigation()"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.item_name }}
                </li>
                <li
                  *ngSwitchCase="'greinar-sida'"
                  [routerLink]="'/greinar'"
                  (click)="closeNavigation()"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.item_name }}
                </li>
                <li
                  *ngSwitchCase="'um_ehi'"
                  [routerLink]="'/um-okkur'"
                  (click)="closeNavigation()"
                  [routerLinkActive]="'active'"
                  [routerLinkActiveOptions]="{ exact: true }"
                >
                  {{ item.item_name }}
                </li>
                <ng-container *ngSwitchDefault>
                  <ng-container
                    *ngIf="item.link_to_page.link_type === 'Document'"
                  >
                    <li
                      [routerLink]="'/' + item.link_to_page.uid"
                      (click)="closeNavigation()"
                      [routerLinkActive]="'active'"
                      [routerLinkActiveOptions]="{ exact: true }"
                    >
                      {{ item.item_name }}
                    </li>
                  </ng-container>

                  <ng-container *ngIf="item.link_to_page.link_type === 'Web'">
                    <li (click)="closeNavigation()">
                      <a [href]="item.link_to_page.url">
                        {{ item.item_name }}
                      </a>
                    </li>
                  </ng-container>
                </ng-container>
              </ng-container>
            </ul>
          </ng-container>
        </div>
      </nav>
    </div>
  </div>
</div>
