export class Skraningarblad {
  constructor(
    public Namskeid_Id: string,
    public Skjol_Annan_Hatt_texti: string,
    public Athugasemd: string,
    public Farsimi: string,
    public Heimilisfang: string,
    public Kennitala: string,
    public Nafn: string,
    public Netfang: string,
    public Postnumer: string,
    public Skjol_Send_A_Annan_Hatt: string = 'false',
    public Skjol_Gegnum_Fax: string = 'false',
    public Vinnusimi: string,
    public Fjarnam: string = 'false',
    public Samþykkja_skilmala: string = 'false',
    public Postlisti: string,
    public Grada_1?: string,
    public Grada_2?: string,
    public Grada_3?: string,
    public Grada_4?: string,
    public Greidslumati?: 'Kreditkort' | 'Greiðsluseðill' | 'Pei',
    public Heimasimi?: string,
    public Hver_Greidir?: 'Þátttakandi' | 'Annar en þátttakandi',
    public LN_Netfang?: string,
    public Medfylgjandi_Skjol?: Array<string>,
    public Nuverandi_Starf?: string,
    public Serstok_Athugasemd?: string,
    public Skoli_1?: string,
    public Skoli_2?: string,
    public Skoli_3?: string,
    public Skoli_4?: string,
    public Skraningarnumer?: number,
    public Skyringartexti?: string,
    public Starfsheiti_1?: string,
    public Starfsheiti_2?: string,
    public Starfsheiti_3?: string,
    public Starfslysing_1?: string,
    public Starfslysing_2?: string,
    public Starfslysing_3?: string,
    public Stadur?: string,
    public Tilvisun?: string,
    public Timabil_1?: string,
    public Timabil_2?: string,
    public Timabil_3?: string,
    public Utskr_Ar_1?: string,
    public Utskr_Ar_2?: string,
    public Utskr_Ar_3?: string,
    public Utskr_Ar_4?: string,
    public Vegna?: string,
    public Verd?: string,
    public Vinnustadur?: string,
    public Vinnustadur_1?: string,
    public Vinnustadur_2?: string,
    public Vinnustadur_3?: string,
    public Heimildarnumer?: string,
    public Greidandi_Berist_Til?: string,
    public Heimilisfang_Greidanda?: string,
    public Kennitala_Greidanda?: string,
    public Kennitala_Vinnustadur?: string,
    public Nafn_Greidanda?: string,
    public Poststod_Vinnuveitanda?: string,
    public Postnumer_Vinnuveitanda?: string,
    public Postnumer_Greidandi?: string,
    public Stadur_Greidandi?: string,
    public Netfang_Greidanda?: string,
    public Netfang_Mottakandi_Reiknings?: string,
    public Payed?: string,
    public KortaStatusCode?: string,
    public Kortategund?: string,
    public Greidandi?: string,
    public Rafraenskjol?: string,
    public DOB?: string,
    public GSM_numer_FYR?: string,
  ) {}
}
