<a [routerLink]="[data?.ID_Yfirflokkur === 'Y0004' ? getCourseLink('namsbrautir', data) : getCourseLink('namskeid', data)]">
  <div class="container trs-short-delay" [ngClass]="{ loaded: (loaded | async) }">
    <span
      class="course-type"
      [ngClass]="{ namsbraut: data?.ID_Yfirflokkur === 'Y0004' }"
    >
      <ng-container *ngIf="data?.ID_Yfirflokkur === 'Y0004'; else namskeid">
        Námsbraut
      </ng-container>

      <ng-template #namskeid>
        <ng-container
          *ngIf="
            data?.Fjarkennsla_ === 'true' &&
            (data?.Fjarkennsla === 'já' || data?.Fjarkennsla === 'Já')
          "
        >
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English">Online</ng-container>
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Fjarnámskeið</ng-container
            >
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            data?.Fjarkennsla_ === 'true' &&
            (data?.Fjarkennsla === 'Bæði' || data?.Fjarkennsla === 'bæði')
          "
        >
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English"
              >In classroom & online</ng-container
            >
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Stað- og fjarnámskeið</ng-container
            >
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            data?.Fjarkennsla_ === 'false' &&
            (data?.Fjarkennsla === 'nei' || data?.Fjarkennsla === 'Nei')
          "
        >
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English"
              >In classroom</ng-container
            >
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Staðnámskeið</ng-container
            >
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            data?.Fjarkennsla_ === 'vidburdur' &&
            data?.Fjarkennsla === 'Viðburður'
          "
        >
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English">Event</ng-container>
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Viðburður</ng-container
            >
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="data?.Fjarkennsla_ === 'prof' && data?.Fjarkennsla === 'Próf'"
        >
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English">Exam</ng-container>
            <ng-container *ngSwitchCase="Language.Icelandic">Próf</ng-container>
          </ng-container>
        </ng-container>
        <ng-container
          *ngIf="data?.Fjarkennsla_ === 'false' && data?.Fjarkennsla === ''"
        >
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English">Course</ng-container>
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Námskeið</ng-container
            >
          </ng-container>
        </ng-container>
      </ng-template>
    </span>

    <div class="overlay" [ngClass]="{ inactive: state === 'inactive' }"></div>
    <figure>
      <img
        [src]="
          gallery.Myndir_Namskeid.length > 0
            ? gallery?.Myndir_Namskeid[0]['Myndir_Namskeid']
            : '/assets/Media/Default-image.png'
        "
        alt=""
        loading="lazy"
      />
    </figure>

    <div class="card-content">
      <div class="mobile-info"></div>
      <div class="main">
        <div class="header">
          <div class="top-header"></div>
          <div class="main-header">
            <h2>{{ data.Heiti }}</h2>
            <span class="text-under">{{ data.Undirtitill }}</span>
          </div>
        </div>

        <div class="info">
          <span class="date">
            <img src="/assets/svg/calendar.svg" alt="Dagsetning Námskeiðs" loading="lazy" />
            {{ date }}
            <span
              *ngIf="
                data?.Endar_Dags && data?.Byrjar_Dags !== data?.Endar_Dags
              "
              >&nbsp;- {{ lastDate }}</span
            >
          </span>
          <span class="length" *ngIf="data.Lengd">
            <img src="/assets/svg/clock.svg" alt="Tími námskeiðs" />
            <ng-container [ngSwitch]="this.language">
              <ng-container *ngSwitchCase="Language.English"
                >{{ data.Lengd }} hours</ng-container
              >
              <ng-container *ngSwitchCase="Language.Icelandic"
                >{{ data.Lengd }} klst.</ng-container
              >
            </ng-container>
          </span>
          <span
            class="teacher fade title-hover"
            attr.data-title="{{ data.Kennari }}"
            *ngIf="data.Kennari && !data.ID_Yfirflokkur.includes('Y0004')"
          >
            <img src="/assets/svg/user.svg" alt="Kennarar námskeiðs" />
            <p [innerHTML]="data.Kennari"></p>
          </span>
          <span class="class-type" *ngIf="data?.ID_Yfirflokkur.includes('Y0004')">
            <img src="/assets/svg/info.svg" alt="Flokkun" />

            <ng-container *ngIf="data.ID_Undirflokkur === 'U0014'">
              Nám án eininga
            </ng-container>

            <ng-container *ngIf="data.ID_Undirflokkur === 'U0013'">
              Nám á framhaldsstigi háskóla
            </ng-container>

            <ng-container *ngIf="data.ID_Undirflokkur === 'U0012'">
              Nám á grunnstigi háskóla
            </ng-container>
          </span>

          <ng-container *ngIf="data?.ID_Yfirflokkur.includes('Y0004')">
            <span
              class="remote"
              *ngIf="
                data?.Fjarkennsla_ === 'true' &&
                (data?.Fjarkennsla === 'já' || data?.Fjarkennsla === 'Já')
              "
            >
              <img src="/assets/svg/video.svg" alt="Fjarnám" />

              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >Online</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Fjarnám</ng-container
                >
              </ng-container>
            </span>
            <span
              class="remote"
              *ngIf="
                data?.Fjarkennsla_ === 'false' &&
                (data?.Fjarkennsla === 'nei' || data?.Fjarkennsla === 'Nei')
              "
            >
              <img src="/assets/svg/home.svg" alt="Staðnám" />

              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >In classroom</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Staðnám</ng-container
                >
              </ng-container>
            </span>
            <span
              class="remote"
              *ngIf="
                data?.Fjarkennsla_ === 'true' &&
                (data?.Fjarkennsla === 'Bæði' || data?.Fjarkennsla === 'bæði')
              "
            >
              <img src="/assets/svg/both.svg" alt="Stað- og fjarnám" />

              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >In classroom & online</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Stað- og fjarnám</ng-container
                >
              </ng-container>
            </span>
          </ng-container>
        </div>
      </div>

      <div class="footer">
        <div *ngIf="this.seats === 0" class="bidlisti">
          <p>Biðlisti</p>
        </div>

        <span class="price" *ngIf="data.Verd && data.Tegund_eydublads !== '2'">
          {{ data.Verd | number | removeCommaPipe }}
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English">ISK</ng-container>
            <ng-container *ngSwitchCase="Language.Icelandic">kr.</ng-container>
          </ng-container>
        </span>

        <span class="price" *ngIf="data.Verd === 0">
          <ng-container [ngSwitch]="this.language">
            <ng-container *ngSwitchCase="Language.English">Free</ng-container>
            <ng-container *ngSwitchCase="Language.Icelandic"
              >Gjaldfrjálst</ng-container
            >
          </ng-container>
        </span>

        <ng-container>
          <span
            *ngIf="data.Text_Snemmskraning"
            class="text-under ng-star-inserted"
          >
            {{ data.Text_Snemmskraning }}
          </span>
        </ng-container>

        <ng-container
          *ngIf="
            data?.Lokadags_Skraningar &&
            general.formatEarlyRegistrationDate(data?.Lokadags_Skraningar)
          "
        >
          <span class="text-under">
            <ng-container
              *ngIf="
                data.ID_Yfirflokkur === 'Y0004' ||
                  (data.ID_Yfirflokkur === 'Y0003' &&
                    data.Tegund_eydublads === '3') ||
                  data.Tegund_eydublads === '4';
                else notApplication
              "
            >
              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >Registration ends</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Umsóknarfrestur til og með</ng-container
                >
              </ng-container>
            </ng-container>

            <ng-template #notApplication>
              <ng-container
                *ngIf="has_early_registration; else notEarlyRegistration"
              >
                <ng-container [ngSwitch]="this.language">
                  <ng-container *ngSwitchCase="Language.English"
                    >Early registration ends</ng-container
                  >
                  <ng-container *ngSwitchCase="Language.Icelandic"
                    >Snemmskráningu lýkur</ng-container
                  >
                </ng-container>
              </ng-container>

              <ng-template #notEarlyRegistration>
                <ng-container [ngSwitch]="this.language">
                  <ng-container *ngSwitchCase="Language.English"
                    >Registration ends</ng-container
                  >
                  <ng-container *ngSwitchCase="Language.Icelandic"
                    >Skráningu lýkur</ng-container
                  >
                </ng-container>
              </ng-template>
            </ng-template>
          </span>
        </ng-container>
      </div>
    </div>

    <ng-container *ngIf="data?.ID_Yfirflokkur === 'Y0004'; else NamskeidButton">
      <button class="card-button namsbraut">
        <ng-container [ngSwitch]="this.language">
          <ng-container *ngSwitchCase="Language.English">More</ng-container>
          <ng-container *ngSwitchCase="Language.Icelandic">Nánar</ng-container>
        </ng-container>
      </button>
    </ng-container>

    <ng-template #NamskeidButton>
      <button class="card-button">
        <ng-container [ngSwitch]="this.language">
          <ng-container *ngSwitchCase="Language.English">More</ng-container>
          <ng-container *ngSwitchCase="Language.Icelandic">Nánar</ng-container>
        </ng-container>
      </button>
    </ng-template>
  </div>
</a>
