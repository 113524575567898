import {
  Component,
  OnInit,
  AfterViewInit,
  Output,
  EventEmitter,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { Location } from '@angular/common';
import { GeneralService } from '@endurmenntun/_services/general/general.service';
import { CategoriesService, Category } from 'src/eduframe';

interface Tree<T> {
  id: number;
  name: string;
  node: T;
  parent?: number;
  children?: Tree<T>[];
}

@Component({
  selector: 'en-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.less'],
})
export class NavigationComponent implements OnInit, AfterViewInit {
  @Input() prismicNavigation;
  @Input() eduframeCategoriesTree: Tree<Category>[];
  @Output() closeNav = new EventEmitter();
  public activeCat: string | number;
  private isDataLoaded: boolean = false;

  constructor(
    private location: Location,
    public general: GeneralService,
    private eduframeCategoriesService: CategoriesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.getCategories();
  }

  ngAfterViewInit(): void {
    this.setActiveCategory();
  }

  public closeNavigation(): void {
    this.closeNav.emit('close');
  }

  private getCategories(): void {
    this.eduframeCategoriesService
      .getCategories({ perPage: 500 })
      .subscribe(data => {
        const eduframeCategories = data;

        // Build the tree structure
        this.eduframeCategoriesTree = eduframeCategories
          .filter((category: Category) => category.parent_id === null)
          .map((category: Category) => {
            return {
              id: category.id,
              name: category.name,
              parent: category.parent_id,
              node: category,
              children: eduframeCategories
                .filter(
                  (subCategory: Category) =>
                    subCategory.parent_id === category.id
                )
                .map((subCategory: Category) => {
                  return {
                    id: subCategory.id,
                    name: subCategory.name,
                    parent: subCategory.parent_id,
                  } as Tree<Category>;
                }),
            } as Tree<Category>;
          })
          .filter((category: Tree<Category>) => category.children.length > 0);

        this.isDataLoaded = true;

        this.setActiveCategory();
        this.cdr.detectChanges();
      });
  }

  private setActiveCategory(): void {
    if (
      this.isDataLoaded &&
      this.eduframeCategoriesTree &&
      this.eduframeCategoriesTree.length > 0
    ) {
      this.activeCat = this.eduframeCategoriesTree[0]?.id || 'um-okkur';
      this.cdr.detectChanges();
    }
  }

  openExternalLink(url: string): void {
    window.open(url, '_blank');
  }
}
