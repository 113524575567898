<div
  class="form-component trs-base wrapper"
  [ngClass]="{ loaded: (fmLoaded | async) }"
>
  <div class="form-component__left">
    <div class="form-component-container">
      <ng-container
        *ngIf="
          !course?.ID_Yfirflokkur.includes('Y0004') &&
          course?.Fjarkennsla_ === 'true' &&
          course?.Fjarkennsla === 'já'
        "
      >
        <div
          class="remote-label"
          [ngClass]="{ namsbraut: course?.ID_Yfirflokkur === 'Y0004' }"
        >
          {{
            course.ID_Yfirflokkur.includes('Y0005') ? 'Online' : 'Fjarnámskeið'
          }}
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !course?.ID_Yfirflokkur.includes('Y0004') &&
          course?.Fjarkennsla_ === 'false' &&
          course?.Fjarkennsla === 'nei'
        "
      >
        <div
          class="remote-label"
          [ngClass]="{ namsbraut: course?.ID_Yfirflokkur === 'Y0004' }"
        >
          {{
            course.ID_Yfirflokkur.includes('Y0005')
              ? 'In classroom'
              : 'Staðnámskeið'
          }}
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !course?.ID_Yfirflokkur.includes('Y0004') &&
          course?.Fjarkennsla_ === 'true' &&
          course?.Fjarkennsla === 'Bæði'
        "
      >
        <div
          class="remote-label"
          [ngClass]="{ namsbraut: course?.ID_Yfirflokkur === 'Y0004' }"
        >
          {{
            course.ID_Yfirflokkur.includes('Y0005')
              ? 'In classroom & online'
              : 'Stað- og fjarnámskeið'
          }}
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !course?.ID_Yfirflokkur.includes('Y0004') &&
          course?.Fjarkennsla_ === 'vidburdur' &&
          course?.Fjarkennsla === 'Viðburður'
        "
      >
        <div
          class="remote-label"
          [ngClass]="{ namsbraut: course?.ID_Yfirflokkur === 'Y0004' }"
        >
          {{ course.ID_Yfirflokkur.includes('Y0005') ? 'Event' : 'Viðburður' }}
        </div>
      </ng-container>
      <ng-container
        *ngIf="
          !course?.ID_Yfirflokkur.includes('Y0004') &&
          course?.Fjarkennsla_ === 'prof' &&
          course?.Fjarkennsla === 'Próf'
        "
      >
        <div
          class="remote-label"
          [ngClass]="{ namsbraut: course?.ID_Yfirflokkur === 'Y0004' }"
        >
          {{ course.ID_Yfirflokkur.includes('Y0005') ? 'Exam' : 'Próf' }}
        </div>
      </ng-container>
      <h1>{{ course?.Heiti }}</h1>

      <h5 *ngIf="course?.Undirtitill">
        {{ course?.Undirtitill }}
      </h5>

      <div class="namskeid_information">
        <div class="namskeid_information__left">
          <ng-container
            *ngIf="
              course?.Lokadags_Skraningar &&
              general.formatEarlyRegistrationDate(
                this.course?.Lokadags_Skraningar
              )
            "
          >
            <ng-container
              *ngIf="
                course?.ID_Yfirflokkur.includes('Y0005');
                else snemmskraning
              "
            >
              <span class="snemmskraning primary-red">
                <img src="/assets/svg/info.svg" alt="" />
                Last day of
                {{ hasEarlyRegistrationEnabled(course) ? '' : '' }}
                registration is
                {{
                  general.formatEarlyRegistrationDate(
                    course?.Lokadags_Skraningar,
                    null,
                    'en-US',
                    'MMMM',
                    true
                  )
                }}
              </span>
            </ng-container>

            <ng-template #snemmskraning>
              <ng-container
                *ngIf="
                  course?.ID_Yfirflokkur === 'Y0004' ||
                    (course.ID_Yfirflokkur === 'Y0003' &&
                      course.Tegund_eydublads === '3') ||
                    course.Tegund_eydublads === '4';
                  else earlyRegNamskeid
                "
              >
                <span class="snemmskraning secondary">
                  <img src="/assets/svg/info.svg" alt="" />
                  Umsóknarfrestur til og með
                  {{
                    general.formatEarlyRegistrationDate(
                      this.course?.Lokadags_Skraningar,
                      true
                    )
                  }}
                </span>
              </ng-container>
              <ng-template #earlyRegNamskeid>
                <span class="snemmskraning">
                  <img
                    src="/assets/svg/info.svg"
                    *ngIf="course?.Verd_Snemmskraning != course?.Verd"
                    alt=""
                  />
                  {{
                    hasEarlyRegistrationEnabled(course)
                      ? 'Snemmskráning'
                      : 'Skráning'
                  }}
                  til og með
                  {{
                    general.formatEarlyRegistrationDate(
                      this.course?.Lokadags_Skraningar
                    )
                  }}
                </span>
              </ng-template>
            </ng-template>
          </ng-container>

          <ng-container
            *ngIf="
              general.formatEarlyRegistrationDate(
                course?.Lokadags_Skraningar
              ) &&
                course?.Verd_Snemmskraning > 0 &&
                course?.Verd_Snemmskraning != course?.Verd;
              else almenntVerd
            "
          >
            <span class="full_price" *ngIf="course?.Verd">
              {{
                course?.ID_Yfirflokkur.includes('Y0005')
                  ? 'Standard registration fee'
                  : 'Almennt verð'
              }}
              {{ course?.Verd | number | removeCommaPipe }}
              {{ course?.ID_Yfirflokkur.includes('Y0005') ? 'ISK' : 'kr.' }}
            </span>
            <span class="price" *ngIf="course?.Verd_Snemmskraning">
              {{ course?.Verd_Snemmskraning | number | removeCommaPipe }}
              {{ course?.ID_Yfirflokkur.includes('Y0005') ? 'ISK' : 'kr.' }}
            </span>
          </ng-container>

          <ng-template #almenntVerd>
            <span class="full_price" *ngIf="course?.Verd">
              {{ course?.ID_Yfirflokkur.includes('Y0005') ? 'Price' : 'Verð' }}
            </span>
            <span class="price" *ngIf="course?.Tegund_eydublads !== '2'">
              {{ course?.Verd | number | removeCommaPipe }}
              {{ course?.ID_Yfirflokkur.includes('Y0005') ? 'ISK' : 'kr.' }}
            </span>
          </ng-template>

          <span class="price" *ngIf="course?.Tegund_eydublads === '2'">
            Gjaldfrjálst
          </span>
        </div>
      </div>

      <div class="description">
        <div class="description__sidebar sidebar">
          <span class="timi">
            <img src="/assets/svg/calendar.svg" alt="" />
            <p [innerHTML]="course?.Timi"></p>
          </span>

          <ng-container *ngIf="course?.ID_Yfirflokkur.includes('Y0004')">
            <span
              class="remote"
              *ngIf="
                course?.Fjarkennsla_ === 'true' && course?.Fjarkennsla === 'já'
              "
            >
              <img src="/assets/svg/video.svg" alt="Fjarnám" />
              <p>
                {{
                  course.ID_Yfirflokkur.includes('Y0005') ? 'Online' : 'Fjarnám'
                }}
              </p>
            </span>
            <span
              class="remote"
              *ngIf="
                course?.Fjarkennsla_ === 'false' &&
                course?.Fjarkennsla === 'nei'
              "
            >
              <img src="/assets/svg/home.svg" alt="Staðnám" />
              <p>
                {{
                  course.ID_Yfirflokkur.includes('Y0005')
                    ? 'In classroom'
                    : 'Staðnám'
                }}
              </p>
            </span>
            <span
              class="remote"
              *ngIf="
                course?.Fjarkennsla_ === 'true' &&
                course?.Fjarkennsla === 'Bæði'
              "
            >
              <img src="/assets/svg/both.svg" alt="Stað- og fjarnám" />
              <p>
                {{
                  course.ID_Yfirflokkur.includes('Y0005')
                    ? 'In classroom & online'
                    : 'Stað- og fjarnám'
                }}
              </p>
            </span>
          </ng-container>

          <span class="lengd">
            <img src="/assets/svg/clock.svg" alt="" />
            <p>
              {{ course?.Lengd }}
              {{ course?.ID_Yfirflokkur.includes('Y0005') ? 'hours' : 'klst.' }}
            </p>
          </span>

          <span class="teacher">
            <img src="/assets/svg/user.svg" alt="" />
            <p [innerHTML]="course?.Kennari"></p>
          </span>

          <span class="location">
            <img src="/assets/svg/map-pin.svg" alt="" />
            <p [innerHTML]="course?.Stadsetning"></p>
          </span>

          <span class="course-id">
            <img src="/assets/svg/hash.svg" alt="Númer námskeiðs" />
            <p [innerHTML]="course?.Namskeid_Id"></p>
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="form-component__right">
    <en-form
      [namskeidId]="namskeidId"
      [registrationType]="registrationType"
      [formType]="''"
      [course]="course"
    ></en-form>
  </div>
</div>
<en-footer-actions
  [courseContent]="course"
  [calendarDetails]="{
    title: course?.Heiti,
    description: course?.Kynning,
    location: course?.Stadsetning,
    start: course?.Byrjar_Dags_,
    end: course?.Endar_Dags_
  }"
></en-footer-actions>

<section class="more-courses">
  <en-course-grid>
    <div class="section-header wrapper">
      <en-overhead-title> Menntun fyrir alla </en-overhead-title>

      <div class="filter-container">
        <en-heading
          class="main-item"
          [headingContent]="{ text: 'Önnur námskeið', type: 'heading2' }"
        ></en-heading>
      </div>
    </div>
  </en-course-grid>
</section>
