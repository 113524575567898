<div class="section-header wrapper">
  <en-overhead-title>
    Leit <span style="margin-left: 4px"> "{{ searchQuery }}"</span>
  </en-overhead-title>

  <div class="filter-container">
    <en-heading
      class="main_item"
      [headingContent]="{ text: 'Niðurstöður', type: 'heading2' }"
    ></en-heading>
  </div>
</div>

<!-- prismic api -->
<!-- Management -->
<section
  class="wrapper search-page_component"
  *ngIf="isOnSearchResultsPage && managementData"
>
  <h3 *ngIf="managementData.length > 0">Stjórn</h3>
  <ng-container *ngFor="let listItem of managementData; let i = index">
    <div>
      <a [routerLink]="'/um-okkur/stjornin'">
        <div class="query-item">
          <h3>{{ listItem.text }}</h3>
        </div>
      </a>
    </div>
  </ng-container>
</section>

<!-- Staff -->
<section
  class="wrapper search-page_component"
  *ngIf="isOnSearchResultsPage && prismicStaff"
>
  <h3 *ngIf="prismicStaff.length > 0">Starfsfólk</h3>
  <ng-container *ngFor="let staff of prismicStaff; let i = index">
    <div>
      <a [routerLink]="'/um-okkur/starfsfolk'">
        <div class="query-item">
          <h3>{{ staff.data.employee_name[0].text }}</h3>
        </div>
      </a>
    </div>
  </ng-container>
</section>

<!-- news -->
<section
  class="wrapper search-page_component"
  *ngIf="isOnSearchResultsPage && prismicNews"
>
  <h3 *ngIf="prismicNews.length > 0">Fréttir</h3>
  <ng-container *ngFor="let news of prismicNews; let i = index">
    <div>
      <a [routerLink]="'/' + 'frettir' + '/' + news?.uid">
        <div class="query-item">
          <h3>{{ news.data.title[0].text }}</h3>
          <p>
            {{ news.data.article_date }}
          </p>
        </div>
      </a>
    </div>
  </ng-container>
</section>
<!-- Fislausnir -->
<!-- Courses -->
<section class="search-page_component wrapper">
  <ng-container *ngIf="results">
    <div class="results-grid">
      <a
        [routerLink]="
          course.fieldData.ID_Yfirflokkur.includes('Y0003')
            ? '/namskeid/' +
              course.fieldData.Namskeid_Id +
              '/' +
              slugifyString(course.fieldData.Heiti)
            : '/namsbrautir/' +
              course.fieldData.Namskeid_Id +
              '/' +
              slugifyString(course.fieldData.Heiti)
        "
        *ngFor="let course of results"
      >
        <en-course-card [cont]="course"></en-course-card>
      </a>
    </div>
  </ng-container>

  <ng-container *ngIf="loading">
    <div class="searching">
      <h1>Leitar...</h1>
    </div>
  </ng-container>

  <ng-container *ngIf="nothingFound">
    <div class="error">
      <h1>
        Ekkert námskeið fannst fyrir leitarskilyrðið "{{ searchQuery }}",
        vinsamlegast tryggðu að ekki séu stafsetningarvillur í leitarskilyrðinu.
      </h1>
    </div>
  </ng-container>
</section>
