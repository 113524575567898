<form (ngSubmit)="onSubmit(skraningarForm)" #skraningarForm="ngForm">
  <div class="form-inputs">
    <div class="form-input-group">
      <label for="nafn">Nafn <span class="must-fill">*</span></label>
      <input
        type="text"
        id="nafn"
        required
        [(ngModel)]="model.Nafn"
        name="nafn"
        #name="ngModel"
        autocomplete="name"
      />
      <div class="alert" [hidden]="name.valid || name.pristine">
        Nafn þarf að vera fyllt út
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (kennitala.invalid &&
            kennitala.errors.required &&
            kennitala.touched) ||
          (kennitala.invalid && kennitalaBlur && kennitala.errors.minlength)
      }"
    >
      <label for="nafn">Kennitala <span class="must-fill">*</span></label>
      <input
        type="text"
        id="kennitala"
        required
        enValidKennitala
        [(ngModel)]="model.Kennitala"
        name="kennitala"
        #kennitala="ngModel"
        minlength="10"
        maxlength="10"
        (paste)="onPaste($event)"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="kennitalaBlur = true"
        autocomplete="off"
      />

      <ng-container
        *ngIf="kennitala.invalid && (kennitala.dirty || kennitala.touched)"
      >
        <div class="alert" *ngIf="kennitala.control.errors.required">
          Kennitala þarf að vera fyllt út
        </div>
        <div
          class="alert"
          *ngIf="kennitala.control.errors.minlength && kennitalaBlur === true"
        >
          Kennitala þarf að vera 10 stafir
        </div>
        <div
          class="alert"
          *ngIf="
            kennitala.control.errors.invalidKennitala && kennitalaBlur === true
          "
        >
          {{
            lang === 'is' || !lang
              ? 'Þessi kennitala er ekki gild.'
              : 'This personal ID is not valid.'
          }}
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="nafn">Heimilisfang <span class="must-fill">*</span></label>
      <input
        type="text"
        id="heimilisfang"
        required
        [(ngModel)]="model.Heimilisfang"
        name="heimilisfang"
        #heimilisfang="ngModel"
        autocomplete="street-address"
      />
      <div class="alert" [hidden]="heimilisfang.valid || heimilisfang.pristine">
        Heimilisfang þarf að vera fyllt út
      </div>
    </div>

    <div class="form-input-group">
      <label for="nafn">Póstnúmer <span class="must-fill">*</span></label>
      <select
        name="postnumer"
        id="postnumer"
        required
        [(ngModel)]="model.Postnumer"
        #postnumer="ngModel"
        autocomplete="postal-code"
      >
        <option disabled selected value>-- Veldu póstnúmer --</option>
        <ng-container *ngFor="let postalCode of postalCodes">
          <option [value]="getOnlyPostal(postalCode)">{{ postalCode }}</option>
        </ng-container>
      </select>
      <div class="alert" [hidden]="postnumer.valid || postnumer.pristine">
        Póstnúmer þarf að vera fyllt út
      </div>
    </div>

    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (farsimi.invalid && farsimi.errors.required && farsimi.touched) ||
          (farsimi.invalid && farsimiBlur && farsimi.errors.minlength)
      }"
    >
      <label for="nafn">Farsími <span class="must-fill">*</span></label>
      <input
        type="text"
        id="farsimi"
        required
        [(ngModel)]="model.Farsimi"
        name="farsimi"
        #farsimi="ngModel"
        minlength="7"
        maxlength="7"
        autocomplete="tel"
        onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))"
        (blur)="farsimiBlur = true"
        (paste)="onPhoneNumberFromClipboard($event)"
      />

      <ng-container
        *ngIf="farsimi.invalid && (farsimi.dirty || farsimi.touched)"
      >
        <div class="alert" *ngIf="farsimi.control.errors.required">
          Farsími þarf að vera fyllt út
        </div>
        <div
          class="alert"
          *ngIf="farsimi.control.errors.minlength && farsimiBlur === true"
        >
          Farsími þarf að vera 7 stafir
        </div>
      </ng-container>
    </div>
  </div>
  <div class="form-inputs">
    <div
      class="form-input-group"
      [ngClass]="{
        error:
          (netfang.invalid && netfang.errors.required && netfang.touched) ||
          (netfang.invalid && netfangBlur && netfang.errors.minlength)
      }"
    >
      <label for="nafn">Netfang <span class="must-fill">*</span></label>
      <input
        type="email"
        id="netfang"
        required
        [(ngModel)]="model.Netfang"
        name="netfang"
        #netfang="ngModel"
        autocomplete="email"
        (ngModelChange)="model.Netfang = $event.toLowerCase()"
        pattern="^(.+@.+\..+)$"
        (blur)="netfangBlur = true"
      />

      <ng-container
        *ngIf="netfang.invalid && (netfang.dirty || netfang.touched)"
      >
        <div class="alert" *ngIf="netfang.errors.required">
          Netfang þarf að vera fyllt út
        </div>
        <div
          class="alert"
          *ngIf="netfang.errors.pattern && netfangBlur === true"
        >
          Netfang þarf að vera gilt
        </div>
      </ng-container>
    </div>

    <div class="form-input-group">
      <label for="netfangStadfest">
        Endurtaka netfang <span class="must-fill">*</span>
      </label>
      <input
        type="email"
        id="netfangStadfest"
        required
        name="netfangStadfest"
        #netfangStadfest="ngModel"
        [(ngModel)]="netfangEndurtekid"
        (ngModelChange)="netfangEndurtekid = $event.toLowerCase()"
        pattern="{{ netfang.value }}"
      />
      <ng-container
        *ngIf="
          netfangStadfest.invalid &&
          (netfangStadfest.dirty || netfangStadfest.touched)
        "
      >
        <div class="alert" *ngIf="netfangStadfest.errors.required">
          Vinsamlegast endurtaktu netfangið
        </div>
        <div class="alert" *ngIf="netfangStadfest.errors.pattern">
          Netföng passa ekki
        </div>
      </ng-container>
    </div>
  </div>

  <div class="form-input-group fullwidth checkbox">
    <div class="checkbox-wrapper">
      <div class="checkmark-container">
        <input
          type="checkbox"
          id="postlisti"
          [value]="'true'"
          [(ngModel)]="model.Postlisti"
          name="postlisti"
          #postlisti="ngModel"
        />
        <div class="checkmark"></div>
      </div>

      <label for="nafn"
        >Já, takk. Ég vil fá senda tölvupósta um námskeið og námsbrautir
        Endurmenntunar - skrá mig á póstlista.</label
      >
    </div>
  </div>

  <div class="form-info teaching-material">
    <div class="form-info__content">
      <p>
        Eftir að umsókn hefur verið samþykkt er rafrænt inntökubréf sent til
        umsækjanda.
      </p>
      <p>
        <a [routerLink]="['/greidslufyrirkomulag']" target="_blank"
          >Upplýsingar um greiðslufyrirkomulag</a
        >
      </p>
    </div>
  </div>

  <div class="form-input-group fullwidth text">
    <label for="nafn">Athugasemdir</label>
    <textarea
      type="text"
      id="athugasemdir"
      rows="6"
      [(ngModel)]="model.Athugasemd"
      name="athugasemdir"
      #athugasemdir="ngModel"
      maxlength="300"
    ></textarea>
    <div class="max-letters">
      <span>
        <ng-container
          *ngIf="athugasemdir?.value && athugasemdir?.value.length !== 0"
        >
          {{ athugasemdir.value.length }}
        </ng-container>
      </span>
      <span>Hámark 300 stafabil</span>
    </div>
  </div>

  <ng-container *ngIf="disclaimer !== ''">
    <div class="form-info terms-and-conditions">
      <h2>Skilmálar og fyrirvari</h2>
      <div class="form-info__content">
        <p [innerHTML]="disclaimer"></p>
      </div>

      <div class="form-input-group fullwidth checkbox">
        <div class="checkbox-wrapper">
          <div class="checkmark-container">
            <input
              type="checkbox"
              id="skilmalar"
              required
              [value]="'true'"
              name="skilmalar"
              [(ngModel)]="model['Samþykkja_skilmala']"
              #skilmalar="ngModel"
            />
            <div class="checkmark"></div>
          </div>
          <label for="skilmalar">Ég samþykki ofangreinda skilmála</label>
        </div>
        <div
          class="alert"
          [hidden]="
            skilmalar.valid || skilmalar.pristine || skilmalar.value === 'true'
          "
        >
          Vinsamlega samþykktu skilmála
        </div>
      </div>
    </div>
  </ng-container>

  <div class="form-info teaching-material">
    <div class="form-info__content">
      <p>
        Tölvupóstur verður sendur sem staðfesting á móttöku umsóknar. Ef þér
        berst engin staðfesting, vinsamlega hafðu samband við ENDURMENNTUN í
        síma 525 4444 eða sendu tölvupóst á
        <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
      </p>
    </div>
  </div>

  <en-button
    [buttonContent]="'Staðfesta'"
    [buttonType]="'regular'"
    [buttonColor]="'red'"
    type="submit"
  ></en-button>
  <ng-container *ngIf="reqProcessing"> Vinn úr skráningu... </ng-container>

  <div class="alert" [hidden]="formValid || reqProcessing">
    <ng-container *ngIf="reqProcessing === false">
      Villa kom upp í skráningu, vinsamlegast fylgdu villumeldingum hér fyrir
      ofan
    </ng-container>
  </div>
  <div class="alert" [hidden]="!registerStudentError">
    <ng-container *ngIf="registerStudentError && registerStudentError.status">
      Villa kom upp í skráningarferlinu. Vinsamlegast hafðu samband við
      ENDURMENNTUN í síma <a href="tel:5254444">525 4444</a> eða sendu tölvupóst
      á <a href="mailto:endurmenntun@hi.is">endurmenntun@hi.is</a>
    </ng-container>
  </div>
</form>
