<div class="wrapper custom-page">
  <div class="section-header">
    <en-heading
      class="main_item"
      [headingContent]="{
        text: prismicContent?.alert_title[0].text,
        type: 'heading1',
        lowercase: false,
        large: false
      }"
    ></en-heading>
  </div>

  <ng-container
    *ngIf="prismicContent && prismicContent.alert_content.length > 0"
  >
    <div class="custom-page__content">
      <en-prismic-text
        [prismicText]="{ content: prismicContent?.alert_content }"
      ></en-prismic-text>
    </div>
  </ng-container>

  <!-- Slices -->

  <div class="slices" *ngIf="prismicContent?.body.length">
    <ng-container
      *ngFor="let slice of prismicContent.body"
      [ngSwitch]="slice.slice_type"
    >
      <ng-container *ngSwitchCase="'banner_with_caption'">
        <div
          class="slices__banner-with-caption slice"
          [ngClass]="{ fadein: (loaded | async) }"
        >
          <div
            class="slices__banner-with-caption__split"
            [ngClass]="{ right: slice.primary.image_position }"
          >
            <div
              class="media"
              [ngClass]="{ 'media--image': slice.primary.image.url }"
            >
              <img
                *ngIf="slice.primary.image.url"
                [src]="slice.primary.image.url"
                [alt]="slice.primary.image.alt"
              />
              <en-youtube-player
                *ngIf="slice.primary.youtube_video_id"
                [ytUrl]="slice.primary.youtube_video_id"
                [noSpacing]="false"
              ></en-youtube-player>
            </div>

            <div class="content">
              <div class="content__container">
                <div class="slices__banner-with-caption__title">
                  <en-heading
                    class="main_item"
                    [headingContent]="{
                      text: slice.primary.title,
                      type: 'heading2',
                      lowercase: true,
                      extraSmall: true
                    }"
                  ></en-heading>
                </div>
                <en-prismic-text
                  [prismicText]="{ content: slice.primary.description }"
                ></en-prismic-text>

                <a
                  *ngIf="slice.primary.button_link.url"
                  [href]="slice.primary.button_link.url"
                >
                  <en-button
                    [buttonType]="'regular'"
                    [buttonContent]="slice.primary.button_label[0].text"
                  ></en-button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'50_50'">
        <div
          class="slices__two-col slice"
          [ngClass]="{ fadein: (loaded | async) }"
        >
          <div class="left">
            <div class="slices__two-col__title">
              <en-heading
                class="main_item"
                [headingContent]="{
                  text: slice.primary.left_title,
                  type: 'heading2',
                  lowercase: true,
                  extraSmall: true
                }"
              ></en-heading>
            </div>
            <en-prismic-text
              [prismicText]="{ content: slice.primary.left_content }"
            ></en-prismic-text>
          </div>

          <div class="right">
            <div class="slices__two-col__title">
              <en-heading
                class="main_item"
                [headingContent]="{
                  text: slice.primary.right_title,
                  type: 'heading2',
                  lowercase: true,
                  extraSmall: true
                }"
              ></en-heading>
            </div>
            <en-prismic-text
              [prismicText]="{ content: slice.primary.right_content }"
            ></en-prismic-text>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'fyrirsogn_og_texti'">
        <div
          class="slices__title-and-text slice"
          [ngClass]="{ fadein: (loaded | async) }"
        >
          <div class="content">
            <div class="slices__title-and-text__title">
              <en-heading
                class="main_item"
                [headingContent]="{
                  text: slice.primary.title,
                  type: 'heading2',
                  lowercase: true,
                  extraSmall: true
                }"
              ></en-heading>
            </div>
            <en-prismic-text
              [prismicText]="{ content: slice.primary.content }"
            ></en-prismic-text>
          </div>
        </div>
      </ng-container>

      <ng-container *ngSwitchCase="'33_33_33'">
        <div class="slices__three-col">
          <div class="slices__three-col__col">
            <div
              class="slices__title-and-text__title"
              *ngIf="slice.primary.left_title[0]"
            >
              <en-heading
                class="main_item"
                [headingContent]="{
                  text: slice.primary.left_title[0].text,
                  type: 'heading3'
                }"
              ></en-heading>
            </div>
            <en-prismic-text
              [prismicText]="{ content: slice.primary.left_content }"
            ></en-prismic-text>
          </div>
          <div class="slices__three-col__col">
            <div
              class="slices__title-and-text__title"
              *ngIf="slice.primary.left_title[0]"
            >
              <en-heading
                class="main_item"
                [headingContent]="{
                  text: slice.primary.center_title[0].text,
                  type: 'heading3'
                }"
              ></en-heading>
            </div>
            <en-prismic-text
              [prismicText]="{ content: slice.primary.center_content }"
            ></en-prismic-text>
          </div>
          <div class="slices__three-col__col">
            <div
              class="slices__title-and-text__title"
              *ngIf="slice.primary.left_title[0]"
            >
              <en-heading
                class="main_item"
                [headingContent]="{
                  text: slice.primary.right_title[0].text,
                  type: 'heading3'
                }"
              ></en-heading>
            </div>
            <en-prismic-text
              [prismicText]="{ content: slice.primary.right_content }"
            ></en-prismic-text>
          </div>
        </div>
      </ng-container>
      <span id="copied"></span>
      <ng-container *ngSwitchCase="'accordion'">
        <div class="slices__accordion">
          <ng-container *ngFor="let acc of slice.items">
            <div class="slices__accordion__item" id="{{ acc.accordion_id }}">
              <div
                class="slices__accordion__item__header"
                [ngClass]="{ active: hash === acc.accordion_id }"
                (click)="toggleAccordian($event, i)"
              >
                <div class="anchor-flex">
                  <svg
                    (click)="makeAnchorLink(acc.accordion_id)"
                    id="copy-link"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    fill="#000000"
                    viewBox="0 0 256 256"
                  >
                    <path
                      d="M216,32H88a8,8,0,0,0-8,8V80H40a8,8,0,0,0-8,8V216a8,8,0,0,0,8,8H168a8,8,0,0,0,8-8V176h40a8,8,0,0,0,8-8V40A8,8,0,0,0,216,32ZM160,208H48V96H160Zm48-48H176V88a8,8,0,0,0-8-8H96V48H208Z"
                    ></path>
                  </svg>
                  <en-heading
                    [attr.id]="makeId(acc.title[0].text)"
                    class="main_item"
                    [headingContent]="{
                      text: acc.title[0].text,
                      type: 'heading3'
                    }"
                  ></en-heading>
                </div>

                <span class="slices__accordion__item__header__expand">
                  <svg
                    class="expand-button"
                    xmlns="http://www.w3.org/2000/svg"
                    width="70.24"
                    height="69.703"
                    viewBox="0 0 70.24 69.703"
                  >
                    <g id="plus" transform="translate(-1540.027 -1794.382)">
                      <g
                        id="chevron"
                        transform="translate(1485.923 1704.923) rotate(45)"
                      >
                        <g
                          id="Group_65"
                          data-name="Group 65"
                          transform="translate(126.513)"
                        >
                          <path
                            id="Path_52"
                            data-name="Path 52"
                            d="M150.091,20.858,129.849.573a1.953,1.953,0,0,0-2.765,2.759l20.244,20.286a1.956,1.956,0,0,1,0,2.764L127.084,46.667a1.953,1.953,0,1,0,2.765,2.759L150.09,29.143a5.866,5.866,0,0,0,0-8.285Z"
                            transform="translate(-126.513)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                      <g
                        id="chevron-2"
                        data-name="chevron"
                        transform="translate(1664.368 1953.543) rotate(-135)"
                      >
                        <g
                          id="Group_65-2"
                          data-name="Group 65"
                          transform="translate(126.513)"
                        >
                          <path
                            id="Path_52-2"
                            data-name="Path 52"
                            d="M152.482,21.269l-25.4,25.4a1.953,1.953,0,1,0,2.765,2.759l23.07-23.112C155.2,24.03,153.245,20.506,152.482,21.269Z"
                            transform="translate(-127.22 -0.707)"
                            fill="#fff"
                          />
                          <path
                            id="Path_54"
                            data-name="Path 54"
                            d="M147.263,18.029,129.849.573a1.953,1.953,0,0,0-2.765,2.759L144.5,20.79C146.467,22.758,149.546,20.313,147.263,18.029Z"
                            transform="translate(-126.513)"
                            fill="#fff"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </span>
              </div>
              <div
                class="slices__accordion__item__content"
                [ngClass]="{ active: hash === acc.accordion_id }"
              >
                <div>
                  <en-prismic-text
                    [prismicText]="{ content: acc.content }"
                  ></en-prismic-text>
                </div>
              </div>
            </div>

            <p class="hide">Afrita hlekk!</p>
          </ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
