<section class="hero" [ngClass]="herobannerType">
  <div
    class="content-section trs-hero-content"
    [ngClass]="{ loaded: (loaded | async) }"
  >
    <div class="content-container">
      <ng-container *ngIf="contentType === 'prismic'">
        <en-overhead-title *ngIf="heroBannerContent?.hb_overhead_title[0].text">
          {{ heroBannerContent?.hb_overhead_title[0].text }}
        </en-overhead-title>
      </ng-container>

      <ng-container
        *ngIf="
          contentType === 'prismic' &&
            heroBannerContent &&
            heroBannerContent.hb_title[0];
          else filemakerTitle
        "
      >
        <en-heading
          [headingContent]="heroBannerContent.hb_title[0]"
        ></en-heading>
      </ng-container>

      <ng-template #filemakerTitle>
        <div class="herobanner-title-wrapper">
          <ng-container
            *ngIf="
              !heroBannerContent?.ID_Yfirflokkur.includes('Y0004') &&
              heroBannerContent?.Fjarkennsla_ === 'true' &&
              (heroBannerContent?.Fjarkennsla === 'já' ||
                heroBannerContent?.Fjarkennsla === 'Já')
            "
          >
            <div
              class="herobanner-remote-label"
              [ngClass]="{
                namsbraut: heroBannerContent?.ID_Yfirflokkur === 'Y0004'
              }"
            >
              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >Online</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Fjarnámskeið</ng-container
                >
              </ng-container>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !heroBannerContent?.ID_Yfirflokkur.includes('Y0004') &&
              heroBannerContent?.Fjarkennsla_ === 'false' &&
              (heroBannerContent?.Fjarkennsla === 'nei' ||
                heroBannerContent?.Fjarkennsla === 'Nei')
            "
          >
            <div
              class="herobanner-remote-label"
              [ngClass]="{
                namsbraut: heroBannerContent?.ID_Yfirflokkur === 'Y0004'
              }"
            >
              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >In classroom</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Staðnámskeið</ng-container
                >
              </ng-container>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !heroBannerContent?.ID_Yfirflokkur.includes('Y0004') &&
              heroBannerContent?.Fjarkennsla_ === 'true' &&
              (heroBannerContent?.Fjarkennsla === 'Bæði' ||
                heroBannerContent?.Fjarkennsla === 'bæði')
            "
          >
            <div
              class="herobanner-remote-label"
              [ngClass]="{
                namsbraut: heroBannerContent?.ID_Yfirflokkur === 'Y0004'
              }"
            >
              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >In classroom & online</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Stað- og fjarnámskeið</ng-container
                >
              </ng-container>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !heroBannerContent?.ID_Yfirflokkur.includes('Y0004') &&
              heroBannerContent?.Fjarkennsla_ === 'vidburdur' &&
              (heroBannerContent?.Fjarkennsla === 'Viðburður' ||
                heroBannerContent?.Fjarkennsla === 'viðburður')
            "
          >
            <div
              class="herobanner-remote-label"
              [ngClass]="{
                namsbraut: heroBannerContent?.ID_Yfirflokkur === 'Y0004'
              }"
            >
              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >Event</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Viðburður</ng-container
                >
              </ng-container>
            </div>
          </ng-container>
          <ng-container
            *ngIf="
              !heroBannerContent?.ID_Yfirflokkur.includes('Y0004') &&
              heroBannerContent?.Fjarkennsla_ === 'prof' &&
              (heroBannerContent?.Fjarkennsla === 'Próf' ||
                heroBannerContent?.Fjarkennsla === 'próf')
            "
          >
            <div
              class="herobanner-remote-label"
              [ngClass]="{
                namsbraut: heroBannerContent?.ID_Yfirflokkur === 'Y0004'
              }"
            >
              <ng-container [ngSwitch]="this.language">
                <ng-container *ngSwitchCase="Language.English"
                  >Exam</ng-container
                >
                <ng-container *ngSwitchCase="Language.Icelandic"
                  >Próf</ng-container
                >
              </ng-container>
            </div>
          </ng-container>
          <h1>{{ heroBannerContent?.Heiti }}</h1>
          <h5 *ngIf="heroBannerContent?.Undirtitill">
            {{ heroBannerContent?.Undirtitill }}
          </h5>
        </div>

        <ng-container
          *ngIf="
            heroBannerContent?.ID_Yfirflokkur === 'Y0004' &&
            heroBannerContent?.Verd === 0
          "
        >
          <div class="more-info-later">
            <p>
              Verð og nánari upplýsingar um námið er væntanlegt og þá verður
              hægt að sækja um.
            </p>
          </div>
        </ng-container>
      </ng-template>

      <p
        class="text-under"
        *ngIf="
          contentType === 'prismic' && heroBannerContent?.hb_content[0].text
        "
      >
        {{ heroBannerContent.hb_content[0].text }}
      </p>

      <ng-container *ngIf="herobannerType === 'frontpage'">
        <en-search (searchQuery)="search($event)" [viewAll]="false"></en-search>
      </ng-container>

      <div
        class="namskeid_information"
        *ngIf="
          contentType === 'filemaker' &&
          heroBannerContent?.Fjoldi_laus_saeti !== 'Upplýsing'
        "
      >
        <div
          class="namskeid_information__right"
          *ngIf="heroBannerContent?.Fjoldi_laus_saeti !== 'Upplýsing'"
        >
          <div class="container">
            <span
              class="primary-red"
              [ngClass]="{
                blue: heroBannerContent?.ID_Yfirflokkur.includes('Y0004')
              }"
            >
              <ng-container
                *ngIf="
                  seats === 0 ||
                    heroBannerContent?.Fjoldi_laus_saeti === 'Fullt' ||
                    heroBannerContent?.Fjoldi_laus_saeti === 'Stopp';
                  else numberOfSeats
                "
              >
                <ng-container
                  *ngIf="
                    heroBannerContent?.ID_Yfirflokkur.includes('Y0005');
                    else fullbokad
                  "
                >
                  This course is fully booked, <br />
                  Waiting list by phone
                  <a href="tel:+3545254444" style="text-decoration: underline"
                    >525 4444</a
                  >
                </ng-container>

                <ng-template #fullbokad>
                  <ng-container
                    *ngIf="
                      heroBannerContent?.Fjoldi_laus_saeti === 'Stopp' ||
                      this.general.formatSeats(
                        heroBannerContent?.Fjoldi_laus_saeti
                      ) === 0
                    "
                  >
                    <br />Fullbókað - Biðlisti í síma
                    <a
                      href="tel:+3545254444"
                      [ngClass]="{
                        secondary:
                          heroBannerContent?.ID_Yfirflokkur.includes('Y0004')
                      }"
                      style="text-decoration: underline"
                      >525 4444</a
                    >
                  </ng-container>
                </ng-template>
                <ng-container
                  *ngIf="
                    heroBannerContent?.Fjoldi_laus_saeti === 'Fullt';
                    else numberOfSeats
                  "
                >
                  <br />Fullbókað
                </ng-container>
              </ng-container>

              <ng-template #numberOfSeats>
                <ng-container
                  *ngIf="seats <= 7 && seats !== null && seats !== 0"
                >
                  {{
                    heroBannerContent?.ID_Yfirflokkur.includes('Y0005')
                      ? 'Only ' + seats + ' spots open'
                      : 'Aðeins ' +
                        seats +
                        ' sæti laus' +
                        (seats === 1 ? 't' : '')
                  }}
                </ng-container>
              </ng-template>
            </span>

            <ng-container
              *ngIf="
                heroBannerContent?.Fjoldi_laus_saeti !== 'Fullt' &&
                heroBannerContent?.Fjoldi_laus_saeti !== 'Loka' &&
                heroBannerContent?.Fjoldi_laus_saeti !== 'Stopp' &&
                heroBannerContent?.Fjoldi_laus_saeti !== '0' &&
                heroBannerContent?.Fjoldi_laus_saeti !== 0 &&
                !this.general.checkIfPast(
                  heroBannerContent?.Byrjar_Dags,
                  heroBannerContent?.Hefst_KL
                )
              "
            >
              <ng-container *ngIf="signupUrl">
                <en-button
                  *ngIf="
                    general.ifSeatsAndPriceNotEmpty(
                      heroBannerContent?.Fjoldi_laus_saeti,
                      heroBannerContent?.Verd
                    )
                  "
                  (click)="openSignupUrl()"
                  [buttonContent]="
                    heroBannerContent?.Tegund_eydublads === '3' ||
                    heroBannerContent?.Tegund_eydublads === '4'
                      ? 'Sækja um'
                      : 'Skrá mig'
                  "
                  [buttonType]="'regular'"
                  [buttonColor]="color"
                >
                </en-button>
              </ng-container>
              <ng-template #englishRegistrationButton>
                <en-button
                  [routerLink]="'/skraning/' + heroBannerContent?.Namskeid_Id"
                  [buttonContent]="'Registration'"
                  [buttonType]="'regular'"
                  [buttonColor]="color"
                >
                </en-button>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="image-section trs-hero-img"
    [ngClass]="{ loaded: (loaded | async) }"
  >
    <!-- <span
      class="info"
      [ngClass]="{ blue: heroBannerContent?.ID_Yfirflokkur === 'Y0004' }"
      *ngIf="started === true"
    >
      {{
        heroBannerContent?.ID_Yfirflokkur.includes('Y0005')
          ? 'In Progress'
          : 'Í gangi'
      }}
    </span> -->
    <figure>
      <img
        *ngIf="contentType === 'prismic'"
        src="{{ heroBannerContent?.hb_background_img.url }}"
        alt="{{ heroBannerContent?.hb_background_img.alt }}"
      />
      <ng-container *ngIf="contentType === 'filemaker'">
        <en-slider
          [type]="'imgSlider'"
          [sliderContent]="heroBannerGallery?.Myndir_Namskeid"
        ></en-slider>
      </ng-container>
    </figure>
  </div>
</section>
