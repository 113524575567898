<div class="form-container" [hidden]="submitted">
  <form (ngSubmit)="onSubmit(contactForm)" #contactForm="ngForm">
    <div class="form-inputs">
      <div class="form-input-group">
        <label for="email">Netfang</label>
        <input
          type="text"
          id="mce-EMAIL"
          required
          [(ngModel)]="model.EMAIL"
          name="email"
          #email="ngModel"
        />

        <ng-container *ngIf="email.invalid && (email.dirty || email.touched)">
          <div class="alert" *ngIf="email.errors.required">
            Netfang þarf að vera fyllt út
          </div>
          <div
            class="alert"
            *ngIf="email.errors.pattern && netfangBlur === true"
          >
            Netfang þarf að vera gilt
          </div>
        </ng-container>
      </div>

      <div style="margin-bottom: 20px">
        <p>
          Með því að skrá þig á póstlista samþykkir þú að fá senda reglubundna
          markpósta.
        </p>
      </div>
    </div>
    <en-button
      [buttonContent]="'Skrá mig'"
      [buttonType]="'regular'"
      [buttonColor]="'red'"
      type="submit"
    ></en-button>
  </form>
</div>

<ng-container *ngIf="submitted">
  <div class="success-container">
    <en-heading
      [headingContent]="{
        type: 'heading3',
        text: 'Takk fyrir að skrá þig á póstlistann!'
      }"
    ></en-heading>
    <p style="font-size: 30px">
      Vinsamlegast smelltu á hlekkinn í tölvupóstinum sem við sendum þér til að
      klára skráningarferlið. það gæti verið að pósturinn hafi farið í Spam- eða
      ruslhólf þannig ekki gleyma að kíkja þar.
    </p>
    <p>
      Með því að skrá þig á póstlista samþykkir þú að fá senda reglubundna
      markpósta.
    </p>
  </div>
</ng-container>
<ng-container *ngIf="error && errorMessage">
  <div class="error-container" [innerHTML]="errorMessage"></div>
</ng-container>
