import { Component, OnInit } from '@angular/core';
// Services
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';
// Models
import { PrismicBaseInterface } from '@endurmenntun/_models/prismic/prismic-base.interface';

@Component({
  selector: 'en-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.less'],
})
export class NotFoundComponent implements OnInit {
  prismicContent: any;

  constructor(private prismic: PrismicService) {}

  ngOnInit(): void {
    this.getPrismicContent();
  }

  getPrismicContent() {
    this.prismic.ref.subscribe(data => {
      if (data) {
        this.prismic
          .getDocumentByType('404')
          .then((data: PrismicBaseInterface) => {
            this.prismicContent = data.results[0].data;
          });
      }
    });
  }
}
