import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
// Services
import { RegistrationPaymentService } from '@endurmenntun/_services/registration-payment/registration-payment.service';
import { MailinglistService } from '@endurmenntun/_services/mailchimp/mailinglist.service';
// Models
import { Skraningarblad } from '@endurmenntun/_models/forms/form.class';
import { LanguageService } from '@endurmenntun/_services/language.service';
import { FakeMakerService } from '@endurmenntun/fake-maker/fake-maker.service';

interface Request {
  model: Skraningarblad;
  files?: any[];
}

@Component({
  selector: 'en-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.less'],
})
export class FormComponent {
  @Input() formType;
  @Input() namskeidId;
  @Input() registrationType;
  @Input() course;

  // handling
  public registrationError: any;
  public proccessingRequest: boolean = false;

  constructor(
    private router: Router,
    private registrationPayment: RegistrationPaymentService,
    private mailingList: MailinglistService,
    private educationService: FakeMakerService
  ) {}

  newRegistration(request: Request) {
    request.model.Samþykkja_skilmala =
      request.model.Samþykkja_skilmala.toString();
    request.model.Fjarnam = request.model.Fjarnam.toString();
    request.model.Postlisti = request.model.Postlisti.toString();
    this.proccessingRequest = true;

    this.educationService
      .registerCourse(request.model, request.files ? request.files : null)
      .then(
        (data: any) => {
          if (this.registrationPayment.getUserInformation()) {
            this.registrationPayment.removeUserInformation();
          }

          if (request.model.Postlisti === 'true') {
            this.mailingList.newRegistration(request.model.Netfang);
          }

          const userInfo = {
            name: request.model.Nafn,
            kennitala: request.model.Kennitala,
            regId: data.response.recordId,
            namskeidID: this.namskeidId,
            isSnemmskraning: 'true',
            snemmskraningverd: this.course?.Verd_snemmsrkaning,
            namskeidverd: this.course?.Verd,
            lang: LanguageService.getCourseLanguage(this.course),
          };
          this.registrationPayment.emitCourse(this.course);
          this.registrationPayment.setUserInformation(userInfo);
          this.proccessingRequest = false;

          if (request.model.Greidslumati === 'Kreditkort') {
            this.router.navigate([this.router.url + '/payment']);
          } else {
            this.router.navigate(['/stadfesting/' + request.model.Namskeid_Id]);
          }
        },
        err => {
          this.registrationError = err;
          throw err;
        }
      );
  }
}
