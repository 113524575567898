<en-hero-banner
  class="hero-banner"
  [heroBannerContent]="prismicContent"
  [contentType]="'prismic'"
  [herobannerType]="'frontpage'"
  (pushSearchQuery)="toggleSearch($event)"
  [loaded]="loaded"
>
</en-hero-banner>

<ng-container *ngIf="prismicContent?.hb_alert_banner === true">
  <div
    class="hero-alert-banner wrapper trs-longer-delay"
    [ngClass]="{ loaded: (loaded | async) }"
  >
    <div class="hero-alert-banner__content">
      <h3>{{ prismicContent?.alert_banner_title }}</h3>
      <en-prismic-text
        [prismicText]="{ content: prismicContent?.hb_alert_banner_content }"
      ></en-prismic-text>
    </div>
  </div>
</ng-container>

<!-- Use hook for scroll, don't use inside ng-container because it won't render-->
<div #results>
  <ng-container *ngIf="showSearchPage">
    <en-search-page (clearSearch)="clearSearchResults()"></en-search-page>
  </ng-container>
</div>

<ng-container *ngIf="!showSearchPage">
  <section class="upcoming-courses">
    <en-course-grid>
      <div
        class="section-header wrapper trs-base"
        [ngClass]="{ loaded: (loaded | async) }"
      >
        <en-overhead-title> Menntun fyrir alla </en-overhead-title>
        <div class="filter-container">
          <en-heading
            class="main_item"
            [headingContent]="{ text: 'Á næstunni', type: 'heading2' }"
          ></en-heading>
        </div>
      </div>
    </en-course-grid>
  </section>

  <section class="news-grid" [ngClass]="{ fadein: (loaded | async) }">
    <en-news-grid [newsType]="newsType">
      <div class="section-header wrapper">
        <en-overhead-title> Fréttasafn </en-overhead-title>
        <div class="filter-container">
          <en-heading
            class="main_item"
            [headingContent]="{ text: 'Nýjast', type: 'heading2' }"
          ></en-heading>
          <div class="filter_items">
            <en-heading
              class="filter_item"
              (click)="newsType = 'frettir'"
              [ngClass]="{ active: newsType === 'frettir' }"
              [headingContent]="{ text: 'Fréttir', type: 'heading3' }"
            >
            </en-heading>
            <!--<en-heading
                      class="filter_item"
                      (click)="newsType = 'greinar'"
                      [ngClass]="{'active': newsType === 'greinar' }"
                      [headingContent]="{text: 'Greinar', type: 'heading3'}">
                    </en-heading>-->
          </div>
        </div>
      </div>
    </en-news-grid>
  </section>

<section class="about-us" [ngClass]="{ fadein: (loaded | async) }">
  <en-about-us
    class="wrapper"
    [activeTab]="umEhiActiveTab"
    [cont]="prismicContent?.about_us_tabs"
  >
    <div class="section-header">
      <en-overhead-title>
        {{ prismicContent?.about_us_overhead_title }}
      </en-overhead-title>
      <div class="filter-container">
        <en-heading
          class="main_item"
          [headingContent]="prismicContent?.about_us_title[0]"
        ></en-heading>
        <div class="filter_items">
          <ng-container *ngFor="let tab of prismicContent?.about_us_tabs">
            <en-heading
              class="filter_item"
              [headingContent]="{ text: tab.section_name, type: 'heading3' }"
              [ngClass]="{ active: umEhiActiveTab === tab.section_slug }"
              (click)="toggleAbout(tab.section_slug)"
            ></en-heading>
          </ng-container>
        </div>
      </div>

      <ng-container *ngFor="let tabContent of prismicContent?.about_us_tabs">
        <p *ngIf="umEhiActiveTab === tabContent?.section_slug">
          {{ tabContent?.section_text[0].text }}
        </p>
      </ng-container>
    </div>
  </en-about-us>
</section>

  <div
    style="height: 30px; width: 100%; display: flex; position: relative"
  ></div>

  <section class="references-grid" [ngClass]="{ fadein: (loaded | async) }">
    <en-references-grid class="wrapper" [cont]="prismicContent?.body[0].items">
      <en-overhead-title>
        {{ prismicContent?.body[0].primary.overhead_title }}
      </en-overhead-title>

      <en-heading
        class="filter_item"
        [headingContent]="prismicContent?.body[0].primary.main_title[0]"
      ></en-heading>
    </en-references-grid>
  </section>
</ng-container>
