import {
  Component,
  Input,
  ViewEncapsulation,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SwiperOptions } from 'swiper';
import { Observable } from 'rxjs';
import { Course, PlannedCourse } from 'src/eduframe';

@Component({
  selector: 'en-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.less'],
  encapsulation: ViewEncapsulation.None,
})
export class SliderComponent {
  @Input() type: string;
  @Input() arrowSides: boolean = false;
  @Input() sliderContent: Array<any>;
  @Input() contentType?: 'fm' | 'prismic';
  @Input() singleCard?: boolean;
  @Input() offset?;
  @Input() addShadowPadding: boolean = false;
  @Input() loaded: Observable<boolean>;

  public skeletonSliderCounter: Array<number> = new Array(8);
  public isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: Object) {
    if (isPlatformBrowser(platformId)) {
      this.isBrowser = true;
    }
  }

  public configSingleCardSlider: SwiperOptions = {
    allowTouchMove: true,
    setWrapperSize: true,
    slidesPerView: 1,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 42,
  };

  getEduframeCourseLink(course: Course, plannedCourse: PlannedCourse): string {
    return `/namskeid/${course.id}/${course.slug}/${plannedCourse.id}`;
  }

  public config: SwiperOptions = {
    allowTouchMove: true,
    setWrapperSize: true,
    breakpoints: {
      1920: {
        slidesPerView: 5,
      },
      1460: {
        slidesPerView: 4,
      },
      1080: {
        slidesPerView: 3,
      },
      680: {
        slidesPerView: 2,
      },
      300: {
        slidesPerView: 1,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 42,
  };

  public configCondensed: SwiperOptions = {
    allowTouchMove: true,
    setWrapperSize: true,
    breakpoints: {
      1460: {
        slidesPerView: 4,
      },
      1080: {
        slidesPerView: 3,
      },
      680: {
        slidesPerView: 2,
      },
      300: {
        slidesPerView: 1,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    spaceBetween: 42,
  };

  imagesliderConfig: SwiperOptions = {
    allowTouchMove: true,
    setWrapperSize: true,
    breakpoints: {
      300: {
        slidesPerView: 1,
      },
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  };
}
