import {
  Component,
  Input,
  OnInit,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
} from '@angular/core';
// Models
import { Skraningarblad } from '@endurmenntun/_models/forms/form.class';
import { postals } from '../postals/postals';
// Services
import { GeneralService } from '@endurmenntun/_services/general/general.service';
import { CourseFile } from '@backend/form/course';
import { cleanIcelandicPhoneNumber } from 'src/utils';

@Component({
  selector: 'en-form-type-three',
  templateUrl: './form-type-three.component.html',
  styleUrls: ['./form-type-three.component.less', '../form.component.less'],
})
export class FormTypeThreeComponent implements OnInit, OnChanges {
  @Input() namskeidId;
  @Input() disclaimer;
  @Input() remote;
  @Input() reqProcessing: boolean = false;
  @Input() registerStudentError;
  @Output() registerStudent = new EventEmitter<any>();
  @ViewChild('fileUpload', { static: false }) fileUpload: ElementRef;
  files = [];
  @ViewChild('cvUpload', { static: false }) cvUpload: ElementRef;
  @ViewChild('profskirteini', { static: false }) profskirteini: ElementRef;
  @ViewChild('profskirteiniTvo', { static: false })
  profskirteiniTvo: ElementRef;

  // files size validation
  public profskirteiniFileValid = true;
  public profskirteiniFileTypeValid = true;
  public profskirteiniTvoFileValid = true;
  public profskirteiniTvoFileTypeValid = true;
  public CvFileValid = true;
  public CvFileTypeValid = true;
  public OtherFileValid = true;
  public OtherFileTypeValid = true;

  public profskirteiniFileUploaded = false;
  public profskirteiniTvoFileUploaded = false;
  public cvFileUploaded = false;

  public model: Skraningarblad;
  public netfangEndurtekid: string;
  public kennitalaBlur = false;
  public farsimiBlur = false;
  public netfangBlur = false;
  public postalCodes = Object.values(postals);

  private formFiles = [];
  eventLogger: [] = [];
  otherFileMessageActive = false;
  otherFileMessage = '';
  reader = new FileReader();

  // show validation error at bottom of form
  public formValid = true;

  constructor(private general: GeneralService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.namskeidId && changes.model) {
      this.model.Namskeid_Id = this.namskeidId;
    }
  }

  ngOnInit(): void {
    this.model = new Skraningarblad(
      this.namskeidId,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      'false',
      'false',
      '',
      'RUSL',
      '',
      '',
      ''
    );
  }

  onPaste(event) {
    const kt: string = event.clipboardData.getData('text/plain');
    this.model.Kennitala = kt.replace(/\D/g, '');
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      const fileObject: CourseFile = {
        name: file.filename,
        type: file.type,
        base64: file.base,
      };
      this.formFiles.push(fileObject);
    });
  }

  checkIfExistsByType(type) {
    if (this.files.filter(e => e.type === type).length > 0) {
      const index = this.files.findIndex(s => s.type === type);
      this.files.splice(index, 1);
    }
  }

  checkIfExistsByName(name) {
    if (this.files.filter(e => e.data.name).length > 0) {
      const index = this.files.findIndex(s => s.data.name === name);
      this.files.splice(index, 1);
    }
  }

  async encodeBase64(file) {
    return new Promise(resolve => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result.toString());
      };
      reader.onerror = console.error;
    });
  }

  public uploadProfskirteini() {
    const profskirteini = this.profskirteini.nativeElement;
    profskirteini.onchange = () => {
      this.checkIfExistsByType('profskirteini');
      if (this.general.checkIfTooLarge(profskirteini)) {
        this.profskirteiniFileValid = false;
      } else if (!this.general.checkIfRightFileType(profskirteini)) {
        this.profskirteiniFileTypeValid = false;
      } else {
        this.profskirteiniFileValid = true;
        this.profskirteiniFileTypeValid = true;
        if (profskirteini.files.length) {
          this.profskirteiniFileUploaded = true;
        } else {
          this.profskirteiniFileUploaded = false;
        }

        for (const file of profskirteini.files) {
          let encodedFile = this.encodeBase64(file);
          encodedFile.then((base64: string) => {
            this.files.unshift({
              filename: file.name,
              data: file,
              type: 'profskirteini',
              base: base64,
            });
          });
        }
      }
    };
    profskirteini.click();
  }

  public uploadProfskirteiniTvo() {
    const profskirteiniTvo = this.profskirteiniTvo.nativeElement;
    profskirteiniTvo.onchange = () => {
      this.checkIfExistsByType('profskirteiniTvo');
      if (this.general.checkIfTooLarge(profskirteiniTvo)) {
        this.profskirteiniTvoFileValid = false;
      } else if (!this.general.checkIfRightFileType(profskirteiniTvo)) {
        this.profskirteiniTvoFileTypeValid = false;
      } else {
        this.profskirteiniTvoFileValid = true;
        this.profskirteiniTvoFileTypeValid = true;
        if (profskirteiniTvo.files.length) {
          this.profskirteiniTvoFileUploaded = true;
        } else {
          this.profskirteiniTvoFileUploaded = false;
        }

        for (const file of profskirteiniTvo.files) {
          let encodedFile = this.encodeBase64(file);
          encodedFile.then((base64: string) => {
            this.files.unshift({
              filename: file.name,
              data: file,
              type: 'profskirteiniTvo',
              base: base64,
            });
          });
        }
      }
    };
    profskirteiniTvo.click();
  }

  uploadCV() {
    const cvUpload = this.cvUpload.nativeElement;
    cvUpload.onchange = () => {
      this.checkIfExistsByType('cv');
      if (this.general.checkIfTooLarge(cvUpload)) {
        this.CvFileValid = false;
      } else if (!this.general.checkIfRightFileType(cvUpload)) {
        this.CvFileTypeValid = false;
      } else {
        this.CvFileValid = true;
        this.CvFileTypeValid = true;
        if (cvUpload.files.length) {
          this.cvFileUploaded = true;
        } else {
          this.cvFileUploaded = false;
        }

        for (const file of cvUpload.files) {
          let encodedFile = this.encodeBase64(file);
          encodedFile.then((base64: string) => {
            this.files.unshift({
              filename: file.name,
              data: file,
              type: 'cv',
              base: base64,
            });
          });
        }
      }
    };

    cvUpload.click();
  }

  uploadOtherfile() {
    const fileUpload = this.fileUpload.nativeElement;
    fileUpload.onchange = () => {
      if (this.general.checkIfTooLarge(fileUpload)) {
        this.OtherFileValid = false;
      } else if (!this.general.checkIfRightFileType(fileUpload)) {
        this.OtherFileTypeValid = false;
      } else {
        for (const file of fileUpload.files) {
          this.OtherFileValid = true;
          this.OtherFileTypeValid = true;
          let encodedFile = this.encodeBase64(file);
          encodedFile.then((base64: string) => {
            this.files.unshift({
              filename: file.name,
              data: file,
              type: 'other',
              base: base64,
            });
          });
        }
      }
    };
    fileUpload.click();
  }

  handleEvent(evt, _this, filename?, length?) {
    _this.eventLogger.push(`${evt.type}: ${evt.loaded} bytes transferred\n`);

    if (evt.type === 'load') {
      _this.otherFileMessageActive = true;
      _this.otherFileMessage = length
        ? `Skránum þínum hefur verið hlaðað upp!`
        : `${filename} hefur verið hlaðið upp!`;
      _this.clearOtherFileMessage();
    }

    if (evt.type === 'error') {
      _this.otherFileMessageActive = false;
      _this.otherFileMessage =
        'Það kom upp villa í ferlinu, reyndu aftur síðar';
      _this.clearOtherFileMessage();
    }
  }

  clearOtherFileMessage() {
    setTimeout(() => {
      this.otherFileMessageActive = false;
      this.otherFileMessage = undefined;
    }, 1000);
  }

  addEventListeners(reader, filename, length) {
    reader.addEventListener('load', event => {
      this.handleEvent(event, this, filename, length);
    });
    reader.addEventListener('error', event => {
      this.handleEvent(event, this, filename);
    });
    reader.addEventListener('abort', event => {
      this.handleEvent(event, this);
    });
  }

  getProgress(event) {
    const fileUpload = this.fileUpload.nativeElement;
    if (this.general.checkIfTooLarge(fileUpload)) {
      this.OtherFileValid = false;
    } else {
      this.eventLogger = [];
      this.otherFileMessageActive = false;
      const selectedFile = event[0];
      if (selectedFile) {
        this.addEventListeners(this.reader, selectedFile.name, event.length);
        this.reader.readAsDataURL(selectedFile);
      }
    }
  }

  onSubmit(args) {
    if (args.controls.fjarnam && args.controls.fjarnam.value) {
      args.controls.fjarnam.value = args.controls.fjarnam.value.toString();
    }
    if (args.controls.skilmalar && args.controls.skilmalar.value) {
      args.controls.skilmalar.value = args.controls.skilmalar.value.toString();
    }
    if (this.files.length) {
      this.uploadFiles();
    }

    args.form.updateValueAndValidity();

    if (args.form.invalid) {
      Object.keys(args.form.controls).forEach(key => {
        args.form.get(key).markAsDirty();
        this.formValid = false;
      });
      return;
    } else {
      this.formValid = true;
      this.registerStudent.emit({ model: this.model, files: this.formFiles });
    }
  }

  getOnlyPostal(postal) {
    var numberPattern = /\d+/g;
    var justCode = postal.match(numberPattern);
    if (justCode !== null) {
      return justCode;
    } else {
      return postal;
    }
  }

  /** Clean up phone number, we got it from clipboard */
  public onPhoneNumberFromClipboard(event: ClipboardEvent): void {
    event.preventDefault();

    const clipboardData = event.clipboardData.getData('text/plain');
    const phoneNumber = cleanIcelandicPhoneNumber(clipboardData);

    this.model.Farsimi = phoneNumber;
  }
}
