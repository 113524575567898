<div class="wrapper alert-page">
  <div class="section-header">
    <en-overhead-title></en-overhead-title>

    <en-heading
      class="main_item"
      [headingContent]="prismicContent?.alert_title[0]"
    ></en-heading>
  </div>

  <ng-container *ngIf="prismicContent">
    <div class="alert-page__content">
      <en-prismic-text
        [prismicText]="{ content: prismicContent?.alert_content }"
      ></en-prismic-text>
    </div>
  </ng-container>
</div>
