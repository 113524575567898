import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'en-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.less'],
})
export class HeadingComponent implements OnInit {
  @Input() headingContent;

  constructor() {}

  ngOnInit() {}
}
