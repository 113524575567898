import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IdleTimer } from './idle-timer';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  public bearerToken = new BehaviorSubject(null);
  public timer: IdleTimer;

  constructor() {}

  public assignBearerToken(): void {
    // Do nothing, our API is public
    // This is just a placeholder due to the old FileMaker service had this method
  }

  public assignBearerTokenAgain(): void {
    // Do nothing, our API is public
    // This is just a placeholder due to the old FileMaker service had this method
  }

  public async setBearerTokenTimer(): Promise<void> {
    // Do nothing, our API is public
    // This is just a placeholder due to the old FileMaker service had this method
  }
}
