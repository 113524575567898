<div class="reference-card" [ngClass]="{ 'shadow-padding': addShadowPadding }">
  <div class="reference-card__container">
    <div class="reference-card__content">
      <div class="comment">
        <div class="comment-bubble"></div>
        <ng-container *ngIf="type === 'fm'">
          <p [innerHTML]="cont.review"></p>
        </ng-container>

        <p *ngIf="type === 'prismic'">
          {{ cont?.review[0].text }}
        </p>
      </div>

      <div class="person" *ngIf="cont.type == 'extra'">
        <figure>
          <img [src]="cont.image_url" [alt]="cont.name" />
          <figcaption>
            <h4>{{ cont.name }}</h4>
            <span>Nemandi</span>
          </figcaption>
        </figure>
      </div>
    </div>
  </div>
</div>
