import { Directive } from '@angular/core';
import {
  AbstractControl,
  NG_VALIDATORS,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import * as kt from 'kennitala';

@Directive({
  selector: '[enValidKennitala]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: ValidKennitalaDirective,
      multi: true,
    },
  ],
})
export class ValidKennitalaDirective implements Validator {
  validate(control: AbstractControl): ValidationErrors | null {
    if (!control.value || control.value.length < 10) {
      return null;
    }
    try {
      const kennitala = require('kennitala');
      if (kennitala.isValid(control.value)) {
        return null;
      }
    } catch (e) {
      console.error(e);
      return null;
    }
    return {
      invalidKennitala: {
        value: control.value,
      },
    };
  }
}
