// Module Imports
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClientJsonpModule } from '@angular/common/http';
import { AppRoutingModule } from '@endurmenntun/app-routing.module';
import { ComponentsModule } from '@endurmenntun/_components/components/components.module';
import { ElementsModule } from '@endurmenntun/_components/elements/elements.module';
import { FrontPageModule } from '@endurmenntun/_components/pages/front-page/front-page.module';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { NgxFilesizeModule } from 'ngx-filesize';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { EducationModule } from '@endurmenntun/_components/pages/education/education.module';
// Component Imports
import { AppComponent } from '@endurmenntun/app.component';
import { FooterComponent } from '@endurmenntun/_components/footer/footer.component';
import { HeaderComponent } from '@endurmenntun/_components/header/header.component';
import { NavigationComponent } from '@endurmenntun/_components/header/navigation/navigation.component';

import { UmEhiContainerComponent } from '@endurmenntun/_components/pages/um-ehi/um-ehi-container.component';
import { StaffComponent } from '@endurmenntun/_components/pages/um-ehi/staff/staff.component';
import { EmployeeComponent } from '@endurmenntun/_components/pages/um-ehi/staff/employee/employee.component';
import { ManagementComponent } from '@endurmenntun/_components/pages/um-ehi/management/management.component';
// Pages
import { NotFoundComponent } from '@endurmenntun/_components/pages/not-found/not-found.component';
import { EhiComponent } from '@endurmenntun/_components/pages/um-ehi/ehi/ehi.component';
import { FrettComponent } from '@endurmenntun/_components/pages/frettir/frett/frett.component';
import { FrettirComponent } from '@endurmenntun/_components/pages/frettir/frettir.component';
import { CoursesEnglishComponent } from '@endurmenntun/_components/pages/courses-english/courses-english.component';
import { AlertPageComponent } from '@endurmenntun/_components/pages/alert-page/alert-page.component';
import { CustomContentPageComponent } from './_components/pages/custom-content-page/custom-content-page.component';
import { FormsComponent } from '@endurmenntun/_components/pages/forms/forms.component';
import { ContactComponent } from '@endurmenntun/_components/pages/forms/contact/contact.component';
import { MailingListComponent } from '@endurmenntun/_components/pages/forms/mailing-list/mailing-list.component';
// Servies
import { WINDOW_PROVIDERS } from '@endurmenntun/_services/window/window.service';
import { CookieService } from 'ngx-cookie-service';
import { PrismicService } from '@endurmenntun/_services/prismic/prismic.service';

import * as Sentry from '@sentry/angular-ivy';

// Eduframe client services
import { FakeMakerService } from './fake-maker/fake-maker.service';

// Pipes

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    NavigationComponent,
    FrettirComponent,
    FrettComponent,
    UmEhiContainerComponent,
    EhiComponent,
    StaffComponent,
    ManagementComponent,
    EmployeeComponent,
    NotFoundComponent,
    CoursesEnglishComponent,
    AlertPageComponent,
    MailingListComponent,
    ContactComponent,
    FormsComponent,
    CustomContentPageComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    HttpClientModule,
    ComponentsModule,
    // EducationModule,
    ElementsModule,
    FrontPageModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    NgxUsefulSwiperModule,
    HttpClientJsonpModule,
    NgxFilesizeModule,
    YouTubePlayerModule,
    BsDatepickerModule.forRoot(),
  ],
  providers: [
    WINDOW_PROVIDERS,
    CookieService,
    PrismicService,
    FakeMakerService,
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
