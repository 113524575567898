<h1
  *ngIf="headingContent?.type === 'heading1'"
  [ngClass]="{
    lowercase: headingContent?.lowercase === true,
    large: headingContent?.large === true
  }"
>
  {{ headingContent?.text }}
</h1>
<h2
  *ngIf="headingContent?.type === 'heading2'"
  [ngClass]="{
    lowercase: headingContent?.lowercase === true,
    smaller: headingContent?.smaller === true,
    'extra-small': headingContent?.extraSmall === true
  }"
>
  {{ headingContent?.text }}
</h2>
<h3 *ngIf="headingContent?.type === 'heading3'">{{ headingContent?.text }}</h3>
<h4 *ngIf="headingContent?.type === 'heading4'">{{ headingContent?.text }}</h4>
